import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { ConnectedRouter } from "@jauntin/react-ui";
import appReducers from "./appStateReducers";
import formReducers from "./formReducers";
import authReducers from "./authReducers";
import producersReducers from "./producersReducers";
import facilitiesReducers from "./facilitiesReducers";
import usersReducers from "./usersReducers";
import membersReducers from "./membersReducers";

export default () =>
  combineReducers({
    router: ConnectedRouter.routerReducer,
    app: appReducers,
    members: membersReducers,
    facilities: facilitiesReducers,
    producers: producersReducers,
    users: usersReducers,
    auth: authReducers,
    form: formReducer.plugin(formReducers),
  });
