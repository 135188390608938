import { FormBuilders } from "@jauntin/reactables";
import { control, group } from "@reactables/forms";
import formProviders from "@basicare/common/src/Helpers/formProviders";

export const RxSettingForm = () =>
  FormBuilders.build(
    group({
      controls: {
        date: control({
          initialValue: "",
          normalizers: ["normalizeDateTime"],
          validators: ["required", "dateTimeFormat"],
        }),
      },
    }),
    {
      name: "rxSettingForm",
      providers: formProviders,
    }
  );
