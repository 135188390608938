import { Button } from "@jauntin/react-ui";
import { useReactable } from "@reactables/react";
import { RxToggle } from "@jauntin/reactables";
import ModalConfirmation from "@basicare/common/src/Components/ModalConfirmation";
import { useHistory } from "react-router-dom";

import FacilityForm from "./FacilityForm";
import { FacilityProp } from "../Rx/RxFacility";
import { facilityFormToSavePayload } from "../Models/facility.model";
import ModalDiscardAddNew from "Features/Shared/Components/ModalDiscardAddNew";
import ModalUpdateError from "Features/Shared/Components/ModalUpdateError";
import { getUrl, FACILITY_PAGE } from "Helpers/URLParser";
import FacilityStatus from "./FacilityStatus";

const EditFacility = ({
  rxFacility: [state, actions],
}: {
  rxFacility: FacilityProp;
}) => {
  const facility = state.entity.data;
  const history = useHistory();
  const [showConfirmModal, confirmModalToggle] = useReactable(RxToggle);
  const [showDiscardModal, discardModalToggle] = useReactable(RxToggle);

  return (
    <>
      <FacilityForm facility={facility}>
        {({ formState, formFields }) => (
          <>
            <div className="content__header col-auto">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="m-0 font-weight-bold">Organization Overview</h4>
                <div className="d-flex float-right">
                  <Button
                    text="Discard Changes"
                    className="btn btn-outline-secondary px-4 mx-2"
                    onClick={discardModalToggle.toggleOn}
                  />
                  <Button
                    text="Save Organization"
                    disabled={!formState.root.valid || !formState.root.dirty}
                    className="btn btn-primary px-4 ml-2 text-nowrap"
                    onClick={confirmModalToggle.toggleOn}
                  />
                </div>
              </div>
            </div>
            <div className="content__body">
              <FacilityStatus facility={facility} />
              {formFields}
            </div>
            {showConfirmModal && (
              <ModalConfirmation
                message={`Update ${facility.name} ?`}
                cancelBtnText="Back to Edit"
                checkboxLabelText="Yes, I confirm that I would like to update this organization."
                show={showConfirmModal}
                handleClose={confirmModalToggle.toggleOff}
                action={() =>
                  actions.updateFacility.send(
                    facilityFormToSavePayload(formState.root.value)
                  )
                }
                isUpdating={state.updateFacility.loading}
              />
            )}

            <ModalDiscardAddNew
              show={showDiscardModal}
              handleClose={discardModalToggle.toggleOff}
              confirmAction={() => {
                history.push(getUrl(FACILITY_PAGE, facility.id));
              }}
            />

            <ModalUpdateError
              show={Boolean(state.updateFacility.error)}
              text="There was an error updating the promo code. Please try again"
              handleCloseError={actions.updateFacility.resetState}
            />
          </>
        )}
      </FacilityForm>
    </>
  );
};

export default EditFacility;
