import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Toast } from "react-bootstrap";
import { Button } from "@jauntin/react-ui";
import PopoverTrigger from "Features/Shared/Components/SearchEllipsisPopover";
import { membershipPropType } from "Helpers/MembershipModel";
import MemberStatus from "Features/Members/Components/MemberStatus";
import {
  EDIT_MEMBER_PAGE,
  getUrl,
  MEMBER_PAGE,
} from "../../../Helpers/URLParser";
import {
  triggerReminder,
  triggerTrialReminder,
  triggerWebhook,
  toggleMembershipToast,
} from "Redux/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import LoadingMask from "@basicare/common/src/Components/LoadingMask";
import { PromoCodeTypes } from "@basicare/common/src/Constants/promoCodeTypes";

const PopoverContent = ({ membership }) => {
  const dispatch = useDispatch();

  const kinds = [
    "subscription_billing_skipped",
    "subscription_canceled",
    "subscription_charged_successfully",
    "subscription_charged_unsuccessfully",
    "subscription_expired",
    "subscription_trial_ended",
    "subscription_went_active",
    "subscription_went_past_due",
  ];

  return (
    <>
      <button
        className="btn d-block popover-item__btn"
        onClick={() => dispatch(triggerReminder(membership.id))}
      >
        Test Reminder Email
      </button>
      <button
        className="btn d-block popover-item__btn"
        onClick={() => dispatch(triggerTrialReminder(membership.id))}
      >
        Test Trial Email
      </button>
      {kinds.map((kind) => (
        <button
          key={kind}
          className="btn d-block popover-item__btn"
          onClick={() => dispatch(triggerWebhook(membership.id, kind))}
        >
          Trigger "{kind}"
        </button>
      ))}
    </>
  );
};

PopoverContent.propTypes = {
  membership: membershipPropType.isRequired,
};

const SearchResults = ({ membersList, canTestFeatures, loading }) => {
  const dispatch = useDispatch();
  const triggerReminderToast = useSelector(
    (state) => state.members.toast.triggerReminder
  );
  const triggerWebhookToast = useSelector(
    (state) => state.members.toast.triggerWebhook
  );

  return (
    <>
      <LoadingMask loading={loading}>
        <table className="table subsection-table">
          <thead>
            <tr>
              <th scope="col">Member Number</th>
              <th scope="col">Member Name</th>
              <th scope="col">Phone</th>
              <th scope="col">Email</th>
              <th scope="col">Organization</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {membersList.map((member) => (
              <tr key={`row${member.id}`}>
                <td>
                  <Link to={getUrl(MEMBER_PAGE, member.id)}>
                    <Button
                      className="btn btn-link btn--edit text-left text-primary"
                      text={
                        member.recuroSubscriberNumber &&
                        Boolean(
                          member.organization.requiresRecuroSubscriberNumber
                        )
                          ? member.recuroSubscriberNumber
                          : member.subscriberNumber
                      }
                      onClick={() => {}}
                    />
                  </Link>
                  {member.trial?.active && (
                    <>
                      <br />
                      {member.trial?.type === PromoCodeTypes.TrialPurchase && (
                        <span>
                          Trial (payment information required at signup)
                        </span>
                      )}
                      {member.trial?.type === PromoCodeTypes.Trial && (
                        <span>Trial (payment information not required)</span>
                      )}
                    </>
                  )}
                </td>
                <td>{`${member.firstName} ${member.lastName}`}</td>
                <td>{member.phone}</td>
                <td>{member.email}</td>
                <td>{member.organization.name}</td>
                <td>
                  <MemberStatus member={member} />
                </td>
                <td className="d-flex">
                  <PopoverTrigger
                    contentClass="popover-content__fit-content"
                    icon="fa-ellipsis-v"
                    content={
                      <>
                        <Link to={getUrl(MEMBER_PAGE, member.id)}>
                          <button
                            className="btn d-block popover-item__btn"
                            onClick={() => {}}
                          >
                            <i className="fa fa-eye" /> View
                          </button>
                        </Link>
                        <Link to={getUrl(EDIT_MEMBER_PAGE, member.id)}>
                          <button
                            className="btn d-block popover-item__btn"
                            onClick={() => {}}
                          >
                            <i className="fa fa-edit" /> Edit
                          </button>
                        </Link>
                      </>
                    }
                  />
                </td>
                <td>
                  {canTestFeatures && (
                    <PopoverTrigger
                      contentClass="popover-content__fit-content"
                      icon="fa-user-cog"
                      content={<PopoverContent membership={member} />}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </LoadingMask>

      <Toast
        className="toast--membership"
        onClose={() => dispatch(toggleMembershipToast("triggerReminder", true))}
        show={triggerReminderToast.show}
        delay={3000}
        autohide
      >
        <Toast.Header>
          <strong
            className={
              triggerReminderToast.success ? "text-success" : "text-danger"
            }
          >
            {triggerReminderToast.success ? "Success!" : "Error!"}
          </strong>
        </Toast.Header>
        <Toast.Body>
          {triggerReminderToast.success
            ? "Renewal Reminder email triggered"
            : "Can't trigger Renewal Reminder email"}
        </Toast.Body>
      </Toast>

      <Toast
        className="toast--membership"
        onClose={() => dispatch(toggleMembershipToast("triggerWebhook", true))}
        show={triggerWebhookToast.show}
        delay={3000}
        autohide
      >
        <Toast.Header>
          <strong
            className={
              triggerWebhookToast.success ? "text-success" : "text-danger"
            }
          >
            {triggerWebhookToast.success ? "Success!" : "Error!"}
          </strong>
        </Toast.Header>
        <Toast.Body>
          {triggerWebhookToast.success
            ? "Webhook triggered"
            : "Can't trigger Webhook"}
        </Toast.Body>
      </Toast>
    </>
  );
};

SearchResults.propTypes = {
  membersList: PropTypes.any.isRequired,
  canTestFeatures: PropTypes.bool.isRequired,
};

export default SearchResults;
