import {
  PrimaryMember,
  emptyPrimaryMember,
} from "@basicare/common/src/Models/primaryMember.model";
import { MembershipSource } from "@basicare/common/src/Constants/membership";
import { Dependent } from "@basicare/common/src/Models/dependent.model";
import { Organization } from "@basicare/common/src/Models/organization.model";
import { Gender } from "@basicare/common/src/Constants/genders";
import { MemberDetails } from "./memberDetails.model";
import { PromoCodeTypes } from "@basicare/common/src/Constants/promoCodeTypes";

export interface MemberForm {
  membership: {
    effectiveDate: string;
    cancellationDate?: string | null;
    organization: Organization;
    recuroSubscriberNumber?: string;
  };
  primaryMember: PrimaryMember;
  dependents: Dependent[];
}

export const emptyMember: MemberForm = {
  membership: {
    effectiveDate: "",
    organization: null,
    recuroSubscriberNumber: "",
    cancellationDate: null,
  },
  primaryMember: emptyPrimaryMember,
  dependents: [],
};

export const memberDetailsToMemberForm = ({
  recuroSubscriberNumber,
  effectiveDate,
  subscriptionInterval,
  organization,
  firstName,
  lastName,
  phone,
  email,
  gender,
  dateOfBirth,
  primaryAddress: { address1: address, address2, state, city, zip },
  dependents,
  cancellationDate = null,
}: MemberDetails): MemberForm => ({
  membership: {
    recuroSubscriberNumber: recuroSubscriberNumber || "",
    effectiveDate,
    cancellationDate,
    organization: {
      id: organization.id,
      code: organization.code,
      name: organization.name,
      productCode: organization.productCode,
      elixirGroupId: organization.elixirGroupId,
      recuroGroupId: organization.recuroGroupId,
      requiresRecuroSubscriberNumber:
        organization.requiresRecuroSubscriberNumber,
      doNotSendEmailsToMember: organization.doNotSendEmailsToMember,
      logoUrl: organization.logoUrl,
      monthlyPrice: organization.monthlyPrice,
      hasScriptClaimGroupId: organization.hasScriptClaimGroupId,
    },
  },
  primaryMember: {
    planType: subscriptionInterval,
    firstName,
    lastName,
    phoneNumber: phone,
    email,
    gender,
    dateOfBirth,
    address,
    address2: address2 || "",
    state,
    city,
    zip,
  },
  dependents,
});

export interface AddMemberPayload extends SaveMemberPayload {
  source: MembershipSource;
}

export interface SaveMemberPayload {
  effectiveDate: string;
  organizationId: number;
  firstName: string;
  lastName: string;
  gender: Gender;
  dateOfBirth: string;
  primaryAddress: {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  phone: string;
  email: string;
  dependents: Dependent[];
  recuroSubscriberNumber?: string;
}

export interface CancelMemberPayload {
  cancellationDate?: string;
}

export interface LoadMembersPayload {
  page: number;
  search?: string;
  params?: { [key: string]: string };
}

export interface SearchMembersPayload {
  search: string;
  status: string;
  promoCodeType: PromoCodeTypes;
  page: number;
  withCancellationDate: number;
}

export const getSaveMemberPayload = ({
  membership: { effectiveDate, organization, recuroSubscriberNumber },
  dependents,
  primaryMember: {
    firstName,
    lastName,
    gender,
    dateOfBirth,
    phoneNumber: phone,
    email,
    ...address
  },
}: MemberForm): SaveMemberPayload => ({
  effectiveDate,
  organizationId: organization.id,
  recuroSubscriberNumber,
  firstName,
  lastName,
  gender,
  dateOfBirth,
  primaryAddress: {
    address1: address.address,
    address2: address.address2,
    city: address.city,
    state: address.state,
    zip: address.zip,
    country: "US",
  },
  phone,
  email,
  dependents,
});

export const getAddMemberPayload = (member: MemberForm): AddMemberPayload => ({
  ...getSaveMemberPayload(member),
  source: MembershipSource.ADMIN_CREATE_MANUAL,
});
