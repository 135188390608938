import { CustomReducer } from "@reactables/forms";
import { Dependent, emptyDependent } from "../../Models/dependent.model";
import { RelationshipType } from "../../Constants/dependents";
import { MAX_DEPENDENTS } from "../../Constants/dependents";
import { dependent } from "../Configs/dependent.config";

export const addDependentReducer: CustomReducer = ({ pushControl }, state) => {
  const dependentsList = state.form[["dependents"].join(".")]
    .value as Dependent[];
  if (dependentsList.length === MAX_DEPENDENTS) return state;

  const isSpouseSelected = dependentsList.some(
    ({ relationshipToAccountHolder }) =>
      relationshipToAccountHolder === RelationshipType.Spouse
  );

  state = pushControl(state, {
    controlRef: ["dependents"],
    config: dependent({
      ...emptyDependent,
      relationshipToAccountHolder: isSpouseSelected
        ? RelationshipType.Dependent
        : "",
    }),
  });

  return state;
};
