import { Gender } from "../Constants/genders";

export interface Person {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: Gender | "";
}

export const emptyPerson: Person = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  gender: "",
};
