export enum Gender {
  Male = "M",
  Female = "F",
}

export const genderOptions = [
  {
    label: "Male",
    value: Gender.Male,
  },
  {
    label: "Female",
    value: Gender.Female,
  },
];
