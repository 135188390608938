/**
 *
 * Reducers related to data on the Members page.
 *
 */
import { TOGGLE_MEMBERSHIP_TOAST } from "../Actions/actions";

const initialToastState = {
  triggerWebhook: {
    show: false,
    success: false,
  },
  triggerReminder: {
    show: false,
    success: false,
  },
};

const initialState = () => ({
  toast: initialToastState,
});

const toggleToast = (state, { type, success }) => {
  return {
    ...state,
    toast: {
      ...initialToastState,
      [type]: {
        show: !state.toast[type].show,
        success,
      },
    },
  };
};

export default (state = initialState(), action) => {
  if (action.type === TOGGLE_MEMBERSHIP_TOAST) {
    return { ...toggleToast(state, action.payload) };
  }
  return state;
};
