import { Toast } from "react-bootstrap";

const alertDelayClose = 3000; // milliseconds

const ReferralLinkToast = ({ show, setShowToast, text = "Referral link" }) => (
  <Toast
    className="referralToast"
    onClose={() => setShowToast(false)}
    show={show || false}
    delay={alertDelayClose}
    autohide
  >
    <Toast.Body className="referralToast__body">
      <div className="d-flex align-items-center">
        <div className="col-auto">
          <i className="fal fa-check icon--large" />
        </div>
        <div className="col">
          <div>
            <strong>{text}</strong>
          </div>
          <div>Copied!</div>
        </div>
      </div>
    </Toast.Body>
  </Toast>
);

export default ReferralLinkToast;
