import { useReactable } from "@reactables/react";
import { RxToggle, RxRequest } from "@jauntin/reactables";
import { ExtendedMeta } from "@jauntin/utilities";
import { Button } from "@jauntin/react-ui";
import { useHistory } from "react-router-dom";
import TwoStageButton from "@basicare/common/src/Components/TwoStageButton";
import { PROMO_CODES_PAGE, getUrl } from "Helpers/URLParser";
import ModalDiscardAddNew from "Features/Shared/Components/ModalDiscardAddNew";
import ModalUpdateError from "Features/Shared/Components/ModalUpdateError";
import PromoCodeForm from "./PromoCodeForm";
import PromoCodeService from "Services/PromoCodeService";
import API from "Services/API";
import { getAddPromoActionEnabled } from "Features/PromoCodes/Rx/Selectors/promoCode.selectors";
import { PromoCodePayload } from "@basicare/common/src/Models/promoCode.model";
import { getPromoCodePayload } from "Features/PromoCodes/Rx/Selectors/promoCode.selectors";

const AddPromoCode = () => {
  const history = useHistory();

  const goToPromoCodesPage = () => history.push(getUrl(PROMO_CODES_PAGE));

  const [submitTouched, { toggleOn: touchSubmit }] = useReactable(RxToggle);
  const [showDiscardModal, discardModalToggle] = useReactable(RxToggle);

  const [submissionRequest, submissionActions] = useReactable(RxRequest, {
    resource: (payload: PromoCodePayload) =>
      new PromoCodeService(new API()).postAddNewPromoCode(payload),
    onSuccess: goToPromoCodesPage,
  });

  if (!submissionRequest) return;

  const showErrors = ({ touched, error }: ExtendedMeta) =>
    Boolean((touched || submitTouched) && error);

  return (
    <PromoCodeForm showErrors={showErrors}>
      {({ formState, formFields }) => {
        const { valid, value } = formState.root;
        const actionEnabled = getAddPromoActionEnabled(
          formState,
          submitTouched
        );

        return (
          <>
            <div className="scroll-part">
              <div className="content__header content__header--autoWidth col-auto">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="m-0 font-weight-bold">Add New Promo Code</h4>
                  <div className="d-flex float-right">
                    <Button
                      text="Discard Changes"
                      className="btn btn-outline-secondary px-4 mx-2"
                      onClick={discardModalToggle.toggleOn}
                    />
                    <TwoStageButton
                      className="btn btn-primary px-4 mx-2 text-nowrap border-radius-0"
                      spinning={submissionRequest.loading}
                      actionEnabled={actionEnabled}
                      action={() =>
                        submissionActions.send(getPromoCodePayload(value))
                      }
                      touchSubmit={touchSubmit}
                      text="Save Promo Code"
                      disableAction={!valid}
                    />
                  </div>
                </div>
              </div>

              {formFields}

              <ModalDiscardAddNew
                show={showDiscardModal}
                handleClose={discardModalToggle.toggleOff}
                confirmAction={goToPromoCodesPage}
              />

              <ModalUpdateError
                show={Boolean(submissionRequest.error)}
                text="There was an error creating promo code. Please try again"
                handleCloseError={submissionActions.resetState}
              />
            </div>
          </>
        );
      }}
    </PromoCodeForm>
  );
};

export default AddPromoCode;
