import { Models } from "@jauntin/reactables";
import {
  PromoCodeDetails,
  PromoCodePayload,
  PromoCodeSearch,
} from "@basicare/common/src/Models/promoCode.model";
import { getFilteredParams } from "@basicare/common/src/Helpers/getQueryString";
/**
 *
 * Services provided by the server related to `PromoCode`
 *
 */
class PromoCodeService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getPromoCodesSearchResults = (
    params: PromoCodeSearch
  ): Promise<{ data: Models.PaginatedData<PromoCodeDetails> }> => {
    const filteredParams = {
      ...getFilteredParams(params),
      perPage: 10,
    };
    return this.api.get({
      location: "/promo-codes",
      params: filteredParams,
    });
  };

  getIsValidPromoCode = (code) => {
    return this.api.get({
      location: `/promo-codes/validate`,
      params: { code },
    });
  };

  getGeneratedPromoCode = () => {
    return this.api.get({
      location: `/promo-codes/generate`,
    });
  };

  getPromoCode = (id): Promise<{ data: PromoCodeDetails }> =>
    this.api.get({
      location: `/promo-codes/${id}`,
    });

  putPromoCodeEditDetails = (data: PromoCodePayload, id: number) =>
    this.api.put({
      location: `/promo-codes/${id}`,
      body: data,
    });

  postAddNewPromoCode = (data: PromoCodePayload) =>
    this.api.post({
      location: `/promo-codes`,
      body: data,
    });

  postAddNote = (data) =>
    this.api.post({
      location: `/promo-codes/${data.id}/notes`,
      body: data,
    });
}

export default PromoCodeService;
