import { Col, Form } from "react-bootstrap";
import { Button } from "@jauntin/react-ui";
import { RxToggle } from "@jauntin/reactables";
import { useReactable } from "@reactables/react";
import { stringHelpers } from "../../../Helpers/FormattingHelpers";
import ReferralLinkToast from "../../Shared/Components/ReferralLinkToast";
import copyToClipboard from "../../../Helpers/CopyToClipboard";
import { Facility } from "../Models/facility.model";

const FacilityStatus = ({ facility }: { facility: Facility }) => {
  const [showToast, showToastActions] = useReactable(RxToggle);

  return (
    <>
      <div className="card border-0">
        <Form.Row className="justify-content-between align-items-center mb-4">
          <Col xs="auto">
            <>
              <span className="label mr-2">Status:</span>
              <span>{stringHelpers.firstToUpper(facility.status)}</span>
            </>
          </Col>
          <Col className="d-flex justify-content-end">
            {!facility.requiresRecuroSubscriberNumber && (
              <>
                <div className="mb-0 text-right my-auto mr-2">
                  <strong>Referral link:</strong>
                  <em>{` ${facility.links.facilityReferralLink}`}</em>
                </div>
                <div className="my-auto">
                  <Button
                    className="btn btn-primary px-4 text-nowrap"
                    type="button"
                    onClick={() => {
                      copyToClipboard(facility.links.facilityReferralLink);
                      showToastActions.toggleOn();
                    }}
                    text="Copy"
                  />
                </div>
              </>
            )}
          </Col>
        </Form.Row>
      </div>
      <ReferralLinkToast
        show={showToast}
        setShowToast={showToastActions.toggleOff}
        text={"Organization referral link"}
      />
    </>
  );
};

export default FacilityStatus;
