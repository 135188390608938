import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";

const PopoverTrigger = ({
  trigger,
  placement,
  variant,
  styleClass,
  contentClass,
  content,
  action,
  icon,
}) => (
  <OverlayTrigger
    trigger={trigger}
    placement={placement}
    overlay={
      <Popover className={`popover-content ${contentClass}`}>{content}</Popover>
    }
    rootClose
  >
    <Button variant={variant} className={styleClass} action={action}>
      <i className={`fa ${icon} subsection-menu--ellipsis`} />
    </Button>
  </OverlayTrigger>
);

PopoverTrigger.propTypes = {
  trigger: PropTypes.any,
  placement: PropTypes.any,
  variant: PropTypes.any,
  styleClass: PropTypes.any,
  content: PropTypes.any,
  action: PropTypes.any,
  icon: PropTypes.string,
  contentClass: PropTypes.string,
};
PopoverTrigger.defaultProps = {
  trigger: "click",
  placement: "auto",
  variant: "link",
  styleClass: "text-dark p-1 d-inline float-right",
  content: "",
  action: "",
  icon: "fa-ellipsis-v",
  contentClass: "",
};

export default PopoverTrigger;
