import { Models } from "@jauntin/reactables";
import { PlanTypes } from "../Constants/planTypes";
import { Gender } from "../Constants/genders";

export interface PrimaryMember extends Models.Address2 {
  planType: PlanTypes;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: Gender;
  phoneNumber: string;
  email: string;
}

export const emptyPrimaryMember: PrimaryMember = {
  planType: null,
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  gender: null,
  phoneNumber: "",
  email: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
};
