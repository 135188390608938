import { Route, Switch } from "react-router-dom";
import {
  getUrl,
  PROMO_CODES_PAGE,
  ADD_PROMO_CODE_PAGE,
} from "../../../Helpers/URLParser";
import SearchPromoCodesContainer from "./SearchPromoCodesContainer";
import AddPromoCode from "./AddPromoCode";
import PromoCodeContainer from "./PromoCodeContainer";

const PromoCodesContainer = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={getUrl(PROMO_CODES_PAGE)}
          component={SearchPromoCodesContainer}
        />
        <Route path={getUrl(ADD_PROMO_CODE_PAGE)} component={AddPromoCode} />
        <Route
          path={`${getUrl(PROMO_CODES_PAGE)}/:id/:pageType`}
          component={PromoCodeContainer}
        />
      </Switch>
    </>
  );
};

export default PromoCodesContainer;
