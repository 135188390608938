import { useState } from "react";
import { useSelector } from "react-redux";
import { downloadMembershipReport } from "../../../Helpers/Reports";
import { AsyncButton } from "@jauntin/react-ui";

const ElixirMembersReport = () => {
  const timezone = useSelector((state) => state.app.timezone);
  const [fetching, setFetching] = useState(false);

  return (
    <div>
      <strong>Elixir Members Report</strong>
      <p>Contains all active Elixir members and cancelled members YTD</p>

      <div className="row">
        <div className="col-md-6">
          <div className="mt-4">
            <AsyncButton
              className="btn btn-primary py-2 border-radius-0"
              disabled={fetching}
              spinning={fetching}
              spinningText="Generating..."
              onClick={() => {
                setFetching(true);
                downloadMembershipReport(
                  "",
                  new Date(),
                  timezone,
                  "ElixirMembershipReport"
                ).then(() => setFetching(false));
              }}
            >
              Download Elixir members report
            </AsyncButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElixirMembersReport;
