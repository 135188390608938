import { toTitleCaseRemoveSeparator } from "@basicare/common/src/Helpers/StringHelper";
import { MemberDetails } from "Features/Members/Rx/Models/memberDetails.model";
import { MemberStatuses } from "@basicare/common/src/Constants/memberStatuses";

const MemberStatus = ({
  member: { status, subscriberNumber, effectiveDate, cancellationDate },
}: {
  member: MemberDetails;
}) => {
  if (status === MemberStatuses.Active && cancellationDate) {
    return (
      <>
        <div className="text-nowrap">
          Active{" "}
          <i
            className="fas fa-exclamation-triangle subsection-table__caution-icon"
            data-toggle="tooltip"
            title={`Membership will be cancelled ${cancellationDate}`}
          />
        </div>
        <div className="text-nowrap small font-italic">
          (Pending Cancellation)
        </div>
      </>
    );
  }
  if (status === MemberStatuses.Created) {
    return (
      <>
        Created{" "}
        {!subscriberNumber ? (
          <i
            className="fas fa-exclamation-triangle subsection-table__caution-icon"
            data-toggle="tooltip"
            title="Member doesn't have assigned number"
          />
        ) : (
          <i
            className="fas fa-exclamation-triangle subsection-table__caution-icon"
            data-toggle="tooltip"
            title={`Member will become active ${effectiveDate}`}
          />
        )}
      </>
    );
  }
  if (status === MemberStatuses.Cancelled) {
    return <>Not Active</>;
  }
  return <>{toTitleCaseRemoveSeparator(status, "_")}</>;
};

export default MemberStatus;
