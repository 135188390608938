import { Route, Switch } from "react-router-dom";
import SearchMembersContainer from "./SearchMembersContainer";
import {
  getUrl,
  MEMBERS_PAGE,
  ADD_MEMBER_PAGE,
} from "../../../Helpers/URLParser";
import AddMember from "./AddMember";
import Member from "./Member";

const MembersContainer = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={getUrl(MEMBERS_PAGE)}
          component={SearchMembersContainer}
        />
        <Route
          path={`${getUrl(MEMBERS_PAGE)}/:id/:pageType`}
          component={Member}
        />
        <Route path={getUrl(ADD_MEMBER_PAGE)} component={AddMember} />
      </Switch>
    </>
  );
};

export default MembersContainer;
