import React from "react";
import PropTypes from "prop-types";
import { FieldArray } from "redux-form";
import { producerPropType } from "../../../Helpers/ProducerModel";
import ContactFields from "./FormElements/ContactFields";
import { ContactCards } from "@jauntin/react-ui";

const Contact = ({ editing, producer }) => {
  return (
    <>
      {editing ? (
        <FieldArray name="producerContacts" component={ContactFields} />
      ) : (
        <ContactCards
          contacts={producer.producerContacts}
          cardTitle="Broker Contact"
          cardClassName="w-100 mt-4"
          headerClassName="bg-transparent font-weight-bold"
          copyOnEmailsLabel="Copied on email containing member information"
        />
      )}
    </>
  );
};

Contact.propTypes = {
  editing: PropTypes.bool.isRequired,
  producer: producerPropType.isRequired,
};

export default Contact;
