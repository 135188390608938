import { useRef, useEffect } from "react";
import { Button, Pagination } from "@jauntin/react-ui";
import { Row, Col, Spinner } from "react-bootstrap";
import { NavLink, useLocation, Link, useHistory } from "react-router-dom";
import { useReactable } from "@reactables/react";
import { RxPaginatedSearch, RxToggle } from "@jauntin/reactables";
import { group, control } from "@reactables/forms";
import { Form, Field } from "@reactables/react-forms";
import {
  ADD_PROMO_CODE_PAGE,
  EDIT_PROMO_CODE_PAGE,
  getUrl,
  PROMO_CODE_PAGE,
} from "Helpers/URLParser";
import PromoCodeService from "Services/PromoCodeService";
import {
  PromoCodeDetails,
  PromoCodeSearch,
} from "@basicare/common/src/Models/promoCode.model";
import API from "Services/API";
import { getQueryString } from "@basicare/common/src/Helpers/getQueryString";
import SearchField from "Features/Shared/Components/SearchField";
import SelectList from "@basicare/common/src/Components/FormElements/SelectList";
import StyledCheckboxField from "@basicare/common/src/Components/FormElements/CheckboxField/StyledCheckboxField";
import { getLgSelectStyle } from "@basicare/common/src/Helpers/ReactSelectStyle";
import { pluralize } from "@basicare/common/src/Helpers/StringHelper";
import { stringHelpers } from "../../../Helpers/FormattingHelpers";
import { planTypeOptions } from "@basicare/common/src/Constants/planTypes";
import {
  getPromoCodeTypeText,
  promoCodeTypeOptions,
} from "@basicare/common/src/Constants/promoCodeTypes";
import { currencyFromInt } from "@basicare/common/src/Helpers/CurrencyHelper";
import { DiscountTypes } from "@basicare/common/src/Constants/discountTypes";
import PopoverTrigger from "Features/Shared/Components/SearchEllipsisPopover";
import copyToClipboard from "../../../Helpers/CopyToClipboard";
import ReferralLinkToast from "../../Shared/Components/ReferralLinkToast";
import LoadingMask from "@basicare/common/src/Components/LoadingMask";

const defaultSearch = {
  search: "",
  subscriptionInterval: null,
  type: null,
  activeOnly: 0,
  page: 1,
};

const getConfigFromSearch = (search: string) => {
  const urlParams = new URLSearchParams(search);
  return group({
    controls: {
      search: control([urlParams.get("search") || defaultSearch.search]),
      subscriptionInterval: control([
        urlParams.get("subscriptionInterval") ||
          defaultSearch.subscriptionInterval,
      ]),
      type: control([urlParams.get("type") || defaultSearch.type]),
      activeOnly: control([
        parseInt(urlParams.get("activeOnly")) || defaultSearch.activeOnly,
      ]),
      page: control([parseInt(urlParams.get("page")) || defaultSearch.page]),
    },
  });
};

const SearchPromoCodesContainer = () => {
  const { search } = useLocation();
  const history = useHistory();

  const searchConfig = useRef(getConfigFromSearch(search)).current;
  const promoCodeService = useRef(new PromoCodeService(new API())).current;

  const [state, actions] = useReactable(
    RxPaginatedSearch<PromoCodeDetails, PromoCodeSearch>,
    {
      name: "rxPromoCodeSearch",
      searchConfig,
      resource: (search) =>
        promoCodeService
          .getPromoCodesSearchResults(search)
          .then(({ data }) => data),
      onSearchChange: (search) => {
        history.replace({ search: getQueryString(search, defaultSearch) });
      },
    }
  );

  const [showCopyToast, { toggleOn, toggleOff }] = useReactable(RxToggle);

  useEffect(() => actions.searchForm.destroy, [actions]);

  if (!state) return;

  return (
    <div className="p-5 mt-2 scroll-part">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="c-gold">Promo Codes</h1>
        <NavLink
          to={getUrl(ADD_PROMO_CODE_PAGE)}
          className="btn btn-primary px-4"
        >
          Add Promo Code
        </NavLink>
      </div>
      <Form rxForm={[state.searchForm, actions.searchForm]}>
        <SearchField
          label="Search by promo code ID or promo code name."
          fieldName="search"
          value={state.searchForm.root.value.search}
          change={(search: string) =>
            actions.searchForm.fieldChangePageReset({
              value: search,
              name: "search",
            })
          }
          totalText={
            state.searchResults.data
              ? `${stringHelpers.commaSeparatedNumber(
                  state.searchResults.data?.total
                )} ${pluralize("promo code", state.searchResults.data?.total)}`
              : ""
          }
        />
        <Row className="align-items-center">
          <Col>
            <Field
              name="subscriptionInterval"
              component={SelectList}
              customStyles={getLgSelectStyle}
              placeholder="Subscription Interval"
              options={planTypeOptions}
              searchable={false}
              inputClassName="form-control-lg pl-0"
              onChange={(value) => {
                actions.searchForm.fieldChangePageReset({
                  name: "subscriptionInterval",
                  value,
                });
              }}
              isClearable
            />
          </Col>
          <Col>
            <Field
              name="type"
              component={SelectList}
              customStyles={getLgSelectStyle}
              placeholder="Promo Code Type"
              options={promoCodeTypeOptions}
              searchable={false}
              inputClassName="form-control-lg pl-0"
              onChange={(value) => {
                actions.searchForm.fieldChangePageReset({
                  name: "type",
                  value,
                });
              }}
              isClearable
            />
          </Col>
          <Col>
            <Col>
              <Field
                name="activeOnly"
                className="mb-0"
                component={StyledCheckboxField}
                label="Show Active Promo Codes Only"
                onChange={(e) => {
                  actions.searchForm.fieldChangePageReset({
                    name: "activeOnly",
                    value: e.currentTarget.checked ? 1 : 0,
                  });
                }}
              />
            </Col>
          </Col>
        </Row>
        {(() => {
          if (!state.searchResults.data) return <Spinner animation="border" />;

          const {
            searchResults: {
              data: { data: members, ...pagination },
            },
          } = state;

          const noResultsMessage =
            pagination.total === 0
              ? "Sorry, we couldn’t find any results. Please check the spelling or try different search term."
              : "";
          return noResultsMessage ? (
            <div className="row">
              <div className="col-sm-8 h4">{noResultsMessage}</div>
            </div>
          ) : (
            <>
              <LoadingMask loading={state.searchResults.loading}>
                <table className="table subsection-table">
                  <thead>
                    <tr>
                      <th scope="col">Promo Code ID</th>
                      <th scope="col">Promo Code Name</th>
                      <th scope="col">Subscription Interval</th>
                      <th scope="col">Promo Code Type</th>
                      <th scope="col">Discount Amount</th>
                      <th scope="col">Current Usage Count</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.searchResults.data.data.map(
                      ({
                        id,
                        code,
                        name,
                        subscriptionInterval,
                        type,
                        discountType,
                        discountAmount,
                        startDate,
                        endDate,
                        redeems,
                        usages,
                      }) => (
                        <tr key={`row${id}`}>
                          <td>
                            <Link to={getUrl(PROMO_CODE_PAGE, id)}>
                              <Button
                                className="btn btn-link btn--edit text-left text-primary"
                                text={code}
                                onClick={() => {}}
                              />
                            </Link>
                            <button
                              className="undecorate-btn ml-2"
                              onClick={() => {
                                copyToClipboard(code);
                                toggleOn();
                              }}
                            >
                              <i className="fal fa-copy" />
                            </button>
                          </td>
                          <td>{name}</td>
                          <td className="text-capitalize">
                            {subscriptionInterval}
                          </td>
                          <td>{getPromoCodeTypeText(type)}</td>
                          <td>
                            {discountAmount
                              ? `${
                                  discountType === DiscountTypes.Fixed
                                    ? currencyFromInt(
                                        discountAmount
                                      ).formatDollars()
                                    : `${discountAmount} %`
                                }`
                              : discountAmount}
                          </td>
                          <td>
                            {redeems}
                            {usages && `/${usages}`}
                          </td>
                          <td>{startDate}</td>
                          <td>{endDate}</td>
                          <td className="d-flex">
                            <PopoverTrigger
                              contentClass="popover-content__fit-content"
                              icon="fa-ellipsis-v"
                              content={
                                <>
                                  <Link to={getUrl(PROMO_CODE_PAGE, id)}>
                                    <button
                                      className="btn d-block popover-item__btn"
                                      onClick={() => {}}
                                    >
                                      <i className="fa fa-eye" /> View
                                    </button>
                                  </Link>
                                  <Link to={getUrl(EDIT_PROMO_CODE_PAGE, id)}>
                                    <button
                                      className="btn d-block popover-item__btn"
                                      onClick={() => {}}
                                    >
                                      <i className="fa fa-edit" /> Edit
                                    </button>
                                  </Link>
                                </>
                              }
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </LoadingMask>
              <div className="d-flex justify-content-end">
                <Pagination
                  pagination={pagination}
                  goToPage={actions.searchForm.changePage}
                />
              </div>
            </>
          );
        })()}
      </Form>
      <ReferralLinkToast
        show={showCopyToast}
        setShowToast={toggleOff}
        text="Promo code"
      />
    </div>
  );
};

export default SearchPromoCodesContainer;
