/**
 * Middleware used to handle changes in location
 *
 */
import { ConnectedRouter } from "@jauntin/react-ui";
import { getPageType } from "../../Helpers/URLParser";

// Sets a body class related to page type
// e.g. page-login, page-policies, etc
const setBodyClass = (location) => {
  const page = getPageType(location);
  document.body.classList.forEach((n) => {
    if (/^page-/.test(n)) {
      document.body.classList.remove(n);
    }
  });
  if (page) {
    document.body.classList.add(`page-${page}`);
  }
};

export default (store) => (next) => (action) => {
  if (action.type === ConnectedRouter.LOCATION_CHANGE) {
    setBodyClass(action.payload.location.pathname);
  }
  return next(action);
};
