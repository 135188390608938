import cc from "card-validator";
import { dateHelpers } from "@jauntin/utilities";
import { validators } from "@jauntin/utilities";

const { numberOfDays } = dateHelpers;

// Quick list of simple validators grabbed from Redux Form's site.
// Seriously needs tests and filling out with further functions

export const required = validators.required;
export const arrayNotEmpty = validators.arrayNotEmpty;
export const notZero = validators.notZero;
export const maxLength = validators.maxLength;
export const minLength = validators.minLength;
export const number = validators.number;
export const minValue = validators.minValue;
export const maxValue = validators.maxValue;
export const oneOf = validators.oneOf;
export const email = validators.email;
export const alphaNumeric = validators.alphaNumeric;
export const phoneNumber = validators.phoneNumber;
export const creditCardCvv = validators.creditCardCvv;
export const creditCardNumber = validators.creditCardNumber();

export const eligibility = (value) =>
  value !== "no" ? `This must be no` : undefined;

export const requiredDates = (value) => {
  const dates = Object.values(value || {});
  for (let i = 0; i < dates.length; i += 1) {
    if (!dates[i]) return "Required";
  }
  return undefined;
};

const validateDigits = (value, digits) => {
  return value && new RegExp(`^([0-9]{${digits}})$`, "i").test(value)
    ? undefined
    : `Invalid, must be ${digits} digits`;
};

export const zipCode = (value) => validateDigits(value, 5);
export const zipCodePlus4 = (value) =>
  new RegExp(`^[0-9]{5}(-[0-9]{4})?$`).test(value)
    ? undefined
    : `Enter a valid zip code`;
export const fourDigits = (value) => validateDigits(value, 4);
export const fiveDigits = (value) => validateDigits(value, 5);
// personCompany just checks for person or company, it is not used for field level validation,
// BUT it IS required to make the validation work when toggling. Check reducer is...Valid funcs.
export const personCompany = (value) =>
  value === "Person" ? undefined : "company";
export const sameContact = (value) =>
  value === "yes" ? undefined : "not the same contact as insured";
export const samePayee = (value) =>
  value === "yes" ? undefined : "not the same billing address as contact";
export const dateInput = (value) =>
  value && /^((0|1)\d{1})\/((0|1|2)\d{1})\/((19|20)\d{2})/g.test(value)
    ? undefined
    : "Invalid date, must be MM/DD/YYYY";

export const creditCardDate = (ccMonthCtrl, ccYearCtrl) => (value, allValues) =>
  !Boolean(allValues[ccMonthCtrl]) ||
  !Boolean(allValues[ccYearCtrl]) ||
  cc.expirationDate(`${allValues[ccMonthCtrl]}/${allValues[ccYearCtrl]}`)
    .isValid
    ? undefined
    : "Invalid expiration date";

export const atLeastOneDayMustBePicked = (_, allValues) =>
  allValues.daysOfWeekField.reduce((prev, curr) => (curr ? true : prev), false)
    ? undefined
    : "At least one day must be picked";
export const checked = (value) =>
  value
    ? undefined
    : "Please click checkbox to acknowledge policy cancellation";
export const facilityCodeValidation = (value) =>
  value && !/^[a-zA-Z0-9]{3,4}$/i.test(value)
    ? "Only alphanumeric characters"
    : undefined;
export const venueCodeValidation = (value) =>
  value && !/^\d{3,5}$/i.test(value) ? "Only numbers" : undefined;
export const providersValidation = (values) => {
  let inputErrors = [];
  let hasErrors = false;
  let providerError = false;
  const totalDays = numberOfDays(values.eventDates);

  const errors = {};
  const providersList = [
    "performersList",
    "goodsVendorsList",
    "foodVendorsList",
    "exhibitorsList",
  ];

  const providerCallback = (p, c) => {
    if (
      c.name &&
      parseInt(c.days, 10) > 0 &&
      parseInt(c.days, 10) <= totalDays
    ) {
      inputErrors.push({});
      return p;
    }

    const error = {};

    if (!c.name || c.name.trim() === "") error.name = " ";
    if (!parseInt(c.days, 10)) error.days = " ";
    inputErrors.push(error);

    hasErrors = true;
    return true;
  };

  for (let i = 0; i < providersList.length; i += 1) {
    if (values[providersList[i]] && values[providersList[i]].length)
      providerError = values[providersList[i]].reduce(providerCallback, false);

    if (providerError) {
      errors[providersList[i]] = inputErrors;

      let providerName = "performer";
      if (providersList[i] === providersList[1]) providerName = "goods vendor";
      if (providersList[i] === providersList[2])
        providerName = "food and/or beverage vendor";
      if (providersList[i] === providersList[3]) providerName = "exhibitor";

      let errorDays = false;
      let errorName = false;
      errors[providersList[i]].forEach((item) => {
        if (item.days) errorDays = true;
        if (item.name) errorName = true;
      });
      if (errorName)
        errors[
          providersList[i]
        ]._error = `Please enter a name for each ${providerName}.`;
      if (errorDays)
        errors[
          providersList[i]
        ]._error = `Please enter at least 1 day for each ${providerName}.`;
    }

    inputErrors = [];
    providerError = false;
  }

  if (hasErrors) return errors;
  return undefined;
};

export const firstCharacterIsNumber = (value) =>
  /^\d$/.test(value.charAt(0)) ? undefined : "First character is not a number";

export const selectionRequired = (value) =>
  !value ? "Please make a selection" : undefined;

export const accepted = (value) =>
  !value ? "Please accept the following" : undefined;
