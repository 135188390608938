import API from "../Services/API";
import MembershipService from "../Services/MembershipService";
import download from "downloadjs";
import { dateHelpers } from "@jauntin/utilities";

const getAdapter = (type) => {
  if (type === "ScriptClaimMembershipReport") {
    return "ScriptClaimFixedWidthAdapter";
  }

  return "CsvAdapter";
};

const getFormat = (type) => {
  return ["ScriptClaimMembershipReport"].includes(type) ? "txt" : "csv";
};

export const sendReportByType = (type) =>
  new MembershipService(new API()).sendReportByType(type).then(() => {
    console.log("Report sent");
  });

export const downloadMembershipReport = (
  startDate,
  endDate,
  timezone,
  type
) => {
  const format = getFormat(type);

  return new MembershipService(new API())
    .downloadMemberReport(startDate, endDate, timezone, type, getAdapter(type))
    .then(({ data }) =>
      download(
        data,
        `${type} ${
          startDate
            ? dateHelpers.dateOnlyStringFormat(startDate, timezone) + ","
            : ""
        }${dateHelpers.dateOnlyStringFormat(endDate, timezone)}.${format}`,
        `text/${format}`
      )
    );
};
