import API from "../../Services/API";
import UserService from "../../Services/UserService";
import {
  setUsersLoading,
  updateUsersTable,
  resetUsers,
  errorResponse,
  userSaveSuccess,
  setDeleteUserError,
} from "./actions";

const userService = new UserService(new API());

const loadUsers =
  (page = null, perPage = null) =>
  (dispatch) => {
    dispatch(setUsersLoading(true));
    userService
      .getPaginatedUsers(page, perPage)
      .then((response) => {
        dispatch(updateUsersTable(response.data));
      })
      .catch((err) => {
        dispatch(errorResponse(err));
        dispatch(setUsersLoading(false));
      });
  };

const deleteUser = (user) => (dispatch) => {
  userService
    .deleteUser(user)
    .then(() => {
      dispatch(loadUsers());
    })
    .catch((err) => {
      if (err.response.status === 403) {
        dispatch(setDeleteUserError(err.response.data?.message));
      }
      dispatch(errorResponse(err));
    });
};

export {
  loadUsers,
  resetUsers,
  deleteUser,
  setUsersLoading,
  userSaveSuccess,
  setDeleteUserError,
};
