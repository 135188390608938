import React from "react";
import {
  FieldInputProps,
  FieldMetaProps,
} from "@basicare/common/src/Constants/ReduxFormPropTypes";
import PropTypes from "prop-types";
import { facilitiesPropType } from "../../../../constants";
import { getLgSelectStyle } from "@basicare/common/src/Helpers/ReactSelectStyle";
import SelectList from "@basicare/common/src/Components/FormElements/SelectList";
import { Field } from "redux-form";

const FacilityField = ({
  input,
  setFacility,
  facilitiesList,
  getFacilities,
}) => (
  <div className="form-group">
    <Field
      name="organization"
      value={input.value}
      component={SelectList}
      customStyles={getLgSelectStyle}
      label="Organization"
      placeholder="Type to search"
      options={facilitiesList}
      onChange={(value) => {
        input.onChange(value);
        setFacility(value);
      }}
      onBlur={() => input.onBlur(input.value)}
      onInputChange={(inputValue) => {
        if (inputValue) {
          getFacilities(inputValue);
        } else {
          setFacility(input.value);
        }
      }}
      inputClassName="form-control-lg"
      isClearable
    />
  </div>
);

FacilityField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  facilitiesList: PropTypes.arrayOf(facilitiesPropType),
  getFacilities: PropTypes.func.isRequired,
  setFacility: PropTypes.func.isRequired,
};

FacilityField.defaultProps = {
  facilitiesList: [],
};

export default FacilityField;
