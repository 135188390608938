import { Form, Field } from "@reactables/react-forms";
import { Button } from "@jauntin/react-ui";
import { useReactable } from "@reactables/react";
import TextField from "@basicare/common/src/Components/FormElements/TextField";
import SettingService from "Services/SettingService";
import API from "Services/API";
import InfoModal from "Features/Shared/Components/InfoModal";
import { format } from "date-fns";
import { RxTimeTravelRequests } from "../Rx/RxTimeTravelRequests";
import { RxSettingForm } from "../Rx/RxSettingForm";

const SettingsForm = () => {
  const settingService = new SettingService(new API());
  const [state, actions] = useReactable(RxSettingForm);
  const [timeTravelState, timeTravelActions] = useReactable(
    RxTimeTravelRequests,
    { settingService }
  );
  const data =
    timeTravelState && timeTravelState.getDate && timeTravelState.getDate.data
      ? timeTravelState.getDate.data
      : format(Date.now(), "yyyy-MM-dd HH:mm");

  if (!state) return;

  return (
    <>
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Time Travel</h4>
        </div>
      </div>
      <div className="content__body">
        <Form rxForm={[state, actions]}>
          <Field
            name="date"
            component={TextField}
            label="Enter Date to Travel"
            placeholder="YYYY-MM-DD HH:MM"
            inputClassName="form-control-lg date-field"
            errorMessages={{
              dateTimeFormat: "Incorrect date-time format",
            }}
          />
        </Form>
        <p className="mb-4">Current app date is: {data}</p>
        <Button
          text="Clear"
          type="button"
          className="btn-outline-secondary"
          onClick={timeTravelActions.resetDate.send}
        />
        <Button
          text="Apply"
          type="submit"
          className="btn-primary ml-3"
          onClick={() =>
            timeTravelActions.saveDate.send({ date: String(state.date.value) })
          }
          disabled={!state.date.valid || timeTravelState.saveDate.loading}
        />
      </div>

      <InfoModal
        show={timeTravelState.saveDate.success}
        handleClose={timeTravelActions.saveDate.resetState}
        message="Success! Date-time is set to time travel."
      />

      <InfoModal
        show={Boolean(timeTravelState.saveDate.error)}
        handleClose={timeTravelActions.saveDate.resetState}
        message="There was an error processing your request. Please try again."
      />

      <InfoModal
        show={timeTravelState.resetDate.success}
        handleClose={timeTravelActions.resetDate.resetState}
        message="Success! Date-time is reset for time travel."
      />

      <InfoModal
        show={Boolean(timeTravelState.resetDate.error)}
        handleClose={timeTravelActions.resetDate.resetState}
        message="There was an error processing your request. Please try again."
      />
    </>
  );
};

export default SettingsForm;
