/**
 *
 * Custom reducers for Redux Form
 *
 */
import { loginFormName, producerFormName, newUserForm } from "../../constants";
import { SET_FORM_VALUES, USER_SAVE_SUCCESS } from "../Actions/actions";

const setValues = (state, action, formName) => {
  const updated = { ...state };
  if (action.type === SET_FORM_VALUES && action.payload.formName === formName) {
    updated.values = updated.values || {};
    const { values } = action.payload;
    Object.keys(values || {}).forEach((key) => {
      updated.values[key] = values[key];
    });
    return updated;
  }
  return state;
};

export default {
  [loginFormName]: (state, action) => {
    return { ...state, ...setValues(state, action, loginFormName) };
  },
  [producerFormName]: (state, action) => {
    return { ...state, ...setValues(state, action, producerFormName) };
  },
  [newUserForm]: (state, action) => {
    if (action.type === USER_SAVE_SUCCESS) {
      return undefined;
    }
    return state;
  },
};
