export enum PromoCodeTypes {
  Discount = "discount",
  Trial = "trial",
  TrialPurchase = "trialPurchase",
}

export const getPromoCodeTypeText = (promoCodeType: PromoCodeTypes): string => {
  switch (promoCodeType) {
    case PromoCodeTypes.TrialPurchase:
      return "Trial (payment information required at signup)";
    case PromoCodeTypes.Trial:
      return "Trial (payment information not required)";
    default:
      return "Discount";
  }
};

export const promoCodeTypeOptions = [
  {
    label: getPromoCodeTypeText(PromoCodeTypes.Discount),
    value: PromoCodeTypes.Discount,
  },
  {
    label: getPromoCodeTypeText(PromoCodeTypes.TrialPurchase),
    value: PromoCodeTypes.TrialPurchase,
  },
  {
    label: getPromoCodeTypeText(PromoCodeTypes.Trial),
    value: PromoCodeTypes.Trial,
  },
];
