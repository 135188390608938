import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  getUrl,
  getPageType,
  getProducerId,
  PRODUCERS_PAGE,
  PRODUCER_PAGE,
  PRODUCER_NOTES_PAGE,
} from "../../../Helpers/URLParser";

const ProducersBreadcrumb = ({ location, commissionID }) => {
  const pageType = getPageType(location);

  return (
    <div className="breadcrumbs">
      <NavLink to={getUrl(PRODUCERS_PAGE)}>Brokers</NavLink>
      {pageType === PRODUCER_PAGE && ` / Broker # ${commissionID}`}
      {pageType === PRODUCER_NOTES_PAGE && (
        <>
          {" / "}
          <NavLink to={getUrl(PRODUCER_PAGE, getProducerId(location))}>
            {`Broker # ${commissionID}`}
          </NavLink>
          {" / "}
          Notes
        </>
      )}
    </div>
  );
};

ProducersBreadcrumb.propTypes = {
  location: PropTypes.string.isRequired,
  commissionID: PropTypes.string,
};

ProducersBreadcrumb.defaultProps = {
  commissionID: "",
};

export default ProducersBreadcrumb;
