import { hasControlTouchError } from "@basicare/common/src/Rx/Selectors/hasControlTouchError.selector";
import {
  PromoCodeForm,
  PromoCodePayload,
} from "@basicare/common/src/Models/promoCode.model";
import { PromoCodeTypes } from "@basicare/common/src/Constants/promoCodeTypes";
import { DiscountTypes } from "@basicare/common/src/Constants/discountTypes";
import { format } from "date-fns";
import { DATE_FORMAT } from "@basicare/common/src/Constants/dateFormat";

export const getAddPromoActionEnabled = (form, submitTouched: boolean) => {
  const visibleTouchError = hasControlTouchError(form);

  const { promoCodeDiscountDollarAmount, discountAmountRequired } =
    form.root.errors;

  const { touched: discountAmountTouched } = form["discountAmount"];

  const promoCodeDollarAmountErrorVisible =
    promoCodeDiscountDollarAmount && discountAmountTouched;

  const discountRequiredErrorVisible =
    discountAmountRequired && discountAmountTouched;

  return (
    form.root.valid ||
    visibleTouchError ||
    promoCodeDollarAmountErrorVisible ||
    discountRequiredErrorVisible ||
    submitTouched
  );
};

export const getPromoCodePayload = (
  promoCode: PromoCodeForm
): PromoCodePayload => {
  const {
    name,
    code,
    type,
    subscriptionInterval,
    billingCycles,
    dateRange: { startDate, endDate },
    discountType,
    discountAmount,
    usages,
  } = promoCode;

  const promoCodeTypeFields =
    type === PromoCodeTypes.Discount
      ? {
          discountType,
          discountAmount:
            discountType === DiscountTypes.Fixed
              ? parseFloat(discountAmount) * 100
              : parseInt(discountAmount),
        }
      : {};

  const parsedUsages = parseInt(usages);
  const parsedBillingCycles = parseInt(billingCycles.count);

  return {
    name,
    code,
    type,
    subscriptionInterval,
    ...promoCodeTypeFields,
    usages: isNaN(parsedUsages) ? null : parsedUsages,
    billingCycles: isNaN(parsedBillingCycles) ? null : parsedBillingCycles,
    startDate: format(new Date(startDate), DATE_FORMAT),
    endDate: endDate ? format(new Date(endDate), DATE_FORMAT) : null,
  };
};
