import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  reduxForm,
  reset,
  initialize,
  getFormValues,
  isPristine,
  isValid,
} from "redux-form";
import { ConnectedRouter } from "@jauntin/react-ui";
import { compose } from "redux";
import {
  modalUpdateServerErrorMessage,
  producerFormName,
  DEFAULT_PRODUCER_NAME,
} from "../../../constants";
import EntityHeader from "../../Shared/Components/EntityHeader";
import Status from "./Status";
import Information from "./Information";
import Contact from "./Contact";
import { producerPropType } from "../../../Helpers/ProducerModel";
import ModalDiscard from "./ModalDiscard";
import ModalUpdate from "./ModalUpdate";
import ProducerService from "../../../Services/ProducerService";
import API from "../../../Services/API";
import {
  errorResponse,
  getProducer,
  setEditingAction,
  logout as logoutAction,
  setValidProducerCode,
  setHasCheckedProducerCode,
  checkAndSetValidProducerCode,
} from "../../../Redux/Actions/actions";
import ModalUpdateError from "../../Shared/Components/ModalUpdateError";
import RouteLeavingEditGuard from "../../Shared/Components/RouteLeavingEditGuard";
import { getUrl, LOGIN_PAGE } from "../../../Helpers/URLParser";
import { contactEmailsUnique } from "../../../Helpers/validators";
import { fileToBase64 } from "@basicare/common/src/Helpers/Base64EncodeFile";

const allFormValues = getFormValues(producerFormName);

const Producer = ({
  editReset,
  producer,
  update,
  pristine,
  valid,
  editing,
  setEditing,
  validProducerCode,
  setValidProducerCode,
  hasCheckedProducerCode,
  setHasCheckedProducerCode,
  checkAndSetValidProducerCode,
  navToPath,
  logout,
}) => {
  const [showModalDiscard, setShowModalDiscard] = useState(false);
  const handleCloseDiscard = () => setShowModalDiscard(false);
  const handleShowDiscard = () => setShowModalDiscard(true);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const handleCloseUpdate = () => setShowModalUpdate(false);
  const handleShowUpdate = () => setShowModalUpdate(true);
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);
  const handleShowError = () => setShowModalError(true);
  const [leaveMidEditNextNavPath, setLeaveMidEditNextNavPath] = useState("");

  return (
    <>
      <EntityHeader
        editing={editing}
        handleShowDiscard={handleShowDiscard}
        handleShowUpdate={handleShowUpdate}
        setEditing={setEditing}
        editReset={editReset}
        pristine={pristine}
        valid={valid}
        hasCheckedCode={hasCheckedProducerCode}
        validCode={validProducerCode}
        entityType="Broker"
        entityName={producer.name}
        defaultEntityName={DEFAULT_PRODUCER_NAME}
      />

      <div className="content__body">
        <Status editing={editing} producer={producer} />
        <Information
          editing={editing}
          producer={producer}
          validProducerCode={validProducerCode}
          setValidProducerCode={setValidProducerCode}
          hasCheckedProducerCode={hasCheckedProducerCode}
          setHasCheckedProducerCode={setHasCheckedProducerCode}
          checkAndSetValidProducerCode={checkAndSetValidProducerCode}
        />
        <Contact editing={editing} producer={producer} />
      </div>

      <ModalDiscard
        show={showModalDiscard}
        handleClose={handleCloseDiscard}
        setPath={setLeaveMidEditNextNavPath}
        action={() => {
          setEditing(false);
          editReset();
          if (leaveMidEditNextNavPath === getUrl(LOGIN_PAGE)) {
            logout();
          }
          if (leaveMidEditNextNavPath) {
            navToPath(leaveMidEditNextNavPath);
          }
        }}
      />

      <ModalUpdate
        show={showModalUpdate}
        action={() => update(handleCloseUpdate, handleShowError, setEditing)}
        handleClose={handleCloseUpdate}
      />

      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />

      {/* Route guard to prompt user with modal when trying to navigate away while in edit mode */}
      <RouteLeavingEditGuard
        when={editing || !!leaveMidEditNextNavPath}
        path={leaveMidEditNextNavPath}
        showDiscard={handleShowDiscard}
        setPath={setLeaveMidEditNextNavPath}
      />
    </>
  );
};

Producer.propTypes = {
  editReset: PropTypes.func.isRequired,
  producer: producerPropType.isRequired,
  update: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  setEditing: PropTypes.func.isRequired,
  validProducerCode: PropTypes.bool.isRequired,
  hasCheckedProducerCode: PropTypes.bool.isRequired,
  checkAndSetValidProducerCode: PropTypes.func.isRequired,
  navToPath: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  producer: state.producers.currentProducer,
  contacts: state.producers.values
    ? state.producers.values.producerContacts
    : [{}],
  editing: state.producers.editing,
  valid: isValid(producerFormName),
  pristine: isPristine(producerFormName),
  validProducerCode: state.producers.validProducerCode,
  hasCheckedProducerCode: state.producers.hasCheckedProducerCode,
});

const mapDispatchToProps = (dispatch) => ({
  editReset: (producer) => {
    dispatch(reset(producerFormName));
    dispatch(
      initialize(producerFormName, {
        producerStatus: producer.status,
        producerName: producer.name,
        producerCommissionId: producer.commissionId,
        producerContacts: producer.producerContacts,
        producerLogoUrl: producer.logoUrl || null,
        producerImgFile: null,
      })
    );
  },
  setEditing: (value) => dispatch(setEditingAction(value)),
  navToPath: (path) => dispatch(ConnectedRouter.push(path)),
  logout: () => dispatch(logoutAction()),
  setValidProducerCode: (value) => dispatch(setValidProducerCode(value)),
  setHasCheckedProducerCode: (value) =>
    dispatch(setHasCheckedProducerCode(value)),
  checkAndSetValidProducerCode: (commissionId) =>
    dispatch(checkAndSetValidProducerCode(commissionId)),
  update: (handleCloseUpdate, handleShowError, setEditing) =>
    dispatch((_, getState) => {
      const values = allFormValues(getState());
      const state = getState();
      const producer = state.producers.currentProducer;
      const { id } = producer;

      const data = {
        id,
        status: values.producerStatus,
        name: values.producerName,
        commissionId: values.producerCommissionId,
        contacts: values.producerContacts,
        logoUrl: values.producerLogoUrl ? "notChanged" : null,
      };

      let updateProducerPromise;

      if (values.producerImgFile) {
        updateProducerPromise = fileToBase64(values.producerImgFile).then(
          (content) => {
            const logoImageFile = {
              fileName: values.producerImgFile.name,
              size: values.producerImgFile.size,
              content,
            };
            return new ProducerService(new API()).putProducerEditDetails(id, {
              ...data,
              logoImageFile,
            });
          }
        );
      } else {
        updateProducerPromise = new ProducerService(
          new API()
        ).putProducerEditDetails(id, data);
      }

      return updateProducerPromise
        .then((response) => {
          if (response.status === 204) {
            handleCloseUpdate();
            dispatch(getProducer(id));
            setEditing(false);
          }
        })
        .catch((err) => {
          handleCloseUpdate();
          handleShowError();
          dispatch(errorResponse(err));
        });
    }),
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  editReset: () => dispatchProps.editReset(stateProps.producer),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: producerFormName,
    validate: (values) => ({
      ...contactEmailsUnique(values, "producerContacts"),
    }),
  })
)(Producer);
