import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Form } from "react-bootstrap";
import { adminUserPropType } from "../../../Helpers/UserModel";
import { Button } from "@jauntin/react-ui";
import ConfirmRemoveCheckbox from "./FormElements/ConfirmRemoveCheckbox";

const ModalRemove = ({ show, handleRemove, handleClose, user }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);

  const onClose = () => {
    handleClose();
    setIsConfirmed(false);
  };

  const onRemove = () => {
    handleRemove(user);
    setIsConfirmed(false);
  };

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header className="py-3 border-bottom-0" closeButton />
      <Modal.Body className="pt-4 text-center">
        <p>This action will remove this admin.</p>
        <p>Is this what you wish to do?</p>
      </Modal.Body>
      <Modal.Footer className="border-top-0 flex-column pb-4">
        <Form.Group controlId="confirmRemoveCheckbox" className="mb-4">
          <ConfirmRemoveCheckbox
            checked={isConfirmed}
            onChange={() => setIsConfirmed(!isConfirmed)}
          />
        </Form.Group>
        <Form.Group className="my-3">
          <Button
            className="btn btn-outline-secondary text-capitalize mr-2"
            text="Keep Admin"
            onClick={onClose}
          />
          <Button
            disabled={!isConfirmed}
            className="btn btn-primary text-capitalize ml-2"
            onClick={onRemove}
            text="Remove Admin"
          />
        </Form.Group>
      </Modal.Footer>
    </Modal>
  );
};

ModalRemove.propTypes = {
  show: PropTypes.bool,
  user: adminUserPropType,
  handleRemove: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

ModalRemove.defaultProps = {
  show: false,
  user: null,
};

export default ModalRemove;
