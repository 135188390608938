import { useState } from "react";
import { Button } from "react-bootstrap";
import copyToClipboard from "../../../Helpers/CopyToClipboard";
import ReferralLinkToast from "../../Shared/Components/ReferralLinkToast";

const PromoCodeReferral = ({ referralLink }: { referralLink: string }) => {
  const [showToast, setShowToast] = useState(false);

  return (
    <>
      <div>
        <div className="row justify-content-between align-items-center mb-4">
          <div className="col d-flex justify-content-end">
            <div className="mb-0 text-right my-auto mr-2">
              <strong>Referral link:</strong>
              <em>{` ${referralLink}`}</em>
            </div>
            <div className="my-auto">
              <Button
                className="btn btn-primary px-4 text-nowrap"
                type="button"
                onClick={() => {
                  copyToClipboard(referralLink);
                  setShowToast(true);
                }}
              >
                Copy
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ReferralLinkToast
        show={showToast}
        setShowToast={setShowToast}
        text={"Promo Code link"}
      />
    </>
  );
};

export default PromoCodeReferral;
