import FacilityForm from "./FacilityForm";
import { useHistory } from "react-router-dom";
import { Button, TwoStageButton } from "@jauntin/react-ui";
import { getUrl, FACILITIES_PAGE } from "Helpers/URLParser";
import { useReactable } from "@reactables/react";
import { RxRequest, RxToggle, Selectors } from "@jauntin/reactables";
import FacilityService from "Services/FacilityService";
import API from "Services/API";
import { facilityFormToSavePayload } from "../Models/facility.model";
import { ExtendedMeta } from "@jauntin/utilities";
import ModalDiscardAddNew from "Features/Shared/Components/ModalDiscardAddNew";

const AddFacility = () => {
  const history = useHistory();

  const [requestState, { send: addOrganization }] = useReactable(RxRequest, {
    name: "rxAddOrganization",
    resource: (data) => {
      return new FacilityService(new API()).postAddNewFacilityDetails(data);
    },
    onSuccess: () => {
      history.push(getUrl(FACILITIES_PAGE));
    },
  });

  const [submitTouched, { toggleOn: touchSubmit }] = useReactable(RxToggle);
  const [showDiscardModal, discardModalToggle] = useReactable(RxToggle);

  const showErrors = ({ touched, error }: ExtendedMeta) =>
    (touched || submitTouched) && Boolean(error);

  return (
    <>
      <FacilityForm showErrors={showErrors}>
        {({ formState, formFields }) => (
          <>
            <div className="content__header col-auto">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="m-0 font-weight-bold">Organization Overview</h4>
                <div className="d-flex float-right">
                  <Button
                    text="Discard Changes"
                    className="btn btn-outline-secondary px-4 mx-2"
                    onClick={discardModalToggle.toggleOn}
                  />
                  {(() => {
                    const disabled =
                      (submitTouched && !formState.root.valid) ||
                      Selectors.hasControlTouchError(formState);
                    return (
                      <TwoStageButton
                        actionEnabled={formState.root.valid}
                        disabled={disabled}
                        className="btn btn-primary px-4 ml-2 text-nowrap"
                        spinning={requestState.loading}
                        touch={touchSubmit}
                        text="Save Organization"
                        action={() => {
                          addOrganization(
                            facilityFormToSavePayload(formState.root.value)
                          );
                        }}
                      />
                    );
                  })()}
                </div>
              </div>
            </div>
            <div className="content__body">{formFields}</div>
          </>
        )}
      </FacilityForm>
      <ModalDiscardAddNew
        show={showDiscardModal}
        handleClose={discardModalToggle.toggleOff}
        confirmAction={() => {
          history.push(getUrl(FACILITIES_PAGE));
        }}
      />
    </>
  );
};

export default AddFacility;
