import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { requestResetPasswordForm } from "../../../constants";
import { required, email } from "../../../Helpers/validators";
import {
  FormPropTypes,
  FieldInputProps,
  FieldMetaProps,
} from "@basicare/common/src/Constants/ReduxFormPropTypes";
import AuthService from "@basicare/common/src/Helpers/AuthService";
import { RESET_PASSWORD, getUrlWithDomain } from "../../../Helpers/URLParser";
import API from "../../../Services/API";
import LoginLockOut from "./LoginLockOut";
import {
  setRequestResetFormSuccessMessage,
  setLoginLockedOut,
} from "../../../Redux/Actions/actions";
import { getCaptchaToken } from "../../../Helpers/ReCaptchaHelper";
import Logo from "@basicare/common/src/Assets/Images/basicare.1200.transparent.png";

const emailField = ({ input, meta }) => (
  <div className="form-group">
    <p className="text-left">
      Please enter your email to receive instructions on how to reset your
      password.
    </p>
    <br />
    <input
      {...input}
      id={input.name}
      className="form-control form-control-lg"
      placeholder="email"
      aria-label="Email"
      type="text"
    />
    {meta.touched && meta.error && (
      <div className="form-error my-1 ml-2 text-left">{meta.error}</div>
    )}
  </div>
);
emailField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
};

const RequestResetPassword = ({
  valid,
  handleSubmit,
  submitting,
  error,
  successMessage,
  lockedOut,
  setShowLockout,
}) => {
  return (
    (!lockedOut && (
      <form onSubmit={handleSubmit}>
        <div className="container d-flex flex-column text-left pt-5 mt-5">
          <img
            src={Logo}
            alt="Logo"
            width="400"
            className="img-fluid mx-auto"
          />
          <div className="col-sm-6 col-lg-4 mx-auto">
            <div className="mt-3">
              <Field
                component={emailField}
                validate={[required, email]}
                name="email"
              />
            </div>

            {error && <div className="form-error my-1 mx-2">{error}</div>}
            {successMessage && (
              <div className="form-success mx-2">{successMessage}</div>
            )}
            <div>
              <button
                disabled={!valid || submitting}
                className="btn btn-primary my-3 w-100"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    )) || (
      <LoginLockOut
        setShowLockout={setShowLockout}
        title="Return to Request Reset Password"
      />
    )
  );
};
const RequestResetPasswordForm = reduxForm({ form: requestResetPasswordForm })(
  RequestResetPassword
);
RequestResetPassword.propTypes = FormPropTypes;

const mapStateToProps = (state) => ({
  successMessage: state.auth.requestResetFormSuccessMessage,
  lockedOut: state.app.isLockedOut,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) =>
    dispatch(async () => {
      try {
        await new AuthService(new API()).requestPasswordReset({
          ...values,
          captcha: await getCaptchaToken(),
          ...{ resetLinkUrl: getUrlWithDomain(RESET_PASSWORD) },
        });
        dispatch(
          setRequestResetFormSuccessMessage(
            "Please check your email inbox for further instructions."
          )
        );
      } catch (error) {
        const { response } = error;
        const { data } = response;
        const { errors } = data;
        let message = "Unable to complete this request";
        let lockedOut = false;

        if (
          response.status === 429 &&
          data &&
          errors &&
          errors.email.length > 0
        ) {
          const [attempts] = errors.email;
          message = attempts;
          lockedOut = true;
        }

        dispatch(setLoginLockedOut(lockedOut));
        throw new SubmissionError({
          _error: message,
        });
      }
    }),
  setShowLockout: (val) => dispatch(setLoginLockedOut(val)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestResetPasswordForm);
