import { Models, Configs } from "@jauntin/reactables";
import { DEFAULT_ELIXIR_GROUP_ID } from "../../../constants";
export interface Facility {
  id: number;
  code: string;
  name: string;
  productCode: string;
  elixirGroupId: string;
  recuroGroupId: string;
  requiresRecuroSubscriberNumber: boolean;
  doNotSendEmailsToMember: boolean;
  logoUrl: string;
  monthlyPrice: string;
  recuroAgentId: any;
  hideFacilityInfo: boolean;
  producer: {
    name: string;
    commissionId: string;
    logoUrl: string;
    id: number;
    createdAt: string;
    updatedAt: string;
    status: string;
    facilities: Array<{
      id: number;
      producerId: number;
      additionalInsuredAddressId?: number;
      code: string;
      name: string;
      recuro_agent_id?: string;
      product_code: string;
      logo_url?: string;
      recuro_group_id: string;
      elixir_group_id: string;
      created_at: string;
      updated_at: string;
      status: string;
      archive: boolean;
      hideFacilityInfo: boolean;
      requires_recuro_subscriber_number: boolean;
      do_not_send_emails_to_member: boolean;
      monthly_price: string;
    }>;
    contacts: Array<{
      id: number;
      email: string;
      fullName?: string;
      role: any;
      copyOnEmails: boolean;
      createdAt: string;
      updatedAt: string;
      producerId: number;
      producer: {
        name: string;
      };
      facilities: Array<{
        id: number;
        additionalInsuredAddress?: {
          id: number;
          contactType: string;
          placeId: any;
          contactFirstName: any;
          contactLastName: any;
          companyName: string;
          address1: string;
          address2: any;
          city: string;
          state: string;
          country: string;
          zip: string;
          timezone: any;
          created_at: string;
          updated_at: string;
        };
      }>;
    }>;
    notes: Array<{
      id: number;
      message: string;
      type: string;
      user?: {
        name: string;
        email: string;
      };
      createdAt: string;
      updatedAt: string;
    }>;
    code: string;
    links: {
      producerReferralLink: string;
    };
  };
  facilityProducerContacts: Array<{
    id: number;
    fullName: any;
    email: string;
  }>;
  contacts: Array<{
    id: number;
    email: string;
    fullName: string;
    role: any;
    copyOnEmails: boolean;
    createdAt: string;
    updatedAt: string;
    facilityId: number;
    facility: {
      name: string;
    };
  }>;
  status: string;
  notes: Array<{
    id: number;
    message: string;
    type: string;
    user: {
      name: string;
      email: string;
    };
    createdAt: string;
    updatedAt: string;
  }>;
  additionalInsuredAddress?: {
    id: number;
    companyName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  links: {
    facilityReferralLink: string;
  };
  invoiceContact?: {
    id: number;
    name: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  createdAt: string;
  updatedAt: string;
}

export interface FacilityForm {
  id?: number;
  status: string;
  code: string;
  name: string;
  monthlyPrice: string;
  productCode: string;
  recuroAgentId: string;
  elixirGroupId: string;
  recuroGroupId: string;
  requiresRecuroSubscriberNumber: boolean;
  doNotSendEmailsToMember: boolean;
  otherInsured: { id?: number } & Models.Address2;
  invoiceContact: { id?: number; name?: string } & Models.Address2;
  logoImageFile: {
    fileName: string;
    content: string;
    size: number;
  };
  logoUrl: string;
  producer:
    | {
        id: number;
        name: string;
        contacts: Array<{
          id: number;
          copyOnEmails: boolean;
          email: string;
          fullName: string;
        }>;
      }
    | string;
  emailProducerContacts: Array<{
    id: number;
    fullName: string;
    email: string;
    copyOnEmails: boolean;
  }>;
  contacts: Array<{
    id?: number;
    fullName: string;
    role: string;
    copyOnEmails: boolean;
    email: string;
  }>;
}

export interface SaveFacilityPayload {
  id?: number;
  name: string;
  status: string;
  code: string;
  productCode: string;
  recuroAgentId: any;
  hideFacilityInfo: boolean;
  otherInsured: {
    id?: number;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  invoiceContact: {
    id?: number;
    name: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  producerId: number;
  emailProducerContacts: Array<{
    producerContactId: number;
    fullName: string;
    email: string;
    copyOnEmails: boolean;
  }>;
  contacts: Array<{
    id?: number;
    fullName: string;
    role: string;
    copyOnEmails: boolean;
    email: string;
  }>;
  logoImageFile?: {
    content: string;
    fileName: string;
    size: number;
  };
  logoUrl?: string;
  elixirGroupId: string;
  recuroGroupId: string;
  requiresRecuroSubscriberNumber: boolean;
  doNotSendEmailsToMember: boolean;
  monthlyPrice: string;
}

export const facilityToForm = (facility: Facility): FacilityForm => ({
  id: facility.id,
  status: facility.status,
  code: facility.code,
  name: facility.name,
  monthlyPrice: facility.monthlyPrice,
  productCode: facility.productCode,
  recuroAgentId: facility.recuroAgentId || "",
  elixirGroupId: facility.elixirGroupId,
  recuroGroupId: facility.recuroGroupId,
  requiresRecuroSubscriberNumber: facility.requiresRecuroSubscriberNumber,
  doNotSendEmailsToMember: facility.doNotSendEmailsToMember,
  otherInsured: (() => {
    if (facility.additionalInsuredAddress) {
      const { address1, ...address } = facility.additionalInsuredAddress;
      return { address: address1, ...address };
    }
    return emptyFacilityForm.otherInsured;
  })(),
  invoiceContact: (() => {
    const { invoiceContact } = facility;

    if (invoiceContact) {
      const { address1, ...address } = invoiceContact;
      return { address: address1, ...address };
    } else {
      return emptyFacilityForm.invoiceContact;
    }
  })(),
  logoImageFile: null,
  logoUrl: facility.logoUrl,
  producer: {
    id: facility.producer.id,
    name: facility.producer.name,
    contacts: facility.producer.contacts.map(
      ({ id, fullName, email, copyOnEmails }) => ({
        id,
        email,
        fullName,
        copyOnEmails,
      })
    ),
  },
  emailProducerContacts: facility.producer.contacts.map(
    ({ id, fullName, email }) => ({
      id,
      fullName,
      email,
      copyOnEmails: (() => {
        const result = facility.facilityProducerContacts.some(
          ({ id: contactId }) => contactId === id
        );
        return result;
      })(),
    })
  ),
  contacts: facility.contacts.map(
    ({ id, fullName, role, copyOnEmails, email }) => ({
      id,
      fullName: fullName || "",
      role: role || "",
      copyOnEmails,
      email,
    })
  ),
});

export const facilityFormToSavePayload = (
  formValues: FacilityForm
): SaveFacilityPayload => ({
  id: formValues.id,
  name: formValues.name,
  status: formValues.status,
  code: formValues.code,
  productCode: formValues.productCode,
  recuroAgentId: formValues.recuroAgentId,
  hideFacilityInfo: false,
  otherInsured: {
    id: formValues.otherInsured.id,
    address1: formValues.otherInsured.address,
    address2: formValues.otherInsured.address2,
    city: formValues.otherInsured.city,
    state: formValues.otherInsured.state,
    zip: formValues.otherInsured.zip,
    country: "US",
  },
  invoiceContact: {
    id: formValues.invoiceContact.id,
    name: formValues.invoiceContact.name,
    address1: formValues.invoiceContact.address,
    address2: formValues.invoiceContact.address2,
    city: formValues.invoiceContact.city,
    state: formValues.invoiceContact.state,
    zip: formValues.invoiceContact.zip,
    country: "US",
  },
  producerId: (formValues.producer as { id: number }).id,
  emailProducerContacts: formValues.emailProducerContacts
    .filter(({ copyOnEmails }) => copyOnEmails)
    .map(({ id: producerContactId, ...contact }) => ({
      producerContactId,
      ...contact,
    })),
  contacts: formValues.contacts,
  logoImageFile: formValues.logoImageFile || undefined,
  logoUrl: formValues.logoUrl ? "notChanged" : null,
  elixirGroupId: formValues.elixirGroupId,
  recuroGroupId: formValues.recuroGroupId,
  requiresRecuroSubscriberNumber: formValues.requiresRecuroSubscriberNumber,
  doNotSendEmailsToMember: formValues.doNotSendEmailsToMember,
  monthlyPrice: formValues.monthlyPrice,
});

export const emptyFacilityForm: FacilityForm = {
  status: "active",
  code: "",
  name: "",
  monthlyPrice: "",
  productCode: "",
  recuroAgentId: "",
  elixirGroupId: DEFAULT_ELIXIR_GROUP_ID,
  recuroGroupId: "",
  requiresRecuroSubscriberNumber: false,
  doNotSendEmailsToMember: false,
  otherInsured: Configs.emptyAddress2,
  invoiceContact: { name: "", ...Configs.emptyAddress2 },
  logoImageFile: null,
  logoUrl: "",
  producer: "",
  emailProducerContacts: [],
  contacts: [],
};
