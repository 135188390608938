import { useReactable } from "@reactables/react";
import { useHistory } from "react-router-dom";
import { RxToggle } from "@jauntin/reactables";
import { Button } from "@jauntin/react-ui";
import ModalDiscardAddNew from "Features/Shared/Components/ModalDiscardAddNew";
import ModalUpdateError from "Features/Shared/Components/ModalUpdateError";
import { PromoCodeProp } from "Features/PromoCodes/Rx/RxPromoCode";
import ModalConfirmation from "@basicare/common/src/Components/ModalConfirmation";
import { PROMO_CODE_PAGE, getUrl } from "Helpers/URLParser";
import { getPromoCodePayload } from "Features/PromoCodes/Rx/Selectors/promoCode.selectors";
import PromoCodeForm from "./PromoCodeForm";

const EditPromoCode = ({ rxPromoCode }: { rxPromoCode: PromoCodeProp }) => {
  const history = useHistory();

  const [state, actions] = rxPromoCode;

  const {
    entity: { data: promoCodeDetails },
  } = state;

  const [showConfirmModal, confirmModalToggle] = useReactable(RxToggle);
  const [showDiscardModal, discardModalToggle] = useReactable(RxToggle);

  return (
    <PromoCodeForm promoCodeDetails={promoCodeDetails}>
      {({
        formState: {
          root: { valid, dirty, value },
        },
        formFields,
      }) => (
        <>
          <div className="content__header content__header--autoWidth col-auto">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="m-0 font-weight-bold">Edit Promo Code</h4>
              <div className="d-flex float-right">
                <Button
                  text="Discard Changes"
                  className="btn btn-outline-secondary px-4 mx-2"
                  onClick={discardModalToggle.toggleOn}
                />
                <Button
                  className="btn btn-primary px-4 mx-2 text-nowrap"
                  onClick={confirmModalToggle.toggleOn}
                  text="Save Promo Code"
                  disabled={!valid || !dirty}
                />
              </div>
            </div>
          </div>
          {formFields}
          {showConfirmModal && (
            <ModalConfirmation
              message={`Update ${promoCodeDetails.code} ?`}
              cancelBtnText="Back to Edit"
              checkboxLabelText="Yes, I confirm that I would like to update this promo code."
              show={showConfirmModal}
              handleClose={confirmModalToggle.toggleOff}
              action={() =>
                actions.editPromoCode.send(getPromoCodePayload(value))
              }
              isUpdating={state.editPromoCode.loading}
            />
          )}

          <ModalDiscardAddNew
            show={showDiscardModal}
            handleClose={discardModalToggle.toggleOff}
            confirmAction={() => {
              history.push(getUrl(PROMO_CODE_PAGE, promoCodeDetails.id));
            }}
          />

          <ModalUpdateError
            show={Boolean(state.editPromoCode.error)}
            text="There was an error updating the promo code. Please try again"
            handleCloseError={actions.editPromoCode.resetState}
          />
        </>
      )}
    </PromoCodeForm>
  );
};

export default EditPromoCode;
