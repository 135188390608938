import { useReactable } from "@reactables/react";
import StyledCheckboxField from "@basicare/common/src/Components/FormElements/CheckboxField/StyledCheckboxField";
import { Form, Field, FormArray, ControlModels } from "@reactables/react-forms";
import { Card, Row, Col, Modal } from "react-bootstrap";
import { ofTypes } from "@reactables/core";
import { RxMemberContactsForm } from "Features/Members/Rx/RxMemberContactsForm";
import { RxMemberProp } from "Features/Members/Rx/RxMember";
import { AsyncButton, Button, TextInput } from "@jauntin/react-ui";
import {
  CustomContact,
  emptyCustomContact,
} from "Features/Members/Rx/Models/memberDetails.model";
import { customContact } from "Features/Members/Rx/Configs/memberContacts.config";
import CustomContactEmailField from "./CustomContactEmailField";
import { getIsAllEmailsSelected } from "Features/Members/Rx/Selectors/memberContactsForm.selector";
import LoadingSpinner from "Features/Shared/Components/LoadingSpinner";

const MemberContacts = ({ rxMember }: { rxMember: RxMemberProp }) => {
  const [
    {
      entity: { data: memberDetails },
      memberEmails,
    },
    rxMemberActions,
    ,
    rxMemberMessages$,
  ] = rxMember;

  const [state, actions] = useReactable(RxMemberContactsForm, {
    memberDetails,
    sources: [
      rxMemberMessages$.pipe(ofTypes(["Member - memberEmails - sendSuccess"])),
    ],
  });

  if (!state) return;

  return (
    <>
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Contacts</h4>
          <AsyncButton
            spinning={memberEmails.loading}
            disabled={!state.root.valid}
            className="btn btn-primary ml-auto border-radius-0"
            onClick={() =>
              rxMemberActions.memberEmails.send({
                form: state,
                memberDetails,
              })
            }
          >
            Resend Welcome Email
          </AsyncButton>
        </div>
      </div>
      <div className="content__body">
        <Form rxForm={[state, actions]}>
          <Row className="mb-4">
            {getIsAllEmailsSelected(state) ? (
              <Button
                text="Deselect All Recipients"
                className="btn btn-outline-primary small"
                onClick={actions.deselectAllEmails}
              ></Button>
            ) : (
              <Button
                text="Select All Recipients"
                className="btn btn-outline-primary small"
                onClick={actions.selectAllEmails}
              ></Button>
            )}
          </Row>
          <Row>
            <Field
              name="emailPrimaryMember"
              component={StyledCheckboxField}
              className="mt-2 mr-2"
            />
            <Col>
              <Card className="mb-4">
                <Card.Header className="bg-white font-weight-bold">
                  Primary Member
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      {memberDetails.firstName} {memberDetails.lastName}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>{memberDetails.email}</Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <FormArray name="emailOrganizationProducerContacts">
            {({ items }) => (
              <>
                {items.map(({ key }, index) => {
                  const { fullName, email } =
                    memberDetails.organization.facilityProducerContacts[index];
                  return (
                    <Row key={key}>
                      <Field
                        name={`emailOrganizationProducerContacts.${index}`}
                        component={StyledCheckboxField}
                        className="mt-2 mr-2"
                      />
                      <Col>
                        <Card className="mb-4">
                          <Card.Header className="bg-white font-weight-bold">
                            Broker Contact #{index + 1}
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col md={6}>{fullName}</Col>
                            </Row>
                            <Row>
                              <Col md={6}>{email}</Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
          </FormArray>
          <FormArray name="emailOrganizationContacts">
            {({ items }) => (
              <>
                {items.map(({ key }, index) => {
                  const { fullName, email, role } =
                    memberDetails.organization.contacts.filter(
                      ({ copyOnEmails }) => copyOnEmails
                    )[index];

                  return (
                    <Row key={key}>
                      <Field
                        name={`emailOrganizationContacts.${index}`}
                        component={StyledCheckboxField}
                        className="mt-2 mr-2"
                      />
                      <Col>
                        <Card className="mb-4">
                          <Card.Header className="bg-white font-weight-bold">
                            Organization Contact #{index + 1}
                          </Card.Header>
                          <Card.Body>
                            {fullName && (
                              <Row>
                                <Col md={6}>{fullName}</Col>
                              </Row>
                            )}
                            {role && (
                              <Row>
                                <Col md={6}>{role}</Col>
                              </Row>
                            )}
                            <Row>
                              <Col md={6}>{email}</Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
          </FormArray>
          <FormArray name="customContacts">
            {({ items, pushControl, removeControl }) => (
              <>
                {items.map(
                  (
                    {
                      key,
                    }: ControlModels.FormControl<{
                      sendEmail: boolean;
                      contactInfo: CustomContact;
                    }>,
                    index
                  ) => {
                    return (
                      <Row key={key}>
                        <Field
                          name={`customContacts.${index}.sendEmail`}
                          component={StyledCheckboxField}
                          className="mt-2 mr-2"
                        />
                        <Col>
                          <Card className="mb-4">
                            <Card.Header className="bg-white font-weight-bold d-flex justify-content-between">
                              Custom Contact #{index + 1}
                              <button
                                className="btn-link--black btn-link--delete btn btn-link"
                                onClick={() => removeControl(index)}
                              >
                                Delete
                              </button>
                            </Card.Header>
                            <Card.Body>
                              <Row>
                                <Col md={6}>
                                  <Field
                                    name={`customContacts.${index}.contactInfo.fullName`}
                                    label="Full name"
                                    inputClassName="form-control-lg"
                                    component={TextInput}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <Field
                                    name={`customContacts.${index}.contactInfo.email`}
                                    label="Email"
                                    inputClassName="form-control-lg"
                                    component={CustomContactEmailField}
                                    memberDetails={memberDetails}
                                    index={index}
                                    emailControl={
                                      state[
                                        `customContacts.${index}.contactInfo.email`
                                      ]
                                    }
                                    contactsFormGroup={state.root}
                                  />
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    );
                  }
                )}
                {items.length < 2 && (
                  <Row>
                    <div className="mr-5"></div>
                    <Col>
                      <Button
                        text="Add Custom Contact"
                        className="btn btn-outline-secondary w-100 mt-4"
                        onClick={() =>
                          pushControl(customContact(emptyCustomContact))
                        }
                      ></Button>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </FormArray>
        </Form>
      </div>

      {memberEmails.loading && (
        <div className="disable-overlay">
          <div className="disable-overlay__inner">
            <LoadingSpinner />
            <div className="h6 mt-2">Sending...</div>
          </div>
        </div>
      )}
      <Modal
        show={memberEmails.success || Boolean(memberEmails.error)}
        size="lg"
      >
        <Modal.Header
          className="w-100 border-0 pb-0"
          closeButton
          onHide={rxMemberActions.memberEmails.resetState}
        />
        <Modal.Body className="text-center px-5">
          <p>
            {memberEmails.success
              ? "Membership documents successfully sent."
              : "There was an error sending membership documents. Please try again."}
          </p>
          <br />
        </Modal.Body>
        <Modal.Footer className="border-0 pb-4 mb-3">
          <Button
            className="btn btn-primary px-5"
            onClick={rxMemberActions.memberEmails.resetState}
            text="OK"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MemberContacts;
