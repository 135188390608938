import axios from "axios";
import param from "@basicare/common/src/Helpers/param";
import { apiUrl } from "../constants";

/**
 * Base abstraction for making an axios call to our servers
 * Provides some defaults such as headers and API base url.
 *
 * It is recommended to use this class with a 'Service' class that represents a set of calls
 * e.g. PriceService.getQuote - has an internal reference to an API object and prepares a call
 *
 * Calls return axios objects, so they can be used the same as axios.
 */
class API {
  static base = apiUrl;

  static defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  _instance = null;

  constructor(credentials = true, headers = {}) {
    this._instance = axios.create({
      withCredentials: credentials,
      baseURL: API.base,
      headers: {
        ...API.defaultHeaders,
        ...headers,
      },
    });
  }

  get = ({ location, params }) => {
    return this._instance.get(location, {
      params,
      paramsSerializer: param,
    });
  };

  delete = ({ location, params }) => {
    return this._instance.delete(location, { params });
  };

  getDownload = ({ location, config }) => {
    return this._instance.get(location, config);
  };

  post = ({ location, body, config }) => {
    return this._instance.post(location, body, config);
  };

  put = ({ location, body, config }) => {
    return this._instance.put(location, body, config);
  };

  patch = ({ location, body, config }) => {
    return this._instance.patch(location, body, config);
  };
}

export default API;
