import {
  PERMISSION_MANAGE_SETTINGS,
  PERMISSION_MANAGE_USERS,
  PERMISSION_VIEW_REPORTS,
  PERMISSION_TEST_FEATURES,
} from "../../constants";

export const canManageUsers = (state) =>
  state.app.currentUser?.permissions.includes(PERMISSION_MANAGE_USERS);

export const canManageSettings = (state) =>
  state.app.currentUser?.permissions.includes(PERMISSION_MANAGE_SETTINGS);

export const canViewReports = (state) =>
  state.app.currentUser?.permissions.includes(PERMISSION_VIEW_REPORTS);

export const canTestFeatures = (state) =>
  state.app.currentUser?.permissions.includes(PERMISSION_TEST_FEATURES);
