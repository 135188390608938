import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { Button, Card } from "react-bootstrap";
import { validators, normalizers } from "@jauntin/utilities";
import { MAX_PRODUCER_CONTACTS } from "../../../../constants";
import { contactEmailsUniqueMessage } from "../../../../Helpers/validators";
import EmailField from "@basicare/common/src/Components/FormElements/EmailField";
import TextField from "@basicare/common/src/Components/FormElements/TextField";
import { CheckboxInput } from "@jauntin/react-ui";
const { email, required } = validators;
const { normalizeEmail } = normalizers;

const ContactFields = ({ fields }) => (
  <>
    {fields.map((contact, i) => {
      return (
        <Card
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className="w-100 mt-4"
        >
          <Card.Header className="bg-transparent d-flex justify-content-between font-weight-bold">
            <div>{`Broker Contact #${i + 1}`}</div>
            <Button
              variant="link"
              className="btn-link--black btn-link--delete"
              onClick={() => fields.remove(i)}
            >
              Delete
            </Button>
          </Card.Header>
          <Card.Body>
            <Field
              component={TextField}
              name={`${contact}.fullName`}
              label="Full name"
              ariaLabel="Full name"
              inputClassName="form-control-lg col-lg-6 mb-4"
            />
            <Field
              component={EmailField}
              validate={[required, email]}
              normalize={normalizeEmail}
              name={`${contact}.email`}
              errorClassName="mb-2"
              inputClassName="form-control-lg mb-4"
              showErrors={(meta) =>
                (meta.error && meta.touched) ||
                meta.error === contactEmailsUniqueMessage
              }
            />
            <Field
              component={CheckboxInput}
              name={`${contact}.copyOnEmails`}
              type="checkbox"
              className="d-flex align-items-center"
              ariaLabel="Copy on emails checkbox"
              labelClassName="pl-2 contacts__copyOnEmail"
              label="Copy on emails containing member information."
            />
          </Card.Body>
        </Card>
      );
    })}
    <div className="mt-4">
      {fields.length < MAX_PRODUCER_CONTACTS && (
        <Button
          variant="outline-secondary"
          className="px-4 mt-4"
          onClick={() =>
            fields.push({
              fullName: "",
              email: "",
              copyOnEmails: false,
            })
          }
          block
        >
          Add contact
        </Button>
      )}
    </div>
  </>
);

ContactFields.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    length: PropTypes.number,
    push: PropTypes.func,
    remove: PropTypes.func,
  }).isRequired,
};

export default ContactFields;
