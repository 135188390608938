import React, { useState } from "react";
import { initialize, reset } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Button } from "@jauntin/react-ui";
import { dateHelpers } from "@jauntin/utilities";
import PopoverTrigger from "../../Shared/Components/SearchEllipsisPopover";
import {
  getUrl,
  FACILITY_PAGE,
  EDIT_FACILITY_PAGE,
} from "../../../Helpers/URLParser";
import {
  editFacilityInitialFormValues,
  facilityPropType,
} from "../../../Helpers/FacilityModel";
import {
  setEditingAction,
  setSelectedProducerInResults,
} from "../../../Redux/Actions/actions";
import {
  facilityFormName,
  modalUpdateServerErrorMessage,
  DEFAULT_FACILITY_CODE,
} from "../../../constants";
import ModalUpdateError from "../../Shared/Components/ModalUpdateError";

const PopoverContent = ({ facility, editReset, setEditing }) => (
  <>
    <NavLink
      className="btn popover-item__btn"
      to={getUrl(FACILITY_PAGE, facility.id)}
      onClick={() => {
        editReset(facility);
        setEditing(true);
      }}
    >
      <i className="fal fa-edit mr-2 col-1 p-0" />
      <span className="col-auto p-0">Edit organization details</span>
    </NavLink>
  </>
);

PopoverContent.propTypes = {
  facility: facilityPropType.isRequired,
  editReset: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
};

const SearchResults = ({ facilitiesList, loadFacility }) => {
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);

  return (
    <>
      <table className="table subsection-table mt-4">
        <thead>
          <tr>
            <th scope="col" width="264">
              Organization Name
            </th>
            <th scope="col" width="123">
              Code
            </th>
            <th scope="col">Broker</th>
            <th scope="col" width="161">
              Date Modified
            </th>
            <th scope="col" className="px-0" />
          </tr>
        </thead>
        <tbody>
          {facilitiesList.map((facility) => (
            <tr key={`row${facility.id}`}>
              <td>
                <Button
                  className="btn btn-link btn--edit text-left text-primary"
                  text={facility.name}
                  onClick={() => loadFacility(facility.id)}
                />
              </td>
              <td>{facility.code}</td>
              <td>
                {facility.producerName}
                <br />
                {facility.producerCommissionId}
                {facility.subProducerCode ? `-${facility.subProducerCode}` : ""}
              </td>
              <td>{dateHelpers.slashSeparated(facility.updated)}</td>
              <td className="d-flex">
                <PopoverTrigger
                  contentClass="popover-content__fit-content"
                  icon="fa-ellipsis-v"
                  content={
                    <>
                      <Link to={getUrl(FACILITY_PAGE, facility.id)}>
                        <button
                          className="btn d-block popover-item__btn"
                          onClick={() => {}}
                        >
                          <i className="fa fa-eye" /> View
                        </button>
                      </Link>
                      {facility.code !== DEFAULT_FACILITY_CODE && (
                        <Link to={getUrl(EDIT_FACILITY_PAGE, facility.id)}>
                          <button
                            className="btn d-block popover-item__btn"
                            onClick={() => {}}
                          >
                            <i className="fa fa-edit" /> Edit
                          </button>
                        </Link>
                      )}
                    </>
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />
    </>
  );
};

SearchResults.propTypes = {
  facilitiesList: PropTypes.any.isRequired,
  loadFacility: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  editReset: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  editReset: (facility) => {
    const otherInsured = facility.otherAdditionalInsured;
    dispatch(reset(facilityFormName));
    dispatch(
      initialize(
        facilityFormName,
        editFacilityInitialFormValues(facility, otherInsured)
      )
    );
    dispatch(
      setSelectedProducerInResults({
        value: facility.producerId,
        label: `${facility.producerName} — ${facility.producerCommissionId}`,
      })
    );
  },
  setEditing: (value) => dispatch(setEditingAction(value)),
});

export default compose(connect(null, mapDispatchToProps))(SearchResults);
