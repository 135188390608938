import { Modal } from "react-bootstrap";
import { Button } from "@jauntin/react-ui";
import { FormBuilders } from "@jauntin/reactables";
import { control, group } from "@reactables/forms";
import { Form, Field } from "@reactables/react-forms";
import { useReactable } from "@reactables/react";
import formProviders from "../Helpers/formProviders";
import StyledCheckboxField from "./FormElements/CheckboxField/StyledCheckboxField";
import AsyncButton from "./AsyncButton";

const RxConfirmForm = (requireCheckbox: boolean) =>
  FormBuilders.build(
    group({
      controls: {
        confirmMemberUpdate: control([
          false,
          requireCheckbox ? ["required"] : [],
        ]),
      },
    }),
    {
      name: "rxConfirmForm",
      providers: formProviders,
    }
  );

const ModalConfirmation = ({
  show,
  action,
  handleClose,
  message = "Are you sure you want to proceed?",
  checkboxLabelText = "Yes, Proceed.",
  cancelBtnText = "Cancel",
  confirmBtnText = "Update",
  cancelBtnClassName = "btn btn-outline-secondary",
  confirmBtnClassName = "btn btn-primary px-4 mx-2 text-nowrap border-radius-0",
  isUpdating = false,
  requireCheckbox = true,
}: {
  show: boolean;
  handleClose: () => void;
  action: (payload?: unknown) => void;
  message?: string | React.ReactNode;
  checkboxLabelText?: string;
  cancelBtnText?: string;
  confirmBtnText?: string;
  isUpdating?: boolean;
  requireCheckbox?;
  cancelBtnClassName?: string;
  confirmBtnClassName?: string;
}) => {
  const rxConfirmForm = useReactable(RxConfirmForm, requireCheckbox);
  const [state, actions] = rxConfirmForm;

  if (!state) return;

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header className="w-100 border-0 pb-0" closeButton />
      <Modal.Body className="text-center px-4">
        <p className="mb-4">{message}</p>
        {requireCheckbox && (
          <Form rxForm={[state, actions]}>
            <Field
              name="confirmMemberUpdate"
              component={StyledCheckboxField}
              labelClassName="font-weight-bold pl-2"
              label={checkboxLabelText}
            />
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer className="border-0 pb-4">
        <Button
          className={cancelBtnClassName}
          text={cancelBtnText}
          onClick={handleClose}
        />
        <AsyncButton
          className={confirmBtnClassName}
          spinning={isUpdating}
          onClick={action}
          disabled={!state.confirmMemberUpdate.valid}
        >
          {confirmBtnText}
        </AsyncButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirmation;
