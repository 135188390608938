import React from "react";
import AsyncButton from "./AsyncButton";
import { scrollToError } from "@jauntin/utilities";

const TwoStageButton = ({
  className = "",
  text = "Submit",
  actionEnabled,
  touchSubmit,
  action,
  spinning = false,
  disableAction = false,
}: {
  actionEnabled: boolean;
  action: () => void;
  touchSubmit: () => void;
  text?: string | React.ReactElement;
  disableAction?: boolean;
  spinning?: boolean;
  className?: string;
}) => (
  <AsyncButton
    spinning={spinning}
    className={className}
    onClick={() => {
      if (actionEnabled) {
        action();
      } else {
        touchSubmit();
        scrollToError();
      }
    }}
    disabled={actionEnabled && disableAction}
  >
    {text}
  </AsyncButton>
);

export default TwoStageButton;
