import React from "react";
import { connect } from "react-redux";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  getUrl,
  MEMBERS_PAGE,
  PROMO_CODES_PAGE,
  FACILITIES_PAGE,
  PRODUCERS_PAGE,
  // ASSOCIATIONS_PAGE,
  // LOGIN_PAGE,
  // BLOCKED_VENUES_PAGE,
  // VENUES_SEARCH_PAGE,
  // CONTACTS_PAGE,
  MAILCATCHER_PAGE,
  USERS_PAGE,
  REPORTS_PAGE,
  SETTINGS_PAGE,
} from "../../../Helpers/URLParser";
import Logo from "@basicare/common/src/Assets/Images/basicare.1200.transparent.png";
import { Button } from "@jauntin/react-ui";
import { logout as logoutAction } from "../../../Redux/Actions/actions";
import {
  canManageSettings,
  canManageUsers,
  canViewReports,
  canTestFeatures,
} from "../../../Redux/Selectors/Users";

const MainMenu = ({ logout, canManageUsers }) => {
  return (
    <SidebarMenu>
      <SidebarMenu.Collapse>
        <SidebarMenu.Header>
          <SidebarMenu.Nav>
            <NavLink
              to={getUrl(MEMBERS_PAGE)}
              eventkey="members"
              activeClassName="active"
              title="Members"
            >
              <img className="main-menu__logo" src={Logo} alt="Logo" />
            </NavLink>
          </SidebarMenu.Nav>
          <SidebarMenu.Toggle />
        </SidebarMenu.Header>
        <div className="menu-wrapper">
          <SidebarMenu.Body className={"mt-4"}>
            <SidebarMenu.Nav>
              <NavLink
                to={getUrl(MEMBERS_PAGE)}
                eventkey="members"
                activeClassName="active"
                title="Members"
              >
                <i className="fa fa-file" aria-hidden="true"></i>
                <span>Members</span>
                <div className="menu-container__item-underline" />
              </NavLink>
            </SidebarMenu.Nav>
          </SidebarMenu.Body>
          <SidebarMenu.Body>
            <SidebarMenu.Nav>
              <NavLink
                to={getUrl(PRODUCERS_PAGE)}
                eventkey="producers"
                activeClassName="active"
                title="Brokers"
              >
                <i className="fa fa-male" aria-hidden="true"></i>
                <span>Brokers</span>
                <div className="menu-container__item-underline" />
              </NavLink>
            </SidebarMenu.Nav>
            <SidebarMenu.Nav>
              <NavLink
                to={getUrl(FACILITIES_PAGE)}
                eventkey="organizations"
                activeClassName="active"
                title="Organizations"
              >
                <i className="fa fa-users" aria-hidden="true"></i>
                <span>Organizations</span>
                <div className="menu-container__item-underline" />
              </NavLink>
            </SidebarMenu.Nav>
            <SidebarMenu.Nav>
              <NavLink
                to={getUrl(PROMO_CODES_PAGE)}
                eventkey="promoCodes"
                activeClassName="active"
                title="Promo Codes"
              >
                <span>Promo Codes</span>
                <div className="menu-container__item-underline" />
              </NavLink>
            </SidebarMenu.Nav>
            {/* <SidebarMenu.Nav>
              <NavLink
                to={getUrl(ASSOCIATIONS_PAGE)}
                eventkey="associations"
                activeClassName="active"
                title="Associations"
              >
                <i className="fa fa-cloud" aria-hidden="true"></i>
                <span>Associations</span>
                <div className="menu-container__item-underline" />
              </NavLink>
            </SidebarMenu.Nav>
            <SidebarMenu.Nav>
              <NavLink
                to={getUrl(VENUES_SEARCH_PAGE)}
                eventkey="venues"
                activeClassName="active"
                title="Venues"
              >
                <i className="fa fa-map-marker" aria-hidden="true"></i>
                <span>Venues</span>
                <div className="menu-container__item-underline" />
              </NavLink>
            </SidebarMenu.Nav>
            <SidebarMenu.Nav>
              <NavLink
                to={getUrl(BLOCKED_VENUES_PAGE)}
                eventkey="blocked_venues"
                activeClassName="active"
                title="Blocked Venues"
              >
                <i className="fa fa-lock" aria-hidden="true"></i>
                <span>Blocked Venues</span>
                <div className="menu-container__item-underline" />
              </NavLink>
            </SidebarMenu.Nav> */}
          </SidebarMenu.Body>

          <SidebarMenu.Body>
            {/* <SidebarMenu.Nav>
              <NavLink
                to={getUrl(CONTACTS_PAGE)}
                eventkey="contacts"
                activeClassName="active"
                title="Contacts"
              >
                <i className="fa fa-address-book" aria-hidden="true"></i>
                <span>Contacts</span>
                <div className="menu-container__item-underline" />
              </NavLink>
            </SidebarMenu.Nav> */}
            {canManageUsers && (
              <SidebarMenu.Nav>
                <NavLink
                  to={getUrl(USERS_PAGE)}
                  eventkey="users"
                  activeClassName="active"
                  title="Admin Users"
                >
                  <i className="fa fa-user" aria-hidden="true"></i>
                  <span>Admin Users</span>
                  <div className="menu-container__item-underline" />
                </NavLink>
              </SidebarMenu.Nav>
            )}
          </SidebarMenu.Body>

          {process.env.REACT_APP_MAILCATCHER_LOCATION && (
            <SidebarMenu.Body>
              <SidebarMenu.Nav>
                <NavLink
                  to={getUrl(MAILCATCHER_PAGE)}
                  eventkey="mailcatcher"
                  activeClassName="active"
                  title="MailCatcher"
                >
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <span>MailCatcher</span>
                  <div className="menu-container__item-underline" />
                </NavLink>
              </SidebarMenu.Nav>
            </SidebarMenu.Body>
          )}

          <SidebarMenu.Body>
            <SidebarMenu.Nav>
              {canViewReports && (
                <NavLink
                  to={getUrl(REPORTS_PAGE)}
                  eventkey="reports"
                  activeClassName="active"
                  title="Reports"
                >
                  <i className="fa fa-file-upload" aria-hidden="true"></i>
                  <span>Reports</span>
                  <div className="menu-container__item-underline" />
                </NavLink>
              )}
              {process.env.REACT_APP_ENV !== "production" &&
                canManageSettings && (
                  <NavLink
                    to={getUrl(SETTINGS_PAGE)}
                    eventkey="settings"
                    activeClassName="active"
                    title="Settings"
                  >
                    <i className="fa fa-cog" aria-hidden="true"></i>
                    <span>Settings</span>
                    <div className="menu-container__item-underline" />
                  </NavLink>
                )}
            </SidebarMenu.Nav>
          </SidebarMenu.Body>

          <Button
            className="btn btn-primary rounded-0 w-100"
            onClick={logout}
            text="Logout"
          />
        </div>
      </SidebarMenu.Collapse>
    </SidebarMenu>
  );
};

MainMenu.propTypes = {
  logout: PropTypes.func.isRequired,
  canManageUsers: PropTypes.bool.isRequired,
  canManageSettings: PropTypes.bool.isRequired,
  canViewReports: PropTypes.bool.isRequired,
  canTestFeatures: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  canManageUsers: canManageUsers(state),
  canManageSettings: canManageSettings(state),
  canViewReports: canViewReports(state),
  canTestFeatures: canTestFeatures(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutAction()),
});

const MainMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainMenu);

export default MainMenuContainer;
