import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone-uploader";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import download from "downloadjs";
import { Button } from "react-bootstrap";
import API from "../../../../Services/API";
import "react-dropzone-uploader/dist/styles.css";
import DropzoneInput from "./DropzoneInput";
import DropzonePreview from "./DropzonePreview";
import MembershipService from "../../../../Services/MembershipService";
import { arrayToCsvAsync } from "../../../../Helpers/CsvHelper";
import { isEmpty } from "lodash";

const membershipService = new MembershipService(new API());

const DropzoneUploader = ({
  setSuccessMessage,
  setShowSuccess,
  setErrorMessage,
  setShowError,
  setWarningMessage,
  setShowWarning,
  clearAllAlerts,
  facilityId,
  submitting,
  setSubmitting,
}) => {
  const getUploadParams = () => {
    return {
      url: new URL(`${process.env.REACT_APP_API_LOCATION}dump`),
    };
  };

  const handleSubmit = async (files) => {
    try {
      setSubmitting(true);
      const response = await membershipService.postImportMemberships(
        facilityId,
        files[0].file
      );
      const responseData = response.data;
      if (responseData) {
        const successfulRowsCount = responseData.completed;
        if (successfulRowsCount > 0) {
          setSuccessMessage(
            `<strong>${responseData.completed} out of ${responseData.total}</strong> rows were successfully uploaded.`
          );
          setShowSuccess(true);
        }

        const failedRowsCount = responseData.incomplete_rows.length;
        if (failedRowsCount > 0) {
          const failedRows = responseData.incomplete_rows;
          let errors = [];
          failedRows.forEach((row) => {
            if (!isEmpty(row.errors)) {
              row.errors.forEach((error) => errors.push(error));
            }
          });
          let warningMessage = "";
          if (errors.length < 11) {
            // Comma separate, with 'and' at the end
            warningMessage = failedRows.reduce(
              (accumulator, current) =>
                `${accumulator}${current.errors.join("<br>")}<br>`,
              ""
            );
          } else {
            const failedCsv = await arrayToCsvAsync(
              errors.map((error, i) => ({ Line: ++i, Error: error }))
            );

            const filename = `${files[0].file.name.substring(
              0,
              files[0].file.name.lastIndexOf(".")
            )} - Errors.csv`;

            warningMessage = (
              <>
                {`A number of members were unable to be saved. `}
                <Button
                  variant="text"
                  className="alert-warning__link"
                  onClick={() => {
                    download(failedCsv, filename, "text/csv");
                  }}
                >
                  Download the list of errors
                </Button>
                {` to review the format of rows before uploading again.`}
              </>
            );
          }
          setWarningMessage(warningMessage);
          setShowWarning(true);
        }
        setSubmitting(false);
      }
    } catch (e) {
      setErrorMessage("Invalid XLSX file format..");
      setShowError(true);
      setSubmitting(false);
    }
  };

  const handleChangeStatus = ({ meta }, status, files) => {
    if (status === "rejected_file_type" || status === "error_upload") {
      setErrorMessage("Invalid XLSX file format.");
      setShowError(true);
    } else if (status === "done") {
      setTimeout(() => {
        handleSubmit(files, meta);
      }, 1000);
    }
  };

  const getFilesFromEvent = (e) => {
    setShowSuccess(false);
    setShowError(false);
    setShowWarning(false);
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      accept=".xlsx, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      maxFiles={1}
      multiple={false}
      PreviewComponent={(props) => (
        <DropzonePreview
          {...props}
          submitting={submitting}
          clearAllAlerts={clearAllAlerts}
        />
      )}
      InputComponent={DropzoneInput}
      getFilesFromEvent={getFilesFromEvent}
      classNames={{
        dropzone: "dropzone__wrapper",
        inputLabel: "dropzone__input-label",
        preview: "dropzone__preview",
        previewImage: "dropzone__preview-img",
        dropzoneActive: "active",
      }}
    />
  );
};

DropzoneUploader.propTypes = {
  setSuccessMessage: PropTypes.func.isRequired,
  setShowSuccess: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setShowError: PropTypes.func.isRequired,
  setWarningMessage: PropTypes.func.isRequired,
  setShowWarning: PropTypes.func.isRequired,
  clearAllAlerts: PropTypes.func.isRequired,
  facilityId: PropTypes.number.isRequired,
  submitting: PropTypes.bool.isRequired,
  setSubmitting: PropTypes.func.isRequired,
};

export default DropzoneUploader;
