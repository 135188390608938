import { group, control } from "@reactables/forms";
import { Configs } from "@jauntin/reactables";
import {
  PrimaryMember,
  emptyPrimaryMember,
} from "../../Models/primaryMember.model";
import { person } from "./person.config";

export const primaryMember = ({
  firstName,
  lastName,
  dateOfBirth,
  gender,
  phoneNumber,
  email,
  ...address
}: PrimaryMember = emptyPrimaryMember) =>
  group({
    controls: {
      ...person({ firstName, lastName, dateOfBirth, gender }).controls,
      ...{
        ...Configs.address2Group(address).controls,
        zip: control({
          initialValue: address.zip,
          validators: ["required", "zipCode"],
          normalizers: ["normalizeZip"],
        }),
      },
      phoneNumber: control({
        initialValue: phoneNumber,
        validators: ["required", "phoneNumber"],
        normalizers: ["normalizePhone"],
      }),
      email: control({
        initialValue: email,
        validators: ["required", "email"],
        normalizers: ["normalizeEmail"],
      }),
    },
  });
