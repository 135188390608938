import { RelationshipType } from "@basicare/common/src/Constants/dependents";
import { getHasVisibleDependentDobError } from "@basicare/common/src/Rx/Selectors/member.selector";
import { hasControlTouchError } from "@basicare/common/src/Rx/Selectors/hasControlTouchError.selector";

export const getIsSpouseSelected = (state: { form }) =>
  state.form.root.value.dependents.some(
    (dependent) =>
      dependent.relationshipToAccountHolder === RelationshipType.Spouse
  );

export const getShowAddMemberActionBtn = (
  state: { form },
  submitTouched: boolean
) => {
  const hasVisibleRecuroNumRequiredForOrgError =
    state.form["membership"].errors.recuroNumRequiredForOrg &&
    state.form["membership.recuroSubscriberNumber"].touched;

  const visibleTouchError = hasControlTouchError(state.form);

  const hasVisibleDependentDobError = getHasVisibleDependentDobError(state);

  return (
    state.form.root.valid ||
    visibleTouchError ||
    hasVisibleRecuroNumRequiredForOrgError ||
    hasVisibleDependentDobError ||
    submitTouched
  );
};
