import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { loadUsers, resetUsers } from "../../../Redux/Actions/userOperations";
import { adminUserPropType } from "../../../Helpers/UserModel";
import AddNewUserForm from "./AddNewUserForm";
import ResultsTable from "./ResultsTable";
import { Pagination } from "@jauntin/react-ui";
import { paginationProps } from "../../../constants";
import { getUrl, USERS_PAGE } from "../../../Helpers/URLParser";

const AddNewUser = ({ users, onMount, onDismount, pagination, onGoToPage }) => {
  useEffect(() => {
    onMount();

    return () => {
      onDismount();
    };
  }, [onMount, onDismount]);

  return (
    <div className="scroll-part">
      <div className="content__body p-5">
        <div className="d-flex justify-content-between align-items-center mb-5">
          <h1 className="c-gold">Add Admin(s)</h1>
          <div className="float-right">
            <Link className="btn btn-outline-secondary" to={getUrl(USERS_PAGE)}>
              Return to Admin Users home screen
            </Link>
          </div>
        </div>
        <AddNewUserForm />
        <ResultsTable users={users} />
      </div>
      <div className="d-flex justify-content-end px-5 pb-5">
        <Pagination pagination={pagination} goToPage={onGoToPage} />
      </div>
    </div>
  );
};

AddNewUser.propTypes = {
  users: PropTypes.arrayOf(adminUserPropType).isRequired,
  pagination: paginationProps.isRequired,
  onMount: PropTypes.func.isRequired,
  onDismount: PropTypes.func.isRequired,
  onGoToPage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.users.usersTableData,
  pagination: state.users.usersPagination,
});

const mapDispatchToProps = (dispatch) => ({
  onMount: () => {
    dispatch(loadUsers());
  },
  onDismount: () => {
    dispatch(resetUsers());
  },
  onGoToPage: (newPage) => {
    dispatch(loadUsers(newPage));
  },
});

const AddNewUserContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewUser);

export default AddNewUserContainer;
