import styled from "styled-components";

const StyledAppErrorMessage = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  button {
    font-weight: 500;
    color: blue;
  }
`;

export default StyledAppErrorMessage;
