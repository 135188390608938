import { StylesConfig } from "react-select";

const getBorderWidth = (state, hasError) =>
  state.isFocused || hasError ? "1px" : "0";

export const getCustomStyles = (hasError: boolean = false): StylesConfig => ({
  control: (base, state) => ({
    ...base,
    "&:hover": { borderColor: "none" },
    minHeight: 38,
    boxShadow: state.isFocused ? "0 0 0 1px #2c2a5f" : "none",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    borderColor: hasError ? "#d53535" : "#2c2a5f",
    borderWidth: getBorderWidth(state, hasError),
    borderBottomWidth: state.menuIsOpen ? "0" : getBorderWidth(state, hasError),
    borderRadius: "4px",
    borderBottomLeftRadius: state.menuIsOpen ? "0" : "4px",
    borderBottomRightRadius: state.menuIsOpen ? "0" : "4px",
    transition: "0 all",
  }),
  singleValue: (base) => ({
    ...base,
    position: "static",
    transform: "none",
    maxWidth: "100%",
    whiteSpace: "normal",
  }),
  placeholder: (base) => ({
    ...base,
    position: "static",
    transform: "none",
    maxWidth: "100%",
    fontFamily: "Rubik",
    color: "#80838c",
  }),
  dropdownIndicator: (base) => ({ ...base, color: "#2c2a5f" }),
  indicatorSeparator: (base) => ({ ...base, display: "none" }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#231f20" : "#484848",
    display: state.isDisabled ? "none" : "block",
    "&:hover": {
      backgroundColor: "#ecf0f7",
      "&:last-child": {
        borderRadius: "2px",
      },
    },
    backgroundColor:
      (state.isSelected && "#ecf0f7") ||
      (state.isFocused && "#ecf0f7") ||
      provided.backgroundColor,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 50,
    marginTop: "0",
    borderRadius: "4px",
    boxShadow: "0 0 0 1px #2c2a5f",
    border: "1px solid #2c2a5f",
    borderTop: "none",
    borderTopLeftRadius: "0",
    borderTopRightRadius: "0",
    "&::after": {
      content: '""',
      position: "absolute",
      zIndex: "-1",
      boxShadow:
        "0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      zIndex: "-1",
      borderTop: "1px solid hsl(0deg 0% 0% / 10%)",
    },
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: "nowrap",
  }),
});

export const getLightModeSelectStyles = (
  hasError: boolean = false
): StylesConfig => {
  const customStyles = getCustomStyles(hasError);

  return {
    ...customStyles,
    control: (base, state) => ({
      ...(customStyles.control && customStyles.control(base, state)),
      borderWidth: "1px",
      borderColor: hasError
        ? "#d53535"
        : state.menuIsOpen || state.isFocused
        ? "#030158"
        : "#bec1da",
      borderBottomWidth: state.menuIsOpen
        ? "0"
        : state.isFocused
        ? "1px"
        : "1px",
    }),
  };
};

export const getLgSelectStyle = (hasError, inputAppend = false) => {
  const style = getLightModeSelectStyles(hasError);

  return {
    ...style,
    control: (base, state) => ({
      ...style.control(base, state),
      minHeight: 42,
      borderColor: hasError ? "#d53535" : "#e0e3e2",
      ...(() => {
        if (inputAppend)
          return {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          };

        return {};
      })(),
    }),
    placeholder: (base) => base,
  } as StylesConfig;
};
