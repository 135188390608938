import { group, control } from "@reactables/forms";
import { Person, emptyPerson } from "@basicare/common/src/Models/person.model";

export const person = ({
  firstName,
  lastName,
  dateOfBirth,
  gender,
}: Person = emptyPerson) =>
  group({
    controls: {
      firstName: control({
        initialValue: firstName,
        validators: ["required"],
        normalizers: ["maxLength50"],
      }),
      lastName: control({
        initialValue: lastName,
        validators: ["required"],
        normalizers: ["maxLength50"],
      }),
      dateOfBirth: control({
        initialValue: dateOfBirth,
        validators: ["required", "dateOfBirth"],
        normalizers: ["normalizeDate"],
      }),
      gender: control([gender, "required"]),
    },
  });
