import variables from "../../Sass/Abstracts/_variables.scss";

const { cBasicareBlue, cBasicareOrange } = variables;

export const BCX_THEME = {
  themeSlug: "bcx",
  title: "Basicare Plus",
  favicons: [
    { rel: "shortcut icon", href: "/favicon.ico" },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/favicons/apple-touch-icon.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "/favicons/favicon-32x32.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: "/favicons/favicon-16x16.png",
    },
    {
      rel: "mask-icon",
      href: "/favicons/safari-pinned-tab.svg",
      color: "#0070a7",
    },
    { rel: "shortcut icon", href: "/favicons/favicon.ico" },
  ],
  breakpoints: {
    xxs: "0",
    xs: "375px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
  },
  palette: {
    primary: {
      light: "#2c2a5f",
      main: "#2c2a5f",
      dark: "#2c2a5f",
      contrastText: "#fff",
    },
    secondary: {
      light: "#e05800",
      main: "#e05800",
      dark: "#e05800",
      contrastText: "#fff",
    },
    error: {
      light: "#e05800",
      main: "#e05800",
    },
  },
  typography: {
    fonts: {
      primary: "Raleway, sans-serif",
      secondary: "Hind, sans-serif",
    },
    h1: ``,
    h2: `
      color: ${cBasicareBlue};
    `,
    h3: `
      color: ${cBasicareOrange};
    `,
    h4: ``,
    h5: ``,
    h6: ``,
  },
  globalStyles: `
    .summary-table {
      &__item-value {
        color: ${cBasicareOrange};
        font-weight: bold;
      }
    }

    .limits {
      &__limit-info {
        color: #0d3a68;
        font-weight: 500;
      }
    }

    .form-step-nav {
      &__start-over {
        border-color: ${cBasicareBlue};
        color: ${cBasicareBlue};
        &:hover {
          background-color: ${cBasicareBlue};
          border-color: ${cBasicareBlue};
        }
      }
    }

    // Datepicker styles needed here for overlay

    .rdp {
      &-day {

        &_selected:not(.rdp-day_disabled) {
          background-color: transparent !important;
          .rdp-day-content {
            background-color: ${cBasicareBlue} !important;
          }
        }

        &:hover:not(.rdp-day_selected, .rdp-day_disabled),
        &_in-hover-range:not(.rdp-day_selected, .rdp-day_disabled) {
          .rdp-day-content {
            border: 2px solid ${cBasicareBlue} !important;
            background-color: #f5f2ec !important;
            outline-color: ${cBasicareBlue} !important;
          }
        }
      }


      &-button {
        &:focus-visible,
        &:focus {
          background-color: #f5f2ec !important;
          border-color: ${cBasicareBlue} !important;
          outline-color: ${cBasicareBlue} !important;
        }
      }
    }
  `,
  components: {
    Button: {
      customCss: `
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        text-align: center;
        vertical-align: middle;
        padding: 0.5rem 1rem;
        line-height: 1.5;
        border-radius: 4px;

        .button__chevrone-right {
          height: 14px;
        }

        a, a:hover {
          font-weight: 500;
        }

        &:disabled {
          opacity: 0.3;
        }`,
    },
    ButtonToggle: {
      backgroundColor: "#fff",
      backgroundColorActive: "#fcf9f4",
      borderColor: cBasicareBlue,
      borderColorActive: cBasicareBlue,
      color: cBasicareBlue,
      colorActive: cBasicareBlue,
      borderRadius: "0",
    },
    CheckboxField: {
      activeColor: cBasicareBlue,
      activeSecondaryColor: "#dfd2b7",
      customCss: "",
    },
    DateRangePickerField: {
      dateSelectedColor: cBasicareBlue,
      customCss: `
        input:disabled {
          background-color: #e9ecef !important;
        }
      `,
    },
    Input: {
      customCss: `
        &:required {
          border-left: 3px solid ${cBasicareBlue};
        }
      `,
    },
    InputGroup: {
      customCss: `i { color: #bdbdbd }`,
    },
    PriceSummaryFooter: {
      customCss: `
        background: #ecf0f7;
        color: ${cBasicareOrange};
      `,
    },
    QuoteSummaryFloater: {
      customCss: `
        .quote-summary-floater__arrow-close {
          color:#dbc091;
        }
      `,
    },
    RadioFieldGroup: {
      checkRadius: "50%",
      borderRadius: "50%",
      radioColor: "#757575",
      radioColorActive: "#757575",
      radioColorSecondaryActive: "#e0e0e0",
    },
    Select: {
      borderColor: "#e0e3e2",
      borderColorActive: cBasicareBlue,
      indicatorColor: cBasicareBlue,
      borderRadius: "0",
      fontFamily: "Raleway, sans-serif",
      customCss: `
        .select-list-field {
          &.required > div:not(.form-error) > div {
            border-left: 3px solid ${cBasicareOrange};
            border-radius: 3px;
          }
        }
      `,
    },
    SelectListField: {
      customCss: `
        &.select-list-field {
          &.required > div:not(.form-error) > div {
            border-left: 3px solid ${cBasicareOrange};
            border-radius: 3px;
          }
        }
      `,
    },
  },
};
