import { validatorFns } from "@jauntin/reactables";
import { RxFormProviders } from "@reactables/forms";
import { normalizers } from "@jauntin/utilities";
import * as BcxValidators from "./bcxValidators";
import * as BcxNormalizers from "./bcxNormalizers";

const formProviders: RxFormProviders = {
  validators: { ...validatorFns(), ...BcxValidators },
  normalizers: { ...normalizers, ...BcxNormalizers },
};

export default formProviders;
