import { useReactable } from "@reactables/react";
import { RxToggle } from "@jauntin/reactables";
import { ExtendedMeta } from "@jauntin/utilities";
import { Button } from "@jauntin/react-ui";
import { useHistory } from "react-router-dom";
import TwoStageButton from "@basicare/common/src/Components/TwoStageButton";
import { MEMBERS_PAGE, getUrl } from "Helpers/URLParser";
import MembershipService from "Services/MembershipService";
import API from "Services/API";
import ModalDiscardAddNew from "Features/Shared/Components/ModalDiscardAddNew";
import ModalUpdateError from "Features/Shared/Components/ModalUpdateError";
import { getShowAddMemberActionBtn } from "Features/Members/Rx/Selectors/member.selector";
import { getAddMemberPayload } from "../Rx/Models/member.model";
import { RxRequest } from "@jauntin/reactables";
import MemberForm from "./MemberForm";
import { MemberForm as MemberFormValue } from "../Rx/Models/member.model";

const AddMember = () => {
  const history = useHistory();
  const goToMembersPage = () => history.push(getUrl(MEMBERS_PAGE));

  const [request, requestActions] = useReactable(RxRequest, {
    name: "rxAddMemberRequest",
    resource: (member: MemberFormValue) =>
      new MembershipService(new API()).addMember(getAddMemberPayload(member)),
    onSuccess: goToMembersPage,
  });

  const [submitTouched, { toggleOn: touchSubmit }] = useReactable(RxToggle);
  const [showDiscardModal, discardModalToggle] = useReactable(RxToggle);

  if (!request) return;

  const showErrors = ({ touched, error }: ExtendedMeta) =>
    Boolean((touched || submitTouched) && error);

  return (
    <>
      <MemberForm showErrors={showErrors}>
        {({ formState, formFields }) => {
          const { dirty, value, valid, pending } = formState.root;
          return (
            <>
              <div className="content__header content__header--autoWidth col-auto">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="m-0 font-weight-bold">Add New Member</h4>
                  <div className="d-flex float-right">
                    <Button
                      text="Discard Changes"
                      className="btn btn-outline-secondary px-4 mx-2"
                      onClick={discardModalToggle.toggleOn}
                    />
                    <TwoStageButton
                      className="btn btn-primary px-4 mx-2 text-nowrap border-radius-0"
                      spinning={request.loading}
                      actionEnabled={getShowAddMemberActionBtn(
                        { form: formState },
                        submitTouched
                      )}
                      action={() => requestActions.send(value)}
                      touchSubmit={touchSubmit}
                      text="Save Changes"
                      disableAction={!valid || !dirty || pending}
                    />
                  </div>
                </div>
              </div>
              {formFields}
            </>
          );
        }}
      </MemberForm>
      <ModalDiscardAddNew
        show={showDiscardModal}
        handleClose={discardModalToggle.toggleOff}
        confirmAction={goToMembersPage}
      />

      <ModalUpdateError
        show={Boolean(request.error)}
        text="There was an error creating a member. Please try again"
        handleCloseError={requestActions.resetState}
      />
    </>
  );
};

export default AddMember;
