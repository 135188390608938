import { untouch } from "redux-form";
import * as qs from "query-string";
import { ConnectedRouter } from "@jauntin/react-ui";
import API from "../../Services/API";
import AppInfoService from "../../Services/AppInfoService";
import { getUrl, LOGIN_PAGE, isProtectedPage } from "../../Helpers/URLParser";
import AuthService from "@basicare/common/src/Helpers/AuthService";
import {
  LOGINTIMEOUTINTERVAL,
  LOGINTIMEOUTPOLLINTERVAL,
} from "../../constants";
import ProducerService from "../../Services/ProducerService";
import FacilityService from "../../Services/FacilityService";
import MembershipService from "../../Services/MembershipService";

/**
 *
 * ACTION TYPES
 *
 */

export const getAppInfo = () => async (dispatch) => {
  try {
    const { data } = await new AppInfoService(new API()).getAppInfo();
    dispatch(setDataLoaded(data));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};

export const SET_DATA_LOADED = "SET_DATA_LOADED";
export const setDataLoaded = (payload) => ({ type: SET_DATA_LOADED, payload });

// An error has been returned from the server
export const API_ERROR = "API_ERROR";
export const errorResponse = (err) => {
  return (dispatch) => {
    // We are unauthenticated. Go to login screen with returnUrl
    if (
      err.response &&
      (err.response.status === 401 || err.response.status === 419) &&
      isProtectedPage(window.location.pathname)
    ) {
      const queryString = qs.stringify({ returnUrl: window.location.pathname });
      const url = `${getUrl(LOGIN_PAGE)}?${queryString}`;
      return dispatch(ConnectedRouter.push(url));
    }
    return dispatch({ type: API_ERROR, error: err });
  };
};

export const UPDATE_LOGIN_STATUS = "UPDATE_LOGIN_STATUS";
export const updateLoginStatus = (status) => {
  return { type: UPDATE_LOGIN_STATUS, payload: status };
};

export const LOGIN_LOCKED_OUT = "LOGIN_LOCKED_OUT";
export const setLoginLockedOut = (status) => {
  return { type: LOGIN_LOCKED_OUT, payload: status };
};

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const setCurrentUser = (user) => {
  return { type: SET_CURRENT_USER, payload: user };
};

export const RESET_CURRENT_USER = "RESET_CURRENT_USER";
export const resetCurrentUser = () => ({ type: RESET_CURRENT_USER });

// Check login status
export const loginStatus = () => {
  return (dispatch) => {
    new AuthService(new API())
      .authLogin()
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(updateLoginStatus(true));
          dispatch(setCurrentUser(response.data));
        }
      })
      .catch((err) => {
        dispatch(updateLoginStatus(false));
        dispatch(resetCurrentUser());
        dispatch(errorResponse(err));
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    new AuthService(new API())
      .authLogout()
      .then((result) => {
        if (result.status === 200) {
          dispatch(updateLoginStatus(false));
          dispatch(resetCurrentUser());
          dispatch(ConnectedRouter.push(getUrl(LOGIN_PAGE)));
        }
      })
      .catch((err) => {
        dispatch(errorResponse(err));
        dispatch(updateLoginStatus(false));
        dispatch(resetCurrentUser());
        dispatch(ConnectedRouter.push(getUrl(LOGIN_PAGE)));
      });
  };
};

// Login timeout. Maintains a global activity check. If no activity has been dispatched after the timeoutInterval,
// manually log out the user
let loginTimeout;
let lastActivityTime;

const handleTimeout = (dispatch) => {
  clearInterval(loginTimeout);
  dispatch(logout());
};

export const refreshLoginInterval = (newLastActivityTime) => {
  lastActivityTime = newLastActivityTime;
  return (dispatch) => {
    clearInterval(loginTimeout);
    loginTimeout = setInterval(() => {
      if (Date.now() - lastActivityTime >= LOGINTIMEOUTINTERVAL) {
        handleTimeout(dispatch);
      }
    }, LOGINTIMEOUTPOLLINTERVAL);
  };
};

export const SET_PAGE = "SET_PAGE";
export const setPage = (newPage) => {
  return { type: SET_PAGE, newPage };
};

// Get US states from server and dispatch data
export const STORE_STATES = "STORE_STATES";
const storeStates = (data) => ({
  type: STORE_STATES,
  payload: data,
});
export const loadStates = () => {
  return (dispatch) => {
    return new AppInfoService(new API())
      .getStates()
      .then((response) => {
        dispatch(storeStates(response.data));
      })
      .catch((err) => dispatch(errorResponse(err)));
  };
};

export const SET_IS_FORM_PRISTINE = "SET_IS_FORM_PRISTINE";
export const setIsFormPristine = (data) => {
  return { type: SET_IS_FORM_PRISTINE, payload: { data } };
};

// Update a Redux form with some values. e.g. to automatically fill out a form with fresh values
// () => { reset('formName'); setFormValues('formName', { field1: 'hello!' });
export const SET_FORM_VALUES = "SET_FORM_VALUES";
export const setFormValues = (formName, values) => {
  return { type: SET_FORM_VALUES, payload: { formName, values } };
};
export const SET_REQUEST_RESET_FORM_SUCCESS_MESSAGE =
  "SET_REQUEST_RESET_FORM_SUCCESS_MESSAGE";
export const setRequestResetFormSuccessMessage = (value) => ({
  type: SET_REQUEST_RESET_FORM_SUCCESS_MESSAGE,
  value,
});
export const SET_RESET_FORM_SUCCESS_MESSAGE = "SET_RESET_FORM_SUCCESS_MESSAGE";
export const setResetFormSuccessMessage = (value) => ({
  type: SET_RESET_FORM_SUCCESS_MESSAGE,
  value,
});

// Request Facility search results from the server, and dispatch returned data
export const UPDATE_FACILITY_DATA = "UPDATE_FACILITY_DATA";
export const updateFacilityData = (data) => {
  return { type: UPDATE_FACILITY_DATA, payload: data };
};
export const getFacility = (id) => {
  return (dispatch) => {
    return new FacilityService(new API())
      .getFacility(id)
      .then((response) => dispatch(updateFacilityData(response.data)))
      .catch((err) => dispatch(errorResponse(err)));
  };
};
export const UPDATE_FACILITIES_TABLE = "UPDATE_FACILITIES_TABLE";
const updateFacilitiesTable = (data) => {
  return { type: UPDATE_FACILITIES_TABLE, payload: data };
};
export const UPDATE_FACILITIES_SEARCH_TERM = "UPDATE_FACILITIES_SEARCH_TERM";
const updateFacilitiesSearchTerm = (data) => {
  return { type: UPDATE_FACILITIES_SEARCH_TERM, payload: data };
};
export const SET_EDITING = "SET_EDITING";
export const setEditingAction = (data) => {
  return { type: SET_EDITING, payload: data };
};
export const getSearchFacilities = (searchInput, page, perPage) => {
  return (dispatch) => {
    dispatch(updateFacilitiesSearchTerm(searchInput));
    new FacilityService(new API())
      .getFacilitiesSearchResults(searchInput, page, perPage)
      .then((response) => {
        dispatch(updateFacilitiesTable(response.data));
        dispatch(setEditingAction(false));
      })
      .catch((err) => dispatch(errorResponse(err)));
  };
};
export const SHOW_FACILITY_STATUS_ALERT = "SHOW_FACILITY_STATUS_ALERT";
export const showFacilityStatusAlert = (data) => {
  return { type: SHOW_FACILITY_STATUS_ALERT, payload: data };
};

export const SET_NEW_FACILITY_NAME = "SET_NEW_FACILITY_NAME";
export const setNewFacilityStatusMessage = (data) => {
  return { type: SET_NEW_FACILITY_NAME, payload: data };
};

// Request Producer search results from the server, and dispatch returned data
export const UPDATE_PRODUCER_DATA = "UPDATE_PRODUCER_DATA";
export const updateProducerData = (data) => {
  return { type: UPDATE_PRODUCER_DATA, payload: data };
};
export const getProducer = (id) => {
  return (dispatch) => {
    return new ProducerService(new API())
      .getProducer(id)
      .then((response) => dispatch(updateProducerData(response.data)))
      .catch((err) => dispatch(errorResponse(err)));
  };
};
export const UPDATE_PRODUCERS_TABLE = "UPDATE_PRODUCERS_TABLE";
const updateProducersTable = (data) => {
  return { type: UPDATE_PRODUCERS_TABLE, payload: data };
};
export const UPDATE_PRODUCERS_SEARCH_TERM = "UPDATE_PRODUCERS_SEARCH_TERM";
const updateProducersSearchTerm = (data) => {
  return { type: UPDATE_PRODUCERS_SEARCH_TERM, payload: data };
};
export const getSearchProducers = (searchInput, page, perPage) => {
  return (dispatch) => {
    dispatch(updateProducersSearchTerm(searchInput));
    new ProducerService(new API())
      .getProducersSearchResults(searchInput, page, perPage)
      .then((response) => {
        dispatch(updateProducersTable(response.data));
      })
      .catch((err) => dispatch(errorResponse(err)));
  };
};

export const SHOW_NEW_PRODUCER_ALERT = "SHOW_NEW_PRODUCER_ALERT";
export const showNewProducerAlert = (data) => {
  return { type: SHOW_NEW_PRODUCER_ALERT, payload: data };
};

export const SET_NEW_PRODUCER_STATUS_MESSAGE =
  "SET_NEW_PRODUCER_STATUS_MESSAGE";
export const setNewProducerStatusMessage = (data) => {
  return { type: SET_NEW_PRODUCER_STATUS_MESSAGE, payload: data };
};

export const SET_VALID_PRODUCER_CODE = "SET_VALID_PRODUCER_CODE";
export const setValidProducerCode = (data) => {
  return { type: SET_VALID_PRODUCER_CODE, payload: data };
};

export const SET_HAS_CHECKED_PRODUCER_CODE = "SET_HAS_CHECKED_PRODUCER_CODE";
export const setHasCheckedProducerCode = (data) => {
  return { type: SET_HAS_CHECKED_PRODUCER_CODE, payload: data };
};

export const checkAndSetValidProducerCode = (commissionId) => {
  return (dispatch) =>
    new ProducerService(new API())
      .getIsValidProducerCode(commissionId)
      .then((response) => {
        dispatch(setHasCheckedProducerCode(true));
        dispatch(setValidProducerCode(response.data.valid));
      })
      .catch((err) => {
        dispatch(setHasCheckedProducerCode(false));
        dispatch(errorResponse(err));
      });
};

export const SET_PRODUCER_SEARCH_RESULTS = "SET_PRODUCER_SEARCH_RESULTS";
export const setProducerSearchResults = (data) => {
  return { type: SET_PRODUCER_SEARCH_RESULTS, payload: data };
};

export const SET_SELECTED_PRODUCERS_IN_RESULTS =
  "SET_SELECTED_PRODUCERS_IN_RESULTS";
export const setSelectedProducerInResults = (data) => {
  return { type: SET_SELECTED_PRODUCERS_IN_RESULTS, payload: data };
};

export const CLEAR_PRODUCER_DATA = "CLEAR_PRODUCER_DATA";
export const clearProducerData = () => {
  return { type: CLEAR_PRODUCER_DATA };
};

export const CLEAR_MEMBER_DATA = "CLEAR_MEMBER_DATA";
export const clearMemberData = () => {
  return { type: CLEAR_MEMBER_DATA };
};

export const CLEAR_FIELD_ERRORS = "CLEAR_FIELD_ERRORS";
export const clearFieldErrors = (formName, fields) => {
  return (dispatch) => {
    fields.forEach((field) => {
      dispatch(untouch(formName, [field]));
    });
  };
};

export const SET_USERS_LOADING = "SET_USERS_LOADING";
export const setUsersLoading = (isLoading) => ({
  type: SET_USERS_LOADING,
  payload: { isLoading },
});

export const UPDATE_USERS_TABLE = "UPDATE_USERS_TABLE";
export const updateUsersTable = (data) => {
  return { type: UPDATE_USERS_TABLE, payload: data };
};

export const RESET_USERS = "RESET_USERS";
export const resetUsers = () => ({ type: RESET_USERS });

export const USER_SAVE_SUCCESS = "USER_SAVE_SUCCESS";
export const userSaveSuccess = (user) => ({
  type: USER_SAVE_SUCCESS,
  payload: user,
});

export const SET_USER_ROLE_SELECTION_VISIBLE =
  "SET_USER_ROLE_SELECTION_VISIBLE";
export const setUserRoleSelectionVisible = (visible) => ({
  type: SET_USER_ROLE_SELECTION_VISIBLE,
  payload: visible,
});

export const SET_DELETE_USER_ERROR = "SET_DELETE_USER_ERROR";
export const setDeleteUserError = (error) => ({
  type: SET_DELETE_USER_ERROR,
  payload: error,
});

export const TOGGLE_MEMBERSHIP_TOAST = "TOGGLE_MEMBERSHIP_TOAST";
export const toggleMembershipToast = (type, success) => ({
  type: TOGGLE_MEMBERSHIP_TOAST,
  payload: { type, success },
});

export const triggerWebhook = (id, kind) => (dispatch) => {
  new MembershipService(new API())
    .triggerWebhook(id, kind)
    .then(() => {
      dispatch(toggleMembershipToast("triggerWebhook", true));
    })
    .catch((e) => {
      dispatch(toggleMembershipToast("triggerWebhook", false));
      console.error(e);
    });
};

export const triggerReminder = (id) => (dispatch) => {
  new MembershipService(new API())
    .triggerReminder(id)
    .then(() => {
      dispatch(toggleMembershipToast("triggerReminder", true));
    })
    .catch((e) => {
      dispatch(toggleMembershipToast("triggerReminder", false));
      console.error(e);
    });
};

export const triggerTrialReminder = (id) => (dispatch) => {
  new MembershipService(new API())
    .triggerTrialReminder(id)
    .then(() => {
      dispatch(toggleMembershipToast("triggerReminder", true));
    })
    .catch((e) => {
      dispatch(toggleMembershipToast("triggerReminder", false));
      console.error(e);
    });
};
