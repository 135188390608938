/**
 *
 * Helper functions that provide route-related information
 *
 */
const LOGIN_PAGE = "login";
const TWO_FACTOR_PAGE = "two-factor";
const RESET_PASSWORD = "reset-password";
const REQUEST_RESET_PASSWORD = "request-reset-password";

const ADMIN_PAGE = "admin";

const MEMBERS_PAGE = "members";
const MEMBER_PAGE = "member";
const CONTACT_PAGE = "contact";
const MEMBERS_NOTES_PAGE = "members-notes";
const ADD_MEMBER_PAGE = "add-member";
const EDIT_MEMBER_PAGE = "edit-member";

const PROMO_CODES_PAGE = "promo-codes";
const PROMO_CODE_PAGE = "promo-code";
const ADD_PROMO_CODE_PAGE = "add-promo-code";
const EDIT_PROMO_CODE_PAGE = "edit-promo-code";

const FACILITIES_PAGE = "organizations";
const FACILITY_PAGE = "organization";
const FACILITY_NOTES_PAGE = "organization-notes";
const ADD_FACILITY_PAGE = "add-organization";
const EDIT_FACILITY_PAGE = "edit-organization";

const VENUES_PAGE = "venues";
const VENUE_PAGE = "venue";
const ADD_VENUE_PAGE = "add-venue";

const PRODUCERS_PAGE = "producers";
const PRODUCER_PAGE = "producer";
const PRODUCER_NOTES_PAGE = "producer-notes";
const ADD_PRODUCER_PAGE = "add-producer";

const ASSOCIATIONS_PAGE = "associations";
const ASSOCIATION_PAGE = "association";
const ASSOCIATION_NOTES_PAGE = "association-notes";
const ADD_ASSOCIATION_PAGE = "add-association";

const BLOCKED_VENUES_PAGE = "blocked-venues";
const ADD_BLOCKED_VENUE_PAGE = "add-blocked-venue";

const PARTNERS_PAGE = "partners";
const CUSTOMERS_PAGE = "customers";

const VENUES_SEARCH_PAGE = "venues-search";

const REPORTS_PAGE = "reports";

const CONTACTS_PAGE = "contacts";

const MAILCATCHER_PAGE = "mailcatcher";

const USERS_PAGE = "users";
const ADD_USER_PAGE = "add-user";

const SETTINGS_PAGE = "settings";

function root() {
  return (process.env.PUBLIC_URL || "/").replace(/^(.*)[^/]$/, "$1/");
}

// For a URL on the members page, tells you the member ID
function getMemberId(location = "") {
  const id = location.replace(/^.*members\/([0-9]+).*$/, "$1");
  return parseInt(id || 0, 10);
}

// For a URL on the producers page, tells you the producer ID
function getProducerId(location = "") {
  const id = location.replace(/^.*producers\/([0-9]+).*$/, "$1");
  return parseInt(id || 0, 10);
}

// For a URL on the associations page, tells you the association ID
function getAssociationId(location = "") {
  const id = location.replace(/^.*associations\/([0-9]+).*$/, "$1");
  return parseInt(id || 0, 10);
}

// For a URL on the facilities page, tells you the facility ID
function getFacilityId(location = "") {
  const id = location.replace(/^.*organizations\/([0-9]+).*$/, "$1");
  return parseInt(id || 0, 10);
}

// For a URL on a venue page, tells you the venue ID
function getVenueId(location = "") {
  const id = location.replace(
    /^.*organizations\/([0-9]+)\/venues\/([0-9]+).*$/,
    "$2"
  );
  return parseInt(id || 0, 10);
}

function isProtectedPage(location = "") {
  return !!location.match(`${root()}admin`);
}

// Gets the url for a page. If a page has an id parameter, supply the parameter - or if you don't supply the parameter, you will get the base route e.g. admin/members/:id/member
function getUrl(page, idValue, venueId) {
  const id = idValue || ":id";
  const vId = venueId || ":venue_id";
  if (page === LOGIN_PAGE) {
    return `${root()}`;
  }
  if (page === TWO_FACTOR_PAGE) {
    return `${root()}two-factor`;
  }
  if (page === REQUEST_RESET_PASSWORD) {
    return `${root()}request-reset-password`;
  }
  if (page === RESET_PASSWORD) {
    return `${root()}reset-password`;
  }

  if (page === ADMIN_PAGE) {
    return `${root()}admin`;
  }

  if (page === MEMBERS_PAGE) {
    return `${root()}admin/members`;
  }

  if (page === ADD_MEMBER_PAGE) {
    return `${root()}admin/members/add-member`;
  }
  if (page === EDIT_MEMBER_PAGE) {
    return `${root()}admin/members/${id}/edit-member`;
  }
  if (page === MEMBER_PAGE) {
    return `${root()}admin/members/${id}/member`;
  }
  if (page === CONTACT_PAGE) {
    return `${root()}admin/members/${id}/contact`;
  }
  if (page === MEMBERS_NOTES_PAGE) {
    return `${root()}admin/members/${id}/notes`;
  }

  if (page === PROMO_CODES_PAGE) {
    return `${root()}admin/promo-codes`;
  }

  if (page === ADD_PROMO_CODE_PAGE) {
    return `${root()}admin/promo-codes/add-promo-code`;
  }
  if (page === EDIT_PROMO_CODE_PAGE) {
    return `${root()}admin/promo-codes/${id}/edit-promo-code`;
  }
  if (page === PROMO_CODE_PAGE) {
    return `${root()}admin/promo-codes/${id}/promo-code`;
  }

  if (page === FACILITIES_PAGE) {
    return `${root()}admin/organizations`;
  }
  if (page === FACILITY_PAGE) {
    return `${root()}admin/organizations/${id}/organization`;
  }
  if (page === FACILITY_NOTES_PAGE) {
    return `${root()}admin/organizations/${id}/notes`;
  }
  if (page === ADD_FACILITY_PAGE) {
    return `${root()}admin/organizations/add-organization`;
  }
  if (page === EDIT_FACILITY_PAGE) {
    return `${root()}admin/organizations/${id}/edit-organization`;
  }

  if (page === VENUES_SEARCH_PAGE) {
    return `${root()}admin/venues-search`;
  }
  if (page === VENUES_PAGE) {
    return `${root()}admin/organizations/${id}/venues`;
  }
  if (page === VENUE_PAGE) {
    return `${root()}admin/organizations/${id}/venues/${vId}/venue`;
  }
  if (page === ADD_VENUE_PAGE) {
    return `${root()}admin/organizations/${id}/add-venue`;
  }

  if (page === PRODUCERS_PAGE) {
    return `${root()}admin/producers`;
  }
  if (page === PRODUCER_PAGE) {
    return `${root()}admin/producers/${id}/producer`;
  }
  if (page === PRODUCER_NOTES_PAGE) {
    return `${root()}admin/producers/${id}/notes`;
  }
  if (page === ADD_PRODUCER_PAGE) {
    return `${root()}admin/producers/add-producer`;
  }

  if (page === ASSOCIATIONS_PAGE) {
    return `${root()}admin/associations`;
  }
  if (page === ASSOCIATION_PAGE) {
    return `${root()}admin/associations/${id}/association`;
  }
  if (page === ASSOCIATION_NOTES_PAGE) {
    return `${root()}admin/associations/${id}/notes`;
  }
  if (page === ADD_ASSOCIATION_PAGE) {
    return `${root()}admin/associations/add-association`;
  }

  if (page === BLOCKED_VENUES_PAGE) {
    return `${root()}admin/blocked-venues`;
  }
  if (page === ADD_BLOCKED_VENUE_PAGE) {
    return `${root()}admin/blocked-venues/add-blocked-venue`;
  }

  if (page === PARTNERS_PAGE) {
    return `${root()}admin/partners`;
  }
  if (page === CUSTOMERS_PAGE) {
    return `${root()}admin/customers`;
  }

  if (page === REPORTS_PAGE) {
    return `${root()}admin/reports`;
  }

  if (page === CONTACTS_PAGE) {
    return `${root()}admin/contacts`;
  }

  if (page === MAILCATCHER_PAGE) {
    return `${root()}admin/mailcatcher`;
  }

  if (page === USERS_PAGE) {
    return `${root()}admin/users`;
  }

  if (page === ADD_USER_PAGE) {
    return `${root()}admin/users/add-user`;
  }

  if (page === SETTINGS_PAGE) {
    return `${root()}admin/settings`;
  }
  return "";
}

function getUrlWithDomain(page, idValue, venueId) {
  const prefix = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }`;
  return `${prefix}${getUrl(page, idValue, venueId)}`;
}

function getPageType(url) {
  if (/\/admin\/members\/\d+\/member/.test(url)) {
    return MEMBER_PAGE;
  }
  if (/\/admin\/members\/\d+\/contact/.test(url)) {
    return CONTACT_PAGE;
  }
  if (/\/admin\/members\/\d+\/notes/.test(url)) {
    return MEMBERS_NOTES_PAGE;
  }
  if (/\/admin\/members\/add-member/.test(url)) {
    return ADD_MEMBER_PAGE;
  }

  if (/\/admin\/members\/edit-member/.test(url)) {
    return EDIT_MEMBER_PAGE;
  }

  if (/\/admin\/members/.test(url)) {
    return MEMBERS_PAGE;
  }

  if (/\/admin\/organizations\/\d+\/organization/.test(url)) {
    return FACILITY_PAGE;
  }
  if (/\/admin\/organizations\/add-organization/.test(url)) {
    return ADD_FACILITY_PAGE;
  }
  if (/\/admin\/organizations\/edit-organization/.test(url)) {
    return EDIT_FACILITY_PAGE;
  }
  if (/\/admin\/organizations/.test(url)) {
    return FACILITIES_PAGE;
  }

  if (/\/admin\/producers\/\d+\/producer/.test(url)) {
    return PRODUCER_PAGE;
  }
  if (/\/admin\/producers\/add-producer/.test(url)) {
    return ADD_PRODUCER_PAGE;
  }
  if (/\/admin\/producers\/\d+\/notes/.test(url)) {
    return PRODUCER_NOTES_PAGE;
  }
  if (/\/admin\/producers/.test(url)) {
    return PRODUCERS_PAGE;
  }

  if (/\/admin\/associations\/\d+\/association/.test(url)) {
    return ASSOCIATION_PAGE;
  }
  if (/\/admin\/associations\/add-association/.test(url)) {
    return ADD_ASSOCIATION_PAGE;
  }
  if (/\/admin\/associations\/\d+\/notes/.test(url)) {
    return ASSOCIATION_NOTES_PAGE;
  }
  if (/\/admin\/associations/.test(url)) {
    return ASSOCIATIONS_PAGE;
  }

  if (/\/admin\/blocked-venues/.test(url)) {
    return BLOCKED_VENUES_PAGE;
  }

  if (/\/admin\/venues-search/.test(url)) {
    return VENUES_SEARCH_PAGE;
  }

  if (/^\/admin\/reports/.test(url)) {
    return REPORTS_PAGE;
  }

  if (/^\/admin\/contacts/.test(url)) {
    return CONTACTS_PAGE;
  }

  if (/^\/$/.test(url)) {
    return LOGIN_PAGE;
  }

  if (/^\/request-reset-password/.test(url)) {
    return REQUEST_RESET_PASSWORD;
  }

  if (/\/admin\/settings/.test(url)) {
    return SETTINGS_PAGE;
  }

  return "";
}

export {
  getMemberId,
  getProducerId,
  getAssociationId,
  getFacilityId,
  getVenueId,
  getUrl,
  getUrlWithDomain,
  isProtectedPage,
  getPageType,
  LOGIN_PAGE,
  TWO_FACTOR_PAGE,
  REQUEST_RESET_PASSWORD,
  ADMIN_PAGE,
  RESET_PASSWORD,
  MEMBERS_PAGE,
  MEMBER_PAGE,
  ADD_MEMBER_PAGE,
  EDIT_MEMBER_PAGE,
  CONTACT_PAGE,
  MEMBERS_NOTES_PAGE,
  PROMO_CODES_PAGE,
  PROMO_CODE_PAGE,
  ADD_PROMO_CODE_PAGE,
  EDIT_PROMO_CODE_PAGE,
  FACILITIES_PAGE,
  FACILITY_PAGE,
  ADD_FACILITY_PAGE,
  EDIT_FACILITY_PAGE,
  FACILITY_NOTES_PAGE,
  VENUES_SEARCH_PAGE,
  VENUES_PAGE,
  VENUE_PAGE,
  ADD_VENUE_PAGE,
  PRODUCERS_PAGE,
  PRODUCER_PAGE,
  PRODUCER_NOTES_PAGE,
  ADD_PRODUCER_PAGE,
  ASSOCIATIONS_PAGE,
  ASSOCIATION_PAGE,
  ASSOCIATION_NOTES_PAGE,
  ADD_ASSOCIATION_PAGE,
  BLOCKED_VENUES_PAGE,
  ADD_BLOCKED_VENUE_PAGE,
  PARTNERS_PAGE,
  CUSTOMERS_PAGE,
  REPORTS_PAGE,
  CONTACTS_PAGE,
  MAILCATCHER_PAGE,
  USERS_PAGE,
  ADD_USER_PAGE,
  SETTINGS_PAGE,
};
