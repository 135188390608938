import { Models } from "@jauntin/reactables";
import {
  AddMemberPayload,
  CancelMemberPayload,
  SaveMemberPayload,
} from "Features/Members/Rx/Models/member.model";
import {
  CustomContact,
  MemberSearchDetails,
} from "Features/Members/Rx/Models/memberDetails.model";
import { MemberEmailsPayload } from "Features/Members/Rx/Models/memberEmailsPayload.model";
import API from "./API";
import { dateHelpers } from "@jauntin/utilities";
import { SearchMembersPayload } from "Features/Members/Rx/Models/member.model";
import { getFilteredParams } from "@basicare/common/src/Helpers/getQueryString";
/**
 *
 * Services from the server that the app needs.
 *
 */
class MembershipService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getMembersSearchResults = (
    params: SearchMembersPayload
  ): Promise<{ data: Models.PaginatedData<MemberSearchDetails> }> => {
    const filteredParams = {
      ...getFilteredParams(params),
      perPage: 10,
    };
    return this.api.get({
      location: "/membership",
      params: filteredParams,
    });
  };

  getMembership = (id) =>
    this.api.get({
      location: `/membership/${id}`,
    });

  triggerWebhook = (id, kind) =>
    this.api.post({
      location: `/membership/${id}/test-webhook/${kind}`,
    });

  triggerReminder = (id) =>
    this.api.post({
      location: `/membership/${id}/test-renewal-reminder`,
    });

  triggerTrialReminder = (id) =>
    this.api.post({
      location: `/membership/${id}/test-trial-reminder`,
    });

  sendReportByType = (type) =>
    this.api.post({
      location: `/reports/type/${type}`,
    });

  addMember = (payload: AddMemberPayload) => {
    return this.api.post({
      location: "/membership/createManual",
      body: JSON.stringify(payload),
    });
  };

  updateCustomContacts(
    id: number,
    customContacts: CustomContact[]
  ): Promise<{ data: CustomContact[] }> {
    return this.api.post({
      location: `/membership/${id}/contacts`,
      body: customContacts,
    });
  }

  sendWelcomeEmails = (data: MemberEmailsPayload, id) => {
    return this.api.post({
      location: `/membership/${id}/documents/thankyou/mail`,
      body: data,
    });
  };

  saveMember = (payload: SaveMemberPayload, id: number) => {
    return this.api.put({
      location: `/membership/${id}`,
      body: JSON.stringify(payload),
    });
  };

  cancelMember = (payload: CancelMemberPayload, id: number): Promise<void> => {
    return this.api.post({
      location: `/membership/${id}/cancel`,
      body: JSON.stringify(payload),
    });
  };

  downloadMemberReport(
    startDate,
    endDate,
    timezone,
    type,
    adapter = "CsvAdapter"
  ) {
    return this.api.getDownload({
      location: `${API.base}reports/type/${type}?${
        startDate
          ? `startDate=${dateHelpers.dateOnlyStringFormat(startDate)}&`
          : ""
      }endDate=${dateHelpers.dateOnlyStringFormat(endDate)}&adapter=${adapter}`,
      config: {
        responseType: "blob",
      },
    });
  }

  postImportMemberships = (facilityId, file) => {
    const formData = new FormData();
    formData.append("file", file);
    return this.api.post({
      location: `/facility/${facilityId}/membership/import`,
      body: formData,
    });
  };

  getIsUniqueRecuroSubscriberNumber = (number) =>
    this.api.get({
      location: `/membership/uniqueRecuroSubscriberNumber/${number}`,
    });

  sendUpdateLink(id: number): Promise<{ success: boolean }> {
    return this.api.post({
      location: `/membership/${id}/payment-method/send-update-link`,
    });
  }

  addNote({ id, message }: { id: number; message: string }) {
    return this.api.post({
      location: `/membership/${id}/notes`,
      body: { message },
    });
  }
}

export default MembershipService;
