import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { control, group } from "@reactables/forms";
import { useEffect, useRef, ChangeEvent } from "react";
import { RxPaginatedSearch } from "@jauntin/reactables";
import SelectList from "@basicare/common/src/Components/FormElements/SelectList";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import SearchResultsTable from "./SearchResultsTable";
import { stringHelpers } from "../../../Helpers/FormattingHelpers";
import { getUrl, ADD_MEMBER_PAGE } from "../../../Helpers/URLParser";
import { Pagination } from "@jauntin/react-ui";
import SearchField from "../../Shared/Components/SearchField";
import { canTestFeatures as canTestFeaturesSelector } from "Redux/Selectors/Users";
import { useReactable } from "@reactables/react";
import MembershipService from "Services/MembershipService";
import API from "Services/API";
import { pluralize } from "@basicare/common/src/Helpers/StringHelper";
import { Form, Field } from "@reactables/react-forms";
import { getLgSelectStyle } from "@basicare/common/src/Helpers/ReactSelectStyle";
import { statusOptions } from "@basicare/common/src/Constants/membership";
import { getQueryString } from "@basicare/common/src/Helpers/getQueryString";
import { MemberSearchDetails } from "Features/Members/Rx/Models/memberDetails.model";
import { SearchMembersPayload } from "Features/Members/Rx/Models/member.model";
import StyledCheckboxField from "@basicare/common/src/Components/FormElements/CheckboxField/StyledCheckboxField";
import { promoCodeTypeOptions } from "@basicare/common/src/Constants/promoCodeTypes";

const defaultSearch = {
  search: "",
  status: null,
  promoCodeType: null,
  withCancellationDate: 0,
  page: 1,
};

const getConfigFromSearch = (search: string) => {
  const urlParams = new URLSearchParams(search);
  return group({
    controls: {
      search: control([urlParams.get("search") || defaultSearch.search]),
      status: control([urlParams.get("status") || defaultSearch.status]),
      promoCodeType: control([
        urlParams.get("promoCodeType") || defaultSearch.promoCodeType,
      ]),
      withCancellationDate: control([
        parseInt(urlParams.get("withCancellationDate")) ||
          defaultSearch.withCancellationDate,
      ]),
      page: control([parseInt(urlParams.get("page")) || defaultSearch.page]),
    },
  });
};

const SearchMembersContainer = () => {
  const location = useLocation();
  const history = useHistory();

  const canTestFeatures = useSelector(canTestFeaturesSelector);

  const searchConfig = useRef(getConfigFromSearch(location.search)).current;
  const membershipService = useRef(new MembershipService(new API())).current;

  const [state, actions] = useReactable(
    RxPaginatedSearch<MemberSearchDetails, SearchMembersPayload>,
    {
      resource: (params) =>
        membershipService
          .getMembersSearchResults(params)
          .then(({ data }) => data),
      searchConfig,
      onSearchChange: (search) => {
        history.replace({ search: getQueryString(search, defaultSearch) });
      },
    }
  );

  useEffect(() => actions.searchForm.destroy, [actions]);

  if (!state) return;

  return (
    <div className="p-5 mt-2 scroll-part">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="c-gold">Members</h1>
        <NavLink to={getUrl(ADD_MEMBER_PAGE)} className="btn btn-primary px-4">
          Add Member Manually
        </NavLink>
      </div>
      <SearchField
        label="Search by member number, member name, member phone, member email or recuro subscriber number"
        fieldName="search"
        value={state.searchForm.root.value.search}
        change={(search: string) =>
          actions.searchForm.fieldChangePageReset({
            value: search,
            name: "search",
          })
        }
        totalText={
          state.searchResults.data
            ? `${stringHelpers.commaSeparatedNumber(
                state.searchResults.data?.total
              )} ${pluralize("member", state.searchResults.data?.total)}`
            : ""
        }
      />
      <div className="row">
        <Form rxForm={[state.searchForm, actions.searchForm]}>
          <div className="col-sm-3">
            <Field
              name="status"
              component={SelectList}
              customStyles={getLgSelectStyle}
              placeholder="Status"
              options={statusOptions}
              searchable={false}
              inputClassName="form-control-lg pl-0"
              labelClassName="d-none"
              onChange={(value) => {
                actions.searchForm.fieldChangePageReset({
                  name: "status",
                  value,
                });
              }}
              isClearable
            />
          </div>
          <div className="col-lg-3">
            <Field
              name="promoCodeType"
              component={SelectList}
              customStyles={getLgSelectStyle}
              placeholder="Promo Code Type"
              options={promoCodeTypeOptions}
              searchable={false}
              inputClassName="form-control-lg pl-0"
              labelClassName="d-none"
              onChange={(value) => {
                actions.searchForm.fieldChangePageReset({
                  name: "promoCodeType",
                  value,
                });
              }}
              isClearable
            />
          </div>
          <div className="col-auto">
            <Field
              name="withCancellationDate"
              component={StyledCheckboxField}
              type="checkbox"
              label="Show Members With Cancellation Date Only"
              className="pt-1"
              onChange={({
                currentTarget: { checked },
              }: ChangeEvent<HTMLInputElement>) => {
                actions.searchForm.fieldChangePageReset({
                  name: "withCancellationDate",
                  value: checked ? 1 : 0,
                });
              }}
            />
          </div>
        </Form>
      </div>
      {(() => {
        if (!state.searchResults.data) return <Spinner animation="border" />;

        const {
          searchResults: {
            data: { data: members, ...pagination },
          },
        } = state;

        const resultsMessage =
          pagination.total === 0
            ? "Sorry, we couldn’t find any results. Please check the spelling or try different search term."
            : "";
        return (
          <>
            {pagination.total !== 0 ? (
              <>
                <SearchResultsTable
                  membersList={members}
                  canTestFeatures={canTestFeatures}
                  loading={state.searchResults.loading}
                />
                <div className="d-flex justify-content-end">
                  <Pagination
                    pagination={pagination}
                    goToPage={actions.searchForm.changePage}
                  />
                </div>
              </>
            ) : (
              <div className="row">
                <div className="col-sm-8 h4">{resultsMessage}</div>
              </div>
            )}
          </>
        );
      })()}
    </div>
  );
};

export default SearchMembersContainer;
