import { Route, Switch } from "react-router-dom";
import SearchFacilitiesContainer from "./SearchFacilitiesContainer";
import {
  getUrl,
  FACILITIES_PAGE,
  ADD_FACILITY_PAGE,
} from "../../../Helpers/URLParser";
import Facility from "./Facility";
import AddFacility from "./AddFacility";

const FacilitiesContainer = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          component={SearchFacilitiesContainer}
          path={getUrl(FACILITIES_PAGE)}
        />
        <Route
          path={`${getUrl(FACILITIES_PAGE)}/:id/:pageType`}
          component={Facility}
        />
        <Route path={getUrl(ADD_FACILITY_PAGE)} component={AddFacility} />
      </Switch>
    </>
  );
};

export default FacilitiesContainer;
