import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Field, change } from "redux-form";
import { producerCommissionId } from "../../../Helpers/validators";
import { producerCodeLength } from "@basicare/common/src/Constants/codes";
import { normalizeProducerCommissionId } from "../../../normalizer";
import { producerPropType } from "../../../Helpers/ProducerModel";
import Debounce from "../../../Helpers/Debounce";
import { producerFormName } from "constants";
import { getEditProducerFormImgSrc } from "Redux/Selectors/Producer";
import ProducerLogoUpload from "./ProducerLogoUpload";
import TextField from "@basicare/common/src/Components/FormElements/TextField";
import { validators } from "@jauntin/utilities";
const { required } = validators;
const producerCodeDebounce = new Debounce({ period: 500 });

const ProducerInformation = ({
  editing,
  producer,
  validProducerCode,
  setValidProducerCode,
  hasCheckedProducerCode,
  setHasCheckedProducerCode,
  checkAndSetValidProducerCode,
}) => {
  const producerFormImgSrc = useSelector(getEditProducerFormImgSrc);
  const dispatch = useDispatch();

  const onProducerImageSelect = (file) => {
    dispatch(change(producerFormName, "producerImgFile", file));
  };

  const clearProducerLogo = () => {
    dispatch(change(producerFormName, "producerImgFile", null));
    dispatch(change(producerFormName, "producerLogoUrl", null));
  };

  return (
    <div className="card w-100">
      {editing ? (
        <>
          <div className="card-header bg-transparent d-flex justify-content-between">
            <div className="my-auto contacts__cardTitle">
              <strong>Broker Information</strong>
            </div>
          </div>
          <div className="card-body">
            <Field
              component={TextField}
              validate={[required]}
              name="producerName"
              label="Broker Name"
              ariaLabel="Broker Name"
              type="text"
              inputClassName="form-control-lg col-lg-6 mb-4"
              errorClassName="d-inline ml-2"
            />
            <Field
              component={TextField}
              label="Broker Code"
              ariaLabel="Broker Code"
              validate={[required, producerCommissionId]}
              name="producerCommissionId"
              type="text"
              normalize={normalizeProducerCommissionId}
              inputClassName="form-control-lg col-md-4 mb-4"
              errorClassName="d-inline ml-2"
              onChange={(e) => {
                const normalizedValue = normalizeProducerCommissionId(
                  e.target.value
                );

                if (normalizedValue.length === producerCodeLength) {
                  if (normalizedValue === producer.commissionId) {
                    setHasCheckedProducerCode(true);
                    setValidProducerCode(true);
                  } else {
                    setHasCheckedProducerCode(false);
                    producerCodeDebounce.do(
                      checkAndSetValidProducerCode,
                      normalizedValue
                    );
                  }
                }
              }}
            />
            {!hasCheckedProducerCode && (
              <div className="form-row">
                <span className="form-error ml-1">Validating code...</span>
              </div>
            )}
            {hasCheckedProducerCode && !validProducerCode && (
              <div className="form-row">
                <div className="col-sm form-group form-error">
                  The Broker Code has already been taken.
                </div>
              </div>
            )}
            <ProducerLogoUpload
              imgSrc={producerFormImgSrc}
              onSelect={onProducerImageSelect}
              onClear={clearProducerLogo}
            />
          </div>
        </>
      ) : (
        <>
          <div className="card-header bg-transparent d-flex justify-content-between">
            <div className="my-auto contacts__cardTitle">
              <strong>Broker Information</strong>
            </div>
          </div>
          <div className="card-body">
            <p className="label">Broker Name</p>
            <p>{producer.name}</p>
            <p className="mb-4" />
            <p className="label">Broker Code</p>
            <p>{producer.commissionId}</p>
            <p className="mb-4" />
            {producer.logoUrl && (
              <>
                <p className="label">Broker Logo</p>
                <div className="d-flex align-items-center justify-content-center py-4 my-3 border">
                  <img src={producer.logoUrl} alt="Broker Logo" />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

ProducerInformation.propTypes = {
  editing: PropTypes.bool.isRequired,
  producer: producerPropType.isRequired,
  validProducerCode: PropTypes.bool.isRequired,
  setValidProducerCode: PropTypes.func.isRequired,
  hasCheckedProducerCode: PropTypes.bool.isRequired,
  setHasCheckedProducerCode: PropTypes.func.isRequired,
  checkAndSetValidProducerCode: PropTypes.func.isRequired,
};

export default ProducerInformation;
