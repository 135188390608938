import { ControlModels } from "@reactables/forms";
import { MemberDetails } from "Features/Members/Rx/Models/memberDetails.model";
import { MemberContactsForm } from "Features/Members/Rx/Models/memberContactsForm.model";
import { getMembershipEmails } from "Features/Members/Rx/RxMemberContactsForm";
import TextField from "@basicare/common/src/Components/FormElements/TextField";

const CustomContactEmailField = ({
  memberDetails,
  contactsFormGroup,
  emailControl,
  index,
  ...props
}: {
  memberDetails: MemberDetails;
  contactsFormGroup: ControlModels.FormControl<MemberContactsForm>;
  emailControl: ControlModels.FormControl<string>;
  index: number;
}) => {
  const filteredCustomContactsEmails = contactsFormGroup.value.customContacts
    .filter((_, i) => i !== index)
    .map(({ contactInfo }) => contactInfo.email);

  const membershipContacts = getMembershipEmails(memberDetails);

  const allEmails = [
    ...filteredCustomContactsEmails,
    ...membershipContacts,
  ].filter((email) => Boolean(email));

  const extendedControl = {
    ...emailControl,
    errors: {
      ...emailControl.errors,
      uniqueCustomContactEmails:
        contactsFormGroup.errors.uniqueCustomContactEmails &&
        allEmails.includes(emailControl.value),
    },
  };

  return (
    <TextField
      {...props}
      meta={extendedControl}
      errorMessages={{
        uniqueCustomContactEmails: "This email already exists for this member",
      }}
    />
  );
};

export default CustomContactEmailField;
