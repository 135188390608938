import { parseAddress } from "@jauntin/utilities";
import { Action } from "@reactables/core";
import { ControlRef, CustomReducers } from "@reactables/forms";

export interface PlacesAutoCompleteActions {
  selectPlace: (payload: google.maps.places.PlaceResult) => void;
  clearAddress: () => void;
}

export const placesAutocompleteReducers = (
  groupRef: ControlRef = []
): CustomReducers<PlacesAutoCompleteActions> => ({
  /**
   * @description fills address fields with autocompleted values
   */
  selectPlace: (
    { updateValues },
    $state,
    { payload }: Action<google.maps.places.PlaceResult>
  ) => {
    const { address_components, formatted_address, types, name } = payload;

    const parsedAddress = parseAddress(
      address_components,
      types,
      formatted_address,
      name,
      ["PR", "VI"]
    );

    if (!parsedAddress) return $state;

    const { address1, zip, city, state } = parsedAddress;

    $state = updateValues($state, {
      controlRef: [...groupRef, "address"],
      value: address1,
    });
    $state = updateValues($state, {
      controlRef: [...groupRef, "city"],
      value: city,
    });
    $state = updateValues($state, {
      controlRef: [...groupRef, "state"],
      value: state,
    });
    $state = updateValues($state, {
      controlRef: [...groupRef, "zip"],
      value: zip,
    });

    return $state;
  },
  clearAddress: ({ updateValues }, $state) => {
    $state = updateValues($state, {
      controlRef: [...groupRef, "address"],
      value: "",
    });
    $state = updateValues($state, {
      controlRef: [...groupRef, "city"],
      value: "",
    });
    $state = updateValues($state, {
      controlRef: [...groupRef, "state"],
      value: "",
    });
    $state = updateValues($state, {
      controlRef: [...groupRef, "zip"],
      value: "",
    });

    return $state;
  },
});
