import { NavLink, useLocation } from "react-router-dom";
import {
  getUrl,
  getMemberId,
  getPageType,
  MEMBERS_PAGE,
  MEMBER_PAGE,
  MEMBERS_NOTES_PAGE,
  CONTACT_PAGE,
} from "../../../Helpers/URLParser";
import { MemberDetails } from "Features/Members/Rx/Models/memberDetails.model";

const MemberBreadcrumb = ({
  member: { subscriberNumber, recuroSubscriberNumber, organization },
}: {
  member: MemberDetails;
}) => {
  const location = useLocation();
  const pageType = getPageType(location.pathname);

  return (
    <div className="breadcrumbs">
      <NavLink to={getUrl(MEMBERS_PAGE)}>Members</NavLink>
      {pageType === MEMBER_PAGE &&
        ` / Member # ${
          recuroSubscriberNumber &&
          Boolean(organization.requiresRecuroSubscriberNumber)
            ? recuroSubscriberNumber
            : subscriberNumber
        }`}
      {pageType !== MEMBER_PAGE && (
        <>
          {" / "}
          <NavLink to={getUrl(MEMBER_PAGE, getMemberId(location.pathname))}>
            {`Member # ${
              recuroSubscriberNumber &&
              Boolean(organization.requiresRecuroSubscriberNumber)
                ? recuroSubscriberNumber
                : subscriberNumber
            }`}
          </NavLink>
          {" / "}
        </>
      )}
      {pageType === CONTACT_PAGE && "Contacts"}
      {pageType === MEMBERS_NOTES_PAGE && "Notes"}
    </div>
  );
};

export default MemberBreadcrumb;
