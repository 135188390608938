import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { ConnectedRouter } from "@jauntin/react-ui";
import "./App.scss";
import AdminContainer from "./Features/Shared/Components/AdminContainer";
import LoginContainer from "./Features/Auth/Components/LoginContainer";
import ScrollToTop from "./Helpers/ScrollToTop";
import {
  getUrl,
  ADMIN_PAGE,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  TWO_FACTOR_PAGE,
} from "./Helpers/URLParser";
import RequestResetPasswordContainer from "./Features/Auth/Components/RequestResetPasswordContainer";
import ResetPasswordContainer from "./Features/Auth/Components/ResetPasswordContainer";
import {
  loginStatus,
  getAppInfo as getAppInfoAction,
} from "./Redux/Actions/actions";
import { ThemeProvider } from "styled-components";
import { BCX_THEME } from "@basicare/common/src/Constants/themes/bcxTheme";
import { DefaultTimezoneProvider } from "@jauntin/react-ui";
import MaintenanceModeMessage from "@basicare/common/src/Components/AppErrorMessage/MaintenanceModeMessage";
import GlobalStyles from "./GlobalStyles";
import TwoFactorContainer from "./Features/Auth/Components/TwoFactorContainer";

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    dispatch(loginStatus());
    dispatch(getAppInfoAction());
  }, [dispatch]);

  const { isLoggedIn, isInMaintenanceMode, timezone } = useSelector(
    ({ app }) => app
  );

  const pushUrl = useSelector((state) => state.router.pushUrl);

  return (
    <>
      {isInMaintenanceMode ? (
        <MaintenanceModeMessage />
      ) : (
        <ConnectedRouter.Router
          history={history}
          location={location}
          dispatch={dispatch}
          pushUrl={pushUrl}
        >
          {timezone && (
            <DefaultTimezoneProvider timezone={timezone}>
              <ThemeProvider theme={BCX_THEME}>
                <GlobalStyles />
                <ScrollToTop>
                  <Switch>
                    <Route
                      path={getUrl(ADMIN_PAGE)}
                      render={({ props }) => (
                        <AdminContainer {...props} isLoggedIn={isLoggedIn} />
                      )}
                    />
                    <Route
                      exact
                      path={getUrl(TWO_FACTOR_PAGE)}
                      render={({ props }) => <TwoFactorContainer {...props} />}
                    />

                    <Route
                      exact
                      path={getUrl(REQUEST_RESET_PASSWORD)}
                      render={({ props }) => (
                        <RequestResetPasswordContainer {...props} />
                      )}
                    />
                    <Route
                      exact
                      path={getUrl(RESET_PASSWORD)}
                      render={({ props }) => (
                        <ResetPasswordContainer {...props} />
                      )}
                    />

                    <Route component={LoginContainer} />
                  </Switch>
                </ScrollToTop>
              </ThemeProvider>
            </DefaultTimezoneProvider>
          )}
        </ConnectedRouter.Router>
      )}
    </>
  );
};

export default App;
