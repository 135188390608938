import { useCallback } from "react";
import { Field } from "@reactables/react-forms";
import { ExtendedMeta, classname } from "@jauntin/utilities";
import {
  getCustomStyles,
  getLgSelectStyle,
} from "@basicare/common/src/Helpers/ReactSelectStyle";
import { PlacesAutocomplete } from "@jauntin/react-ui";
import SelectList from "@basicare/common/src/Components/FormElements/SelectList";
import { UsState } from "../../../../frontend/src/Features/Shared/Models/usState.model";
import TextField from "@basicare/common/src/Components/FormElements/TextField";

const placesAutoCompleteOptions: google.maps.places.AutocompleteOptions = {
  componentRestrictions: { country: "us" },
  fields: ["address_components", "formatted_address", "types", "name"],
  types: ["address"],
};

const AddressFieldGroup = ({
  groupName,
  usStates,
  googleMapsLoaded,
  disabled,
  onSelect,
  onClear,
  addressValue,
  fullWidth = false,
  lgStyle = false,
  showErrors,
}: {
  groupName: string;
  addressValue: string;
  usStates: UsState[];
  googleMapsLoaded: boolean;
  disabled?: boolean;
  onSelect: (place: google.maps.places.PlaceResult) => void;
  onClear: () => void;
  fullWidth?: boolean;
  lgStyle?: boolean;
  showErrors?: (meta: ExtendedMeta) => boolean;
}) => {
  const onPlaceSelect = useCallback(
    (e) => {
      onSelect(e);
      document.getElementById(`${groupName}.address2`).focus();
    },
    [onSelect, groupName]
  );

  return (
    <div className="address-field-group">
      <div className="row">
        {googleMapsLoaded ? (
          <Field
            name={`${groupName}.address`}
            component={PlacesAutocomplete}
            label="Street Address"
            placeholder="Type your address"
            labelClassName="label--required"
            className="col-12"
            inputClassName={classname(lgStyle && "form-control-lg")}
            options={placesAutoCompleteOptions}
            components={{
              inputAppend: (
                <button
                  className={classname(
                    "btn btn-link address-field-group__autocomplete-icon",
                    !addressValue && "cursor-default"
                  )}
                  onClick={() => {
                    addressValue && onClear();
                  }}
                >
                  <i
                    className={classname(
                      "fal",
                      addressValue ? "fa-times" : "fa-search"
                    )}
                  />
                </button>
              ),
            }}
            onSelect={onPlaceSelect}
            showErrors={showErrors}
          />
        ) : (
          <Field
            name={`${groupName}.address`}
            component={TextField}
            label="Street Address"
            placeholder="Type your address"
            wrapperClassName="col-12"
            labelClassName="label--required"
            inputClassName={classname(lgStyle && "form-control-lg")}
            showErrors={showErrors}
          />
        )}
      </div>

      <div className="row">
        <Field
          component={TextField}
          label="Address Line 2 (Suite, Unit, etc.)"
          placeholder="Address Line 2 (Suite, Unit, etc.)"
          name={`${groupName}.address2`}
          wrapperClassName={classname("col-12", fullWidth && "col-lg-5")}
          inputClassName={classname(lgStyle && "form-control-lg")}
          disabled={disabled}
          showErrors={showErrors}
        />
        <Field
          component={TextField}
          name={`${groupName}.city`}
          label="City"
          ariaLabel="City"
          placeholder="City"
          wrapperClassName="col-12 col-lg"
          labelClassName="label--required"
          inputClassName={classname(lgStyle && "form-control-lg")}
          disabled={disabled}
          showErrors={showErrors}
        />
        <div className="col form-group">
          <Field
            name={`${groupName}.state`}
            wrapperClassName="col-12 col-lg"
            component={SelectList}
            label="State"
            placeholder="State"
            labelClassName="label--required"
            options={usStates.map((usState: UsState) => ({
              label: usState.name,
              value: usState.code,
            }))}
            customStyles={lgStyle ? getLgSelectStyle : getCustomStyles}
            showErrors={showErrors}
          />
        </div>
        <Field
          component={TextField}
          name={`${groupName}.zip`}
          wrapperClassName="col-12 col-lg"
          label="Zipcode"
          ariaLabel="Zipcode"
          placeholder="Zip Code"
          pattern="\d*"
          inputMode="numeric"
          disabled={disabled}
          labelClassName="label--required"
          inputClassName={classname(lgStyle && "form-control-lg")}
          showErrors={showErrors}
        />
      </div>
    </div>
  );
};

export default AddressFieldGroup;
