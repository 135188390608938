import TextField from "@basicare/common/src/Components/FormElements/TextField";
import StyledIntegerInput from "@basicare/common/src/Components/FormElements/IntegerNumberInput/StyledIntegerNumberInput";
import { PromoCodeForm } from "@basicare/common/src/Models/promoCode.model";
import { DiscountTypes } from "@basicare/common/src/Constants/discountTypes";
import { ControlModels } from "@reactables/forms";
import { plans } from "@basicare/common/src/Constants/planTypes";
import { currencyFromInt } from "@basicare/common/src/Helpers/CurrencyHelper";

const DiscountAmountField = ({
  rootControl,
  discountAmountControl,
  ...props
}: {
  rootControl: ControlModels.FormControl<PromoCodeForm>;
  discountAmountControl: ControlModels.FormControl<string>;
}) => {
  const { subscriptionInterval, discountType } = rootControl.value;

  const { promoCodeDiscountDollarAmount, discountAmountRequired } =
    rootControl.errors;

  const extendedControl = {
    ...discountAmountControl,
    errors: {
      ...discountAmountControl.errors,
      promoCodeDiscountDollarAmount,
      discountAmountRequired,
    },
  };

  const maxDiscount =
    (plans.find((plan) => plan.planType === subscriptionInterval)?.price || 0) -
    100;

  const errorMessages = {
    promoCodeDiscountDollarAmount: `The discount can not be greater than ${currencyFromInt(
      maxDiscount
    ).formatDollars()} for ${subscriptionInterval} subscription`,
    discountAmountRequired: "Required",
  };

  if (discountType === DiscountTypes.Percent) {
    return (
      <StyledIntegerInput
        {...props}
        meta={extendedControl}
        errorMessages={errorMessages}
        min={0}
        max={60}
      />
    );
  }

  return (
    <TextField
      {...props}
      meta={extendedControl}
      errorMessages={errorMessages}
    />
  );
};

export default DiscountAmountField;
