export const minFacilityCodeLength = 3;
export const maxFacilityCodeLength = 6;

export const minVenueCodeLength = 3;
export const maxVenueCodeLength = 5;

export const producerCodeLength = 3;
export const maxSubProducerCodeLength = 10;

export const FACILITY_CODE_REGEX = new RegExp(
  `^[a-zA-Z0-9]{${minFacilityCodeLength},${maxFacilityCodeLength}}$`,
  "i"
);

export const VENUE_PARAM_REGEX = new RegExp(
  FACILITY_CODE_REGEX.source.replace("$", "") +
    `-[0-9]{${minVenueCodeLength},${maxVenueCodeLength}}$`,
  "i"
);

export const PRODUCER_CODE_REGEX = /^[a-zA-Z0-9]{1,6}(?:-[a-zA-Z0-9]{1,10})?$/i;
