import { RxMemberProp } from "Features/Members/Rx/RxMember";
import { NotesContainer } from "@jauntin/react-ui";
import { useNotePublisher } from "Features/Shared/Hooks/useNotePublisher";

const MemberNotes = ({ rxMember }: { rxMember: RxMemberProp }) => {
  const [state, actions, , actions$] = rxMember;

  const { id, notes } = state.entity.data;
  const { addNote } = state;

  const publish = useNotePublisher({ actions$, publish: actions.addNote.send });

  return (
    <NotesContainer
      publish={publish}
      entityId={id}
      notes={notes.map(({ id, message, type, user, createdAt }) => ({
        id,
        message,
        type,
        userName: user?.name,
        created: createdAt,
      }))}
      publishing={addNote.loading}
    />
  );
};

export default MemberNotes;
