import { RequestActions, RequestState, RxRequest } from "@jauntin/reactables";
import { map } from "rxjs/operators";
import { combineLatest, from, merge, Observable, of } from "rxjs";
import { ofTypes, Reactable } from "@reactables/core";
import SettingService from "Services/SettingService";

export interface SettingFormPayload {
  date: string;
}

export interface DateResponse {
  data: {
    date: string;
  };
}

const RxSaveDate = ({ settingService }: { settingService: SettingService }) =>
  RxRequest<SettingFormPayload, unknown>({
    name: "rxSaveDate",
    effect: (action$) => {
      return action$.pipe(
        map(({ payload }) => from(settingService.setDateForTravel(payload)))
      );
    },
  });

const RxGetDate = ({
  settingService,
  sources,
}: {
  settingService: SettingService;
  sources: Observable<{ type: string }>[];
}) =>
  RxRequest<unknown, string>({
    name: "rxGetDate",
    effect: (action$) => {
      return action$.pipe(
        map(
          () =>
            from(settingService.getDateForTravel()).pipe(
              map(({ data: { date } }) => date)
            ) as Observable<string>
        )
      );
    },
    sources: sources,
  });

const RxResetDate = ({ settingService }: { settingService: SettingService }) =>
  RxRequest<unknown, unknown>({
    name: "rxResetDate",
    effect: (action$) => {
      return action$.pipe(
        map(({ payload }) => from(settingService.resetDateForTravel()))
      );
    },
  });

export interface TimeTravelRequestsState {
  resetDate: RequestState<unknown>;
  saveDate: RequestState<unknown>;
  getDate: RequestState<string>;
}

export interface TimeTravelRequestsActions {
  resetDate: RequestActions<unknown>;
  saveDate: RequestActions<unknown>;
}

export const RxTimeTravelRequests = ({
  settingService,
}: {
  settingService: SettingService;
}) => {
  const [saveDate$, saveDateActions, saveDateActions$] = RxSaveDate({
    settingService,
  });
  const [resetDate$, resetDateActions, resetDateActions$] = RxResetDate({
    settingService,
  });
  const getDateOnChange$ = merge(saveDateActions$, resetDateActions$).pipe(
    ofTypes(["sendSuccess"]),
    map(() => ({ type: "send" }))
  );
  const getDateOnLoad$ = of({ type: "send" });
  const [getDate$] = RxGetDate({
    settingService,
    sources: [getDateOnChange$, getDateOnLoad$],
  });
  const state$ = combineLatest({
    resetDate: resetDate$,
    saveDate: saveDate$,
    getDate: getDate$,
  });
  const actions = {
    resetDate: resetDateActions,
    saveDate: saveDateActions,
  };

  return [state$, actions] as Reactable<
    TimeTravelRequestsState,
    TimeTravelRequestsActions
  >;
};
