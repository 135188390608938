import React, { useState } from "react";
import { sendReportByType } from "../../../Helpers/Reports";
import { AsyncButton } from "@jauntin/react-ui";
import ReportTypeField from "./FormElements/ReportTypeField";
import { required } from "../../../Helpers/validators";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { sendReportsForm } from "../../../constants";

const DailyMembershipReport = () => {
  const [fetching, setFetching] = useState(false);
  const [type, setType] = useState("ScriptClaimMembershipReport");

  return (
    <div>
      <strong>Sending Reports</strong>
      <p>Sends file drop of the selected type to storage</p>
      <div className="row">
        <div className="col-md-6">
          <div className="mt-3">
            <Field
              name="type"
              component={ReportTypeField}
              validate={[required]}
              onChange={(e) => setType(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="mt-2">
            <AsyncButton
              className="btn btn-primary py-2 border-radius-0"
              disabled={fetching}
              spinning={fetching}
              spinningText="Sending..."
              onClick={() => {
                setFetching(true);
                sendReportByType(type)
                  .then(() => setFetching(false))
                  .catch((e) => {
                    console.error(e);
                    setFetching(false);
                  });
              }}
            >
              Send Report
            </AsyncButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  connect(),
  reduxForm({
    form: sendReportsForm,
  })
)(DailyMembershipReport);
