import { ControlModels } from "@reactables/forms";
import { CustomContact, MemberDetails } from "./memberDetails.model";
import { MemberEmailsPayload } from "./memberEmailsPayload.model";
export interface MemberContactsForm {
  emailPrimaryMember: boolean;
  emailOrganizationContacts: boolean[];
  emailOrganizationProducerContacts: boolean[];
  customContacts: {
    sendEmail: boolean;
    contactInfo: CustomContact;
  }[];
}

export const getContactsAndEmailPayload = ({
  memberDetails,
  form,
}: {
  form: ControlModels.Form<MemberContactsForm>;
  memberDetails: MemberDetails;
}) => {
  const {
    id,
    organization: { contacts, facilityProducerContacts },
  } = memberDetails;
  const {
    customContacts,
    emailPrimaryMember,
    emailOrganizationContacts,
    emailOrganizationProducerContacts,
  } = form.root.value;

  const customContactsPayload = customContacts.map(({ contactInfo }) =>
    Object.entries(contactInfo).reduce((acc, [key, value]) => {
      if (key === "id" && value === null) return acc;

      return {
        ...acc,
        [key]: value,
      };
    }, {})
  ) as CustomContact[];

  const emailPayload: MemberEmailsPayload = {
    id,
    mainRecipients: emailPrimaryMember ? [memberDetails.email] : [],
    bccRecipients: [
      ...contacts
        .filter(
          ({ copyOnEmails }, index) =>
            emailOrganizationContacts[index] && copyOnEmails
        )
        .map(({ email }) => email),
      ...facilityProducerContacts
        .filter((_, index) => emailOrganizationProducerContacts[index])
        .map(({ email }) => email),
      ...customContacts
        .filter(({ sendEmail }) => sendEmail)
        .map(({ contactInfo: { email } }) => email),
    ],
  };

  return {
    emails: emailPayload,
    customContacts: customContactsPayload,
  };
};
