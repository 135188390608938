import { ControlModels, ControlRef } from "@reactables/forms";
export const hasControlTouchError = <T>(
  form: ControlModels.Form<T>,
  groupName: string = "root"
) => {
  const groupRef: ControlRef = groupName.split(".");
  const groupHasVisibleError = Object.values(form).some((control) => {
    const isDescendentFormControl =
      (groupName === "root" ||
        groupRef.every((key, index) => key === control.controlRef[index])) &&
      // If it does not have child controls, it is a FormControl (leaf in the form tree)
      !control.config.controls;

    return isDescendentFormControl && control.touched && !control.valid;
  });

  return groupHasVisibleError;
};
