import { Reactable } from "@reactables/core";
import { RequestState, RequestActions, RxEntity } from "@jauntin/reactables";
import {
  PromoCodeDetails,
  PromoCodePayload,
} from "@basicare/common/src/Models/promoCode.model";
import PromoCodeService from "Services/PromoCodeService";
import { HookedReactable } from "@reactables/react";

export interface PromoCodeState {
  entity: RequestState<PromoCodeDetails>;
  editPromoCode: RequestState<unknown>;
  addNote: RequestState<unknown>;
}

export interface PromoCodeActions {
  editPromoCode: RequestActions<PromoCodePayload>;
  addNote: RequestActions<{ id: number; message: string }>;
}

export type PromoCodeProp = HookedReactable<PromoCodeState, PromoCodeActions>;

export const RxPromoCode = ({
  promoCodeService,
  id,
  onEditSuccess,
}: {
  promoCodeService: PromoCodeService;
  id: number;
  onEditSuccess?: () => void;
}): Reactable<PromoCodeState, PromoCodeActions> =>
  RxEntity({
    name: "PromoCode",
    getEntityResource: () =>
      promoCodeService
        .getPromoCode(id)
        .then(({ data }) => data) as Promise<PromoCodeDetails>,
    entityActions: {
      editPromoCode: {
        resource: (payload: PromoCodePayload) =>
          promoCodeService.putPromoCodeEditDetails(payload, id),
        onSuccess: onEditSuccess,
      },
      addNote: {
        resource: (note: { id: number; message: string }) =>
          promoCodeService.postAddNote(note),
      },
    },
  });
