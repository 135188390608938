import { Reactable } from "@reactables/core";
import { HookedReactable } from "@reactables/react";
import { RxEntity, RequestState, RequestActions } from "@jauntin/reactables";
import FacilityService from "Services/FacilityService";
import { Facility, SaveFacilityPayload } from "../Models/facility.model";

export interface FacilityState {
  entity: RequestState<Facility>;
  updateFacility: RequestState<unknown>;
  addNote: RequestState<unknown>;
}

export interface FacilityActions {
  getEntity: (id: number) => void;
  updateFacility: RequestActions<SaveFacilityPayload>;
  addNote: RequestActions<unknown>;
}

export type FacilityProp = HookedReactable<FacilityState, FacilityActions>;

export const RxFacility = ({
  facilityId,
  facilityService,
  onSaveFacilitySuccess,
}: {
  facilityId: number;
  facilityService: FacilityService;
  onSaveFacilitySuccess?: (result) => void;
}): Reactable<FacilityState, FacilityActions> =>
  RxEntity({
    name: "Facility",
    getEntityResource: () =>
      facilityService
        .getFacility(facilityId)
        .then(({ data }) => data) as Promise<Facility>,
    entityActions: {
      updateFacility: {
        resource: (data: SaveFacilityPayload) =>
          facilityService.putFacilityEditDetails(data),
        onSuccess: onSaveFacilitySuccess,
      },
      addNote: {
        resource: (data) => facilityService.postAddNote(data),
      },
    },
  });
