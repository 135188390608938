import { group, control } from "@reactables/forms";
import { Dependent, emptyDependent } from "../../Models/dependent.model";
import { person } from "./person.config";

export const dependent = ({
  id,
  relationshipToAccountHolder,
  ...personInfo
}: Dependent = emptyDependent) =>
  group({
    validators: ["dependentDateOfBirth"],
    controls: {
      id: control([id]),
      relationshipToAccountHolder: control([
        relationshipToAccountHolder,
        "required",
      ]),
      ...person(personInfo).controls,
    },
  });
