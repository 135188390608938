import { useReactable } from "@reactables/react";
import { Switch, Route, useParams, useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { RxFacility } from "../Rx/RxFacility";
import FacilityService from "Services/FacilityService";
import API from "Services/API";
import {
  EDIT_FACILITY_PAGE,
  FACILITY_NOTES_PAGE,
  FACILITY_PAGE,
  FACILITIES_PAGE,
  getUrl,
} from "Helpers/URLParser";
import FacilityView from "./FacilityView";
import EditFacility from "./EditFacility";
import FacilityNotes from "./FacilityNotes";
import { NavLink } from "react-router-dom";
import { dateHelpers } from "@jauntin/utilities";

const Facility = () => {
  const history = useHistory();
  const { id } = useParams() as { id: string };

  const rxFacility = useReactable(RxFacility, {
    facilityId: parseInt(id),
    facilityService: new FacilityService(new API()),
    onSaveFacilitySuccess: () => {
      history.push(getUrl(FACILITY_PAGE, id));
    },
  });

  const [state] = rxFacility;

  if (!state) return;

  const {
    entity: { loading, data: facility },
  } = state;

  if (loading && !facility)
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        <Spinner animation="border" role="status" />
        <span className="ml-3">Loading Organization...</span>
      </div>
    );

  return (
    <>
      <div className="subsection__wrapper">
        <div className="subsection-menu">
          <div className="subsection-menu__header">
            <div className="breadcrumbs">
              <NavLink to={getUrl(FACILITIES_PAGE)}>Organizations</NavLink> /
              Organization #{facility.code}
            </div>
            <h3 className="subsection-menu__title">{`${facility.name}`}</h3>
            <p className="subsection-menu__id">{`${facility.code}`}</p>
            <p className="subsection-menu__date">
              {`Created on ${dateHelpers.lettersAndNumbers(
                facility.createdAt
              )}`}
            </p>
          </div>
          <div className="subsection-menu__items">
            <NavLink
              to={getUrl(FACILITY_PAGE, facility.id)}
              className="subsection-menu__item"
              activeClassName="active"
            >
              Organization Overview
            </NavLink>
            <NavLink
              to={getUrl(FACILITY_NOTES_PAGE, facility.id)}
              className="subsection-menu__item"
              activeClassName="active"
            >
              Organization Notes
            </NavLink>
          </div>
        </div>
        <div className="subsection__container">
          <div>
            <Switch>
              <Route path={getUrl(FACILITY_PAGE)}>
                <FacilityView facility={facility} />
              </Route>
              <Route path={getUrl(EDIT_FACILITY_PAGE)}>
                <EditFacility rxFacility={rxFacility} />
              </Route>
              <Route path={getUrl(FACILITY_NOTES_PAGE)}>
                <FacilityNotes rxFacility={rxFacility} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default Facility;
