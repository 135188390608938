import { ControlModels, getArrayItems } from "@reactables/forms";
import { Dependent } from "../../Models/dependent.model";

export const getHasVisibleDependentDobError = ({
  form,
}: {
  form: ControlModels.Form<{ dependents: Dependent[] }>;
}) => {
  const dependentControls = getArrayItems(
    ["dependents"],
    form
  ) as ControlModels.FormControl<Dependent>[];

  const hasVisibleDependentDobError = dependentControls.some(
    (dependentControl, index) => {
      return (
        dependentControl.errors.dependentDateOfBirth &&
        form[`dependents.${index}.dateOfBirth`].touched
      );
    }
  );

  return hasVisibleDependentDobError;
};
