import { Link } from "react-router-dom";
import { Button, ContactCards } from "@jauntin/react-ui";
import { RxToggle } from "@jauntin/reactables";
import { useReactable } from "@reactables/react";
import { DEFAULT_FACILITY_CODE } from "../../../constants";
import { getUrl, EDIT_FACILITY_PAGE } from "Helpers/URLParser";
import ModalUpload from "./ModalUpload";
import { Facility } from "../Models/facility.model";
import FacilityStatus from "./FacilityStatus";

const FacilityView = ({ facility }: { facility: Facility }) => {
  const [showUpload, showUploadActions] = useReactable(RxToggle);

  return (
    <>
      <div className="content__header col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Organization Overview</h4>
          <div className="d-flex float-right">
            <>
              <Button
                text="Upload members by XLSX"
                onClick={showUploadActions.toggleOn}
                className="btn btn-outline-secondary px-4 ml-2 text-nowrap"
              />
              {facility.code !== DEFAULT_FACILITY_CODE && (
                <Link to={getUrl(EDIT_FACILITY_PAGE, facility.id)}>
                  <Button
                    text="Edit Organization"
                    className="btn btn-primary px-4 mx-2 text-nowrap"
                    onClick={() => {}}
                  />
                </Link>
              )}
            </>
          </div>
        </div>
      </div>
      <div className="content__body">
        <FacilityStatus facility={facility} />
        <div className="card w-100">
          <div className="card-header bg-transparent d-flex justify-content-between">
            <div className="my-auto contacts__cardTitle">
              <strong>Organization Information</strong>
            </div>
          </div>
          <div className="card-body">
            <p className="label">Organization Code</p>
            <p className="mb-4">{facility.code}</p>
            <p className="label">Organization Name</p>
            <p className="mb-4">{facility.name}</p>
            <p className="label">Monthly Price</p>
            <p className="mb-4">{facility.monthlyPrice}</p>

            <p className="label">Product Code</p>
            <p className="mb-4">{facility.productCode}</p>

            {facility.recuroAgentId && (
              <>
                <p className="label">Recuro Agent ID</p>
                <p className="mb-4">{facility.recuroAgentId}</p>
              </>
            )}

            <p className="label">Elixir Group ID</p>
            <p className="mb-4">{facility.elixirGroupId}</p>

            <p className="label">Recuro Group ID</p>
            <p className="mb-4">{facility.recuroGroupId}</p>

            {Boolean(facility.requiresRecuroSubscriberNumber) && (
              <>
                <p className="mb-4">Requires Recuro Subscriber Number</p>
              </>
            )}

            {Boolean(facility.doNotSendEmailsToMember) && (
              <>
                <p className="mb-4">Emails are not sent to the member</p>
              </>
            )}
            {(() => {
              const { additionalInsuredAddress } = facility;

              if (!additionalInsuredAddress) return <></>;

              return (
                <p>
                  <span className="d-flex">
                    {`${additionalInsuredAddress.address1}${
                      additionalInsuredAddress.address1 &&
                      additionalInsuredAddress.address2
                        ? `, ${additionalInsuredAddress.address2}`
                        : ""
                    }`}
                  </span>
                  <span className="d-flex">
                    {`${additionalInsuredAddress.city}${
                      additionalInsuredAddress.city &&
                      additionalInsuredAddress.state
                        ? `,`
                        : ""
                    } ${additionalInsuredAddress.state} ${
                      additionalInsuredAddress.zip
                    }`}
                  </span>
                </p>
              );
            })()}

            {facility.logoUrl && (
              <div className="mt-3">
                <p className="label">Organization Logo</p>
                <div className="d-flex align-items-center justify-content-center py-4 my-3 border">
                  <img src={facility.logoUrl} alt="Organization Logo" />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="card w-100 mt-4">
          <div className="card-header bg-transparent d-flex justify-content-between">
            <div className="my-auto contacts__cardTitle">
              <strong>Broker</strong>
            </div>
          </div>
          <div className="card-body">
            <p className="mb-4">
              <span className="d-flex">{facility.producer.name}</span>
              <span>{facility.producer.commissionId}</span>
            </p>

            {facility.facilityProducerContacts.length > 0 && (
              <div className="mb-4">
                <p className="label">
                  Organization contact copied on transactions:
                </p>
                <p>
                  {facility.facilityProducerContacts.map((contact) => (
                    <span key={contact.id} className="d-flex">
                      {contact.fullName && (
                        <span className="mr-3">{contact.fullName}</span>
                      )}
                      <span>{contact.email}</span>
                    </span>
                  ))}
                </p>
              </div>
            )}
          </div>
        </div>

        {(() => {
          const { invoiceContact } = facility;

          if (!invoiceContact) return <></>;

          const { name, address1, address2, city, state, zip } = invoiceContact;

          if (
            ![name, address1, address2, city, state, zip].some((value) => value)
          )
            return <></>;

          return (
            <div className="card w-100 mt-4">
              <div className="card-header bg-transparent d-flex justify-content-between">
                <div className="my-auto contacts__cardTitle">
                  <strong>Invoice Contact</strong>
                </div>
              </div>
              <div className="card-body">
                <p>
                  {name && (
                    <>
                      <span>{name}</span>
                      <br />
                    </>
                  )}
                  <span className="d-flex">
                    {`${address1}${
                      address1 && address2 ? `, ${address2}` : ""
                    }`}
                  </span>
                  <span className="d-flex">
                    {`${city}${city && state ? `,` : ""} ${state} ${zip}`}
                  </span>
                </p>
              </div>
            </div>
          );
        })()}

        <ContactCards
          contacts={facility.contacts}
          cardTitle="Organization Contact"
          className="py-3"
          cardClassName="w-100 mt-4"
          headerClassName="bg-transparent font-weight-bold"
          copyOnEmailsLabel="Copied on email containing member information"
        />
      </div>
      <ModalUpload
        show={showUpload}
        handleClose={showUploadActions.toggleOff}
        facilityId={facility.id}
      />
    </>
  );
};

export default FacilityView;
