import { Person, emptyPerson } from "./person.model";
import { RelationshipType } from "../Constants/dependents";

export interface Dependent extends Person {
  id?: number;
  relationshipToAccountHolder: RelationshipType | "";
}

export const emptyDependent: Dependent = {
  relationshipToAccountHolder: "",
  ...emptyPerson,
};
