import { MemberDetails } from "../Models/memberDetails.model";
import { group, control, array } from "@reactables/forms";
import { CustomContact } from "Features/Members/Rx/Models/memberDetails.model";

export const memberContacts = ({
  organization: { contacts, facilityProducerContacts },
  contacts: customContacts,
}: MemberDetails) =>
  group({
    validators: ["uniqueCustomContactEmails", "emailSelected"],
    controls: {
      emailPrimaryMember: control([true]),
      emailOrganizationProducerContacts: array({
        controls: facilityProducerContacts.map(() => control([true])),
      }),
      emailOrganizationContacts: array({
        controls: contacts
          .filter(({ copyOnEmails }) => copyOnEmails)
          .map(() => control([true])),
      }),
      customContacts: array({
        controls: customContacts.map((contact) => customContact(contact)),
      }),
    },
  });

export const customContact = ({ id, fullName, email }: CustomContact) =>
  group({
    controls: {
      sendEmail: control([true]),
      contactInfo: group({
        controls: {
          id: control([id]),
          fullName: control([fullName]),
          email: control([email, ["required", "email"]]),
        },
      }),
    },
  });
