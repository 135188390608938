import { ControlModels } from "@reactables/forms";
import TextField from "./TextField";

const RecuroSubscriberNumberField = ({
  recuroSubNumControl,
  memberFormGroup,
  ...props
}) => {
  const extendedRecuroSubNumControl: ControlModels.FormControl<string> = {
    ...recuroSubNumControl,
    errors: {
      ...recuroSubNumControl.errors,
      recuroNumRequiredForOrg: memberFormGroup.errors.recuroNumRequiredForOrg,
    },
  };

  const { validatorErrors, pending, value } = extendedRecuroSubNumControl;

  const isValidating =
    value && Object.values(validatorErrors).every((error) => !error) && pending;

  return (
    <TextField
      {...props}
      meta={extendedRecuroSubNumControl}
      label={
        <>
          Recuro Subscriber Number
          {isValidating && <span className="ml-2 small">Validating...</span>}
        </>
      }
      errorMessages={{
        recuroNumRequiredForOrg: "Required for selected organization",
        digits9orAlphaNum11: "Invalid Recuro Subscriber Number",
        uniqueRecuroNumber:
          "The Recuro Subscriber Number has already been taken.",
      }}
    />
  );
};

export default RecuroSubscriberNumberField;
