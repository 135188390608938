import { Action, Reactable, ofTypes } from "@reactables/core";
import { from, combineLatest, of, Observable } from "rxjs";
import { debounceTime, map, filter, mergeMap } from "rxjs/operators";
import {
  FormBuilders,
  asyncValidators,
  RxRequest,
  RequestState,
  Reducers,
  Configs,
} from "@jauntin/reactables";
import {
  group,
  control,
  array,
  ControlModels,
  RxFormActions,
} from "@reactables/forms";
import {
  Facility,
  FacilityForm,
  facilityToForm,
  emptyFacilityForm,
} from "../Models/facility.model";
import formProviders from "@basicare/common/src/Helpers/formProviders";
import { ProducerTypeaheadItem } from "@basicare/common/src/Models/producer.model";
import FacilityService from "Services/FacilityService";
import { fileToBase64 } from "@basicare/common/src/Helpers/Base64EncodeFile";

const emailProducerContactsConfig = (contacts) =>
  array({
    controls: contacts.map(({ id, email, fullName, copyOnEmails }) =>
      group({
        controls: {
          id: control([id, "required"]),
          email: control([email, "required"]),
          fullName: control([fullName]),
          copyOnEmails: control([copyOnEmails]),
        },
      })
    ),
  });

export const facilityContactConfig = (
  {
    id,
    fullName,
    role,
    email,
    copyOnEmails,
  }: {
    id?: number;
    fullName: string;
    role: string;
    email: string;
    copyOnEmails: boolean;
  } = {
    fullName: "",
    role: "",
    email: "",
    copyOnEmails: false,
  }
) =>
  group({
    controls: {
      id: control([id]),
      fullName: control([fullName]),
      role: control([role]),
      email: control([email, ["required", "email"]]),
      copyOnEmails: control([copyOnEmails]),
    },
  });

const facilityConfig = ({
  id,
  status,
  code,
  name,
  monthlyPrice,
  productCode,
  recuroAgentId,
  elixirGroupId,
  recuroGroupId,
  requiresRecuroSubscriberNumber,
  doNotSendEmailsToMember,
  otherInsured,
  invoiceContact,
  logoUrl,
  producer,
  emailProducerContacts,
  contacts,
}: FacilityForm = emptyFacilityForm) =>
  group({
    controls: {
      id: control([id]),
      status: control([status]),
      code: control({
        initialValue: code,
        validators: ["required", "facilityCode"],
        asyncValidators: ["uniqueFacilityCode"],
        normalizers: ["normalizeFacilityCode"],
      }),
      name: control([name, "required"]),
      monthlyPrice: control({
        initialValue: monthlyPrice,
        validators: ["required", "priceFormat"],
        normalizers: ["normalizePrice"],
      }),
      productCode: control([productCode, "required"]),
      recuroAgentId: control({
        initialValue: recuroAgentId,
        validators: ["alphaNumeric"],
        normalizers: ["alphaNumeric"],
      }),
      elixirGroupId: control([elixirGroupId, "required"]),
      recuroGroupId: control({
        initialValue: recuroGroupId,
        validators: ["required", "alphaNumeric", "maxLength20"],
        normalizers: ["recuroGroupId", "maxLength20"],
      }),
      requiresRecuroSubscriberNumber: control([requiresRecuroSubscriberNumber]),
      doNotSendEmailsToMember: control([doNotSendEmailsToMember]),
      otherInsured: group({
        controls: {
          id: control([otherInsured.id]),
          ...Configs.address2Group(otherInsured, false).controls,
          zip: control({
            initialValue: otherInsured.zip,
            validators: ["zipCodePlus4"],
            normalizers: ["normalizeZipPlus4"],
          }),
        },
      }),
      invoiceContact: group({
        controls: {
          id: control([invoiceContact.id]),
          name: control([invoiceContact.name]),
          ...Configs.address2Group(invoiceContact, false).controls,
          zip: control({
            initialValue: invoiceContact.zip,
            validators: ["zipCodePlus4"],
            normalizers: ["normalizeZipPlus4"],
          }),
        },
      }),
      logoImageFile: control([null]),
      logoUrl: control([logoUrl]),
      producer: control([producer, "required"]),
      emailProducerContacts: emailProducerContactsConfig(emailProducerContacts),
      contacts: array({
        validators: ["uniqueContactEmails"],
        controls: contacts.map((contact) => facilityContactConfig(contact)),
      }),
    },
  });

const RxProducerTypeahead = ({
  facilityService,
  initialState = { ...Reducers.loadableInitialState, data: [] },
  sources,
}: {
  facilityService: FacilityService;
  initialState?: Reducers.LoadableState<ProducerTypeaheadItem[]>;
  sources?: Observable<Action<unknown>>[];
}) => {
  return RxRequest<string, ProducerTypeaheadItem[]>({
    name: "rxProducerTypeahead",
    initialState,
    sources: [of({ type: "send", payload: "" }), ...sources],
    effect: (action$: Observable<Action<string>>) =>
      action$.pipe(
        debounceTime(500),
        map(({ payload }) => {
          return from(facilityService.getProducersForFacility(payload)).pipe(
            map(({ data: { data } }) => data)
          ) as Observable<ProducerTypeaheadItem[]>;
        })
      ),
  });
};

interface FacilityFormState {
  form: ControlModels.Form<FacilityForm>;
  typeahead: RequestState<ProducerTypeaheadItem[]>;
}

type FacilityFormActions = {
  typeahead: { search: (search: string) => void };
  form: RxFormActions;
};

export const RxFacilityForm = ({
  facility,
  facilityService,
}: {
  facility?: Facility;
  facilityService: FacilityService;
}): Reactable<FacilityFormState, FacilityFormActions> => {
  const [formState$, formActions, formActions$] = FormBuilders.build(
    facilityConfig(facility ? facilityToForm(facility) : undefined),
    {
      reducers: {
        selectProducer: (
          { updateValues, removeControl, addControl },
          state,
          { payload: producer }: Action<ProducerTypeaheadItem>
        ) => {
          state = updateValues(state, {
            controlRef: ["producer"],
            value: producer,
          });

          state = removeControl(state, ["emailProducerContacts"]);

          const contacts = producer
            ? producer.contacts.map(({ id, fullName, email }) => ({
                id: id,
                copyOnEmails: false,
                email,
                fullName,
              }))
            : [];

          state = addControl(state, {
            controlRef: ["emailProducerContacts"],
            config: emailProducerContactsConfig(contacts),
          });

          return state;
        },
        selectLogo: {
          reducer: (_, state) => state,
          effects: [
            (selectLogo$: Observable<Action<File>>) =>
              selectLogo$.pipe(
                mergeMap(({ payload: file }) => {
                  return from(fileToBase64(file)).pipe(
                    map((content) => ({
                      type: "imageFileConverted",
                      payload: {
                        content,
                        fileName: file.name,
                        size: file.size,
                      },
                    }))
                  );
                })
              ),
          ],
        },
        imageFileConverted: ({ updateValues }, state, { payload }) => {
          state = updateValues(state, {
            controlRef: ["logoImageFile"],
            value: payload,
          });
          return state;
        },
        clearLogo: ({ updateValues }, state) => {
          state = updateValues(state, { controlRef: ["logoUrl"], value: null });
          state = updateValues(state, {
            controlRef: ["logoImageFile"],
            value: null,
          });

          return state;
        },
      },
      providers: {
        ...formProviders,
        asyncValidators: asyncValidators([
          {
            name: "uniqueFacilityCode",
            resource: (value) =>
              from(facilityService.getIsValidFacilityCode(value)).pipe(
                map(({ data }: { data: { exists: boolean } }) => !data.exists)
              ),
          },
        ]),
      },
    }
  ) as Reactable<ControlModels.Form<FacilityForm>, RxFormActions>;

  const producerClearedFetchTypeahead$ = formActions$.pipe(
    ofTypes(["selectProducer"]),
    filter(({ payload }: Action<ProducerTypeaheadItem>) => !payload),
    map(() => ({ type: "send", payload: "" }))
  );

  const [typeaheadState$, { send: search }] = RxProducerTypeahead({
    facilityService,
    sources: [producerClearedFetchTypeahead$],
  });

  const state$ = combineLatest({
    form: formState$,
    typeahead: typeaheadState$,
  });

  const actions = { typeahead: { search }, form: formActions };

  return [state$, actions];
};
