/**
 *
 * Reducers related to data on the Contacts page.
 *
 */
import {
  SET_USERS_LOADING,
  UPDATE_USERS_TABLE,
  RESET_USERS,
  USER_SAVE_SUCCESS,
  SET_USER_ROLE_SELECTION_VISIBLE,
  SET_DELETE_USER_ERROR,
} from "../Actions/actions";

import { convertUserFromServerToStore } from "../../Helpers/UserModel";

const initialState = () => ({
  usersTableData: [],
  usersPagination: {
    count: 0,
    page: 1,
    total: 0,
    totalPages: 0,
    perPage: 10,
  },
  newUser: null,
  roleSelectionVisible: false,
  usersLoading: false,
  deleteUserError: null,
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case SET_USERS_LOADING:
      return { ...state, usersLoading: action.payload.isLoading };
    case UPDATE_USERS_TABLE:
      return {
        ...state,
        usersTableData: action.payload.data.map((user) =>
          convertUserFromServerToStore(user)
        ),
        usersPagination: {
          count: action.payload.count,
          page: action.payload.page,
          total: action.payload.total,
          totalPages: action.payload.totalPages,
          perPage: action.payload.perPage,
        },
        usersLoading: false,
      };
    case USER_SAVE_SUCCESS:
      return {
        ...state,
        newUser: action.payload,
        roleSelectionVisible: false,
      };
    case SET_USER_ROLE_SELECTION_VISIBLE:
      return { ...state, roleSelectionVisible: action.payload };
    case SET_DELETE_USER_ERROR:
      return { ...state, deleteUserError: action.payload };
    case RESET_USERS:
      return initialState();
    default:
      return state;
  }
};
