import PropTypes from "prop-types";
import { clone } from "lodash";
import notePropType from "./NoteModel";

/**
 *
 * Model used to represent a `Broker`. This file includes
 *
 * 1. An empty model with default strings and values
 * 2. Prop Types for the model
 * 3. A mapper that takes the object provided by the server and converts it to this model
 *
 */

/**
 *
 * EMPTY OBJECTS - use to prefill. For example initial state
 *
 */
const emptyProducer = {
  id: 0,
  name: "",
  commissionId: "",
  created: "",
  updated: "",
  status: "",
  facilities: 0,
  referralLink: "",
  producerContacts: [],
  notes: [],
  logoUrl: null,
};

/**
 *
 * PROP TYPES
 *
 */
const producerNotesPropType = notePropType;
const producerContactsPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  copyOnEmails: PropTypes.bool.isRequired,
});
const producerPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  commissionId: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  facilities: PropTypes.number.isRequired,
  referralLink: PropTypes.string.isRequired,
  producerContacts: PropTypes.arrayOf(producerContactsPropType),
  notes: PropTypes.arrayOf(producerNotesPropType),
  logoUrl: PropTypes.string,
});

/**
 *
 * MAPPER - MAP from retrieved data to model
 *
 */
const convertServerProducerToProducerModel = (producer) => {
  const model = clone(emptyProducer);
  model.id = producer.id;
  model.name = producer.name;
  model.commissionId = producer.commissionId;
  model.createdAt = producer.createdAt;
  model.updatedAt = producer.updatedAt;
  model.facilities = producer.facilities.length;
  model.status = producer.status;
  model.logoUrl = producer.logoUrl;

  model.producerContacts = producer.contacts.map((m) => ({
    id: m.id,
    fullName: m.fullName || "",
    email: m.email,
    copyOnEmails: m.copyOnEmails,
  }));

  model.notes = producer.notes.map((m) => ({
    id: m.id,
    type: m.type,
    message: m.message,
    userName: m.user?.name || "",
    createdAt: m.createdAt,
  }));

  model.referralLink = producer.links?.producerReferralLink;

  return model;
};

const initialProducerFormValues = () => clone(emptyProducer);

export {
  emptyProducer,
  producerPropType,
  producerNotesPropType,
  convertServerProducerToProducerModel,
  initialProducerFormValues,
};
