/**
 *
 * Reducers for general app state
 *
 */
import {
  API_ERROR,
  STORE_STATES,
  UPDATE_LOGIN_STATUS,
  LOGIN_LOCKED_OUT,
  SET_CURRENT_USER,
  SET_DATA_LOADED,
} from "../Actions/actions";
import { emptyUser } from "../../Helpers/UserModel";

const initialState = () => ({
  isLoggedIn: false,
  maintenanceMode: false,
  currentUser: emptyUser,
  states: [],
  eventTypes: [],
  timezone: null,
});

const updateLoginStatus = (state, status) => {
  const updated = { ...state };
  updated.isLoggedIn = status;
  return updated;
};

const setLoginLockedOut = (state, status) => {
  const lockedOut = { ...state };
  lockedOut.isLockedOut = status;
  return lockedOut;
};

export default (state = initialState(), action) => {
  if (action.type === API_ERROR) {
    if (
      action.error?.response?.status === 503 &&
      action.error?.response?.data?.message === "Maintenance Mode"
    ) {
      return {
        ...state,
        maintenanceMode: true,
      };
    }
  }
  if (action.type === STORE_STATES) {
    return { ...state, ...{ states: action.payload } };
  }
  if (action.type === UPDATE_LOGIN_STATUS) {
    return { ...updateLoginStatus(state, action.payload) };
  }
  if (action.type === LOGIN_LOCKED_OUT) {
    return { ...setLoginLockedOut(state, action.payload) };
  }
  if (action.type === SET_CURRENT_USER) {
    return { ...state, currentUser: action.payload };
  }
  if (action.type === SET_DATA_LOADED) {
    return { ...state, ...action.payload };
  }
  return state;
};
