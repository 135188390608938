import { ControlModels } from "@reactables/forms";
import TextField from "./TextField";

const DependentDobField = ({
  dependentDobControl,
  dependentControl,
  ...props
}) => {
  const extendedDobControl: ControlModels.FormControl<string> = {
    ...dependentDobControl,
    errors: {
      ...dependentDobControl.errors,
      dependentDateOfBirth: dependentControl.errors.dependentDateOfBirth,
    },
  };
  return <TextField {...props} meta={extendedDobControl} />;
};

export default DependentDobField;
