import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { initialize, reset } from "redux-form";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Button } from "@jauntin/react-ui";
import { stringHelpers } from "../../../Helpers/FormattingHelpers";
import PopoverTrigger from "../../Shared/Components/SearchEllipsisPopover";
import { producerPropType } from "../../../Helpers/ProducerModel";
import { getUrl, PRODUCER_PAGE } from "../../../Helpers/URLParser";
import {
  HOLD,
  producerFormName,
  DEFAULT_PRODUCER_NAME,
} from "../../../constants";
import { setEditingAction } from "../../../Redux/Actions/actions";

const PopoverContent = ({ producer, editReset, setEditing }) => {
  return (
    <>
      <NavLink
        className="btn popover-item__btn"
        to={getUrl(PRODUCER_PAGE, producer.id)}
        onClick={() => {
          editReset(producer);
          setEditing(true);
        }}
      >
        <i className="fal fa-edit mr-2 col-1 p-0" />
        <span className="col-auto p-0">Edit broker details</span>
      </NavLink>
    </>
  );
};
PopoverContent.propTypes = {
  producer: producerPropType.isRequired,
  editReset: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
};

const SearchResults = ({
  producersList,
  loadProducer,
  editReset,
  setEditing,
}) => {
  return (
    <table className="table subsection-table mt-4">
      <thead>
        <tr>
          <th scope="col">Broker Name</th>
          <th scope="col">Broker Code</th>
          <th scope="col">Status</th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {producersList.map((producer) => (
          <tr key={`row${producer.id}`}>
            <td>
              <Button
                className="btn btn-link btn--edit text-left text-primary"
                text={producer.name}
                onClick={() => loadProducer(producer.id)}
              />
            </td>
            <td>{producer.commissionId}</td>
            <td>
              {stringHelpers.firstToUpper(
                producer.status === HOLD ? "On Hold" : producer.status
              )}
            </td>
            <td>
              {producer.name !== DEFAULT_PRODUCER_NAME && (
                <PopoverTrigger
                  content={
                    <PopoverContent
                      producer={producer}
                      editReset={editReset}
                      setEditing={setEditing}
                    />
                  }
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

SearchResults.propTypes = {
  producersList: PropTypes.any.isRequired,
  loadProducer: PropTypes.func.isRequired,
  editReset: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  editReset: (producer) => {
    dispatch(reset(producerFormName));
    dispatch(
      initialize(producerFormName, {
        producerStatus: producer.status,
        producerName: producer.name,
        producerCommissionId: producer.commissionId,
        producerFullName: producer.contactName,
        producerCommissionRate: producer.commissionRate,
        producerContacts: producer.producerContacts,
        producerLogoUrl: producer.logoUrl || null,
        producerImgFile: null,
      })
    );
  },
  setEditing: (value) => dispatch(setEditingAction(value)),
});

export default compose(connect(null, mapDispatchToProps))(SearchResults);
