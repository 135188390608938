import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";
import MainMenuContainer from "./MainMenuContainer";
import MembersContainer from "../../Members/Components/MembersContainer";
import PromoCodesContainer from "../../PromoCodes/Components/PromoCodesContainer";
import ProducersContainer from "../../Producers/Components/ProducersContainer";
import LoadStatesContainer from "./LoadStatesContainer";
import FacilitiesContainer from "../../Facilities/Components/FacilitiesContainer";
import MailcatcherContainer from "../../MailCatcher/MailcatcherContainer";
import AdminUsersContainer from "../../AdminUsers/Components/AdminUsersContainer";
import AddNewUserContainer from "../../AdminUsers/Components/AddNewUserContainer";
import {
  getUrl,
  FACILITIES_PAGE,
  MEMBERS_PAGE,
  PRODUCERS_PAGE,
  MAILCATCHER_PAGE,
  USERS_PAGE,
  ADD_USER_PAGE,
  REPORTS_PAGE,
  PROMO_CODES_PAGE,
  SETTINGS_PAGE,
} from "../../../Helpers/URLParser";
import ReportsContainer from "../../Reports/Components/ReportsContainer";
import SettingsContainer from "../../Settings/Components/SettingsContainer";
import GuardedRoute from "@basicare/common/src/Components/GuardedRoute";
import {
  canManageUsers,
  canViewReports,
  canManageSettings,
} from "Redux/Selectors/Users";
import ActivityListener from "./ActivityListener";

const libraries = ["places"];

const Admin = ({ isLoggedIn, isNotProductionEnv }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
    language: "en",
  });
  const GuardRedirectComponent = () => <Redirect to={getUrl(MEMBERS_PAGE)} />;

  return (
    (isLoggedIn && isLoaded && (
      <div className="container-main">
        <ActivityListener />
        <LoadStatesContainer />
        <MainMenuContainer />
        <div className="admin-body">
          <Switch>
            {process.env.REACT_APP_MAILCATCHER_LOCATION && (
              <Route
                path={getUrl(MAILCATCHER_PAGE)}
                component={MailcatcherContainer}
              />
            )}
            {/*
            <Route
              path={getUrl(ASSOCIATIONS_PAGE)}
              component={AssociationsContainer}
            />
            <Route
              path={getUrl(VENUES_SEARCH_PAGE)}
              component={AllVenuesSearchContainer}
            />*/}
            <Route path={getUrl(MEMBERS_PAGE)} component={MembersContainer} />
            <Route
              path={getUrl(PROMO_CODES_PAGE)}
              component={PromoCodesContainer}
            />
            <Route
              path={getUrl(FACILITIES_PAGE)}
              component={FacilitiesContainer}
            />
            <Route
              path={getUrl(PRODUCERS_PAGE)}
              component={ProducersContainer}
            />
            {/* <Route
              path={getUrl(CONTACTS_PAGE)}
              component={AllContactsSearchContainer}
            />
            <Route
              path={getUrl(BLOCKED_VENUES_PAGE)}
              component={BlockedVenuesContainer}
            /> */}
            <GuardedRoute
              exact
              path={getUrl(USERS_PAGE)}
              component={AdminUsersContainer}
              guards={[canManageUsers]}
              redirectComponent={GuardRedirectComponent}
            />
            <GuardedRoute
              exact
              path={getUrl(ADD_USER_PAGE)}
              component={AddNewUserContainer}
              guards={[canManageUsers]}
              redirectComponent={GuardRedirectComponent}
            />
            <GuardedRoute
              exact
              path={getUrl(REPORTS_PAGE)}
              component={ReportsContainer}
              guards={[canViewReports]}
              redirectComponent={GuardRedirectComponent}
            />
            <GuardedRoute
              exact
              path={getUrl(SETTINGS_PAGE)}
              component={SettingsContainer}
              guards={[canManageSettings, isNotProductionEnv]}
              redirectComponent={GuardRedirectComponent}
            />
            <Route component={GuardRedirectComponent} />
          </Switch>
        </div>
      </div>
    )) || <></>
  );
};

Admin.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isNotProductionEnv: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  isNotProductionEnv: () => {
    return process.env.REACT_APP_ENV !== "production";
  },
});

export default connect(null, mapDispatchToProps)(Admin);
