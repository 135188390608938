import { ControlModels } from "@reactables/forms";
import TextField from "@basicare/common/src/Components/FormElements/TextField";

const ContactEmailField = ({
  entityEmails = [],
  contactsFormArray,
  meta,
  index,
  ...props
}: {
  entityEmails?: string[];
  contactsFormArray: ControlModels.FormControl<unknown>;
  meta: ControlModels.FormControl<string>;
  index: number;
}) => {
  const filteredCustomContactsEmails = (
    contactsFormArray.value as Array<{ email: string }>
  )
    .filter((_, i) => i !== index)
    .map(({ email }) => email);

  const allEmails = [...filteredCustomContactsEmails, ...entityEmails].filter(
    (email) => Boolean(email)
  );

  const extendedControl = {
    ...meta,
    errors: {
      ...meta.errors,
      uniqueContactEmails:
        contactsFormArray.errors.uniqueContactEmails &&
        allEmails.includes(meta.value),
    },
  };

  return (
    <TextField
      {...props}
      meta={extendedControl}
      errorMessages={{
        uniqueContactEmails: "This email already exists for another contact",
      }}
    />
  );
};

export default ContactEmailField;
