import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { facilitiesPropType, invoiceForm } from "../../../constants";
import { required } from "../../../Helpers/validators";
import FacilityField from "./FormElements/FacilityField";
import Debounce from "../../../Helpers/Debounce";
import PropTypes from "prop-types";
import { getSearchFacilities } from "../../../Redux/Actions/actions";
import FacilityService from "Services/FacilityService";
import API from "Services/API";

const debouncer = new Debounce({ period: 500 });

const FacilityInvoice = ({ facilitiesList, getFacilities }) => {
  const [fetchingDownload, setFetchingDownload] = useState(false);
  const [fetchingSend, setFetchingSend] = useState(false);
  const [facility, setFacility] = useState(null);
  const downloadFacilityInvoice = async () => {
    setFetchingDownload(true);
    try {
      await new FacilityService(new API()).downloadExportInvoice(facility);
    } finally {
      setFetchingDownload(false);
    }
  };
  const sendFacilityInvoice = async () => {
    setFetchingSend(true);
    try {
      await new FacilityService(new API()).postSendInvoice(facility);
    } finally {
      setFetchingSend(false);
    }
  };

  useEffect(() => {
    getFacilities();
  }, [getFacilities]);

  return (
    <div>
      <strong>Invoices</strong>
      <p>Downloads invoice for the selected organization</p>
      <div className="row">
        <div className="col-md-6">
          <div className="mt-3">
            <Field
              name="FacilityField"
              component={FacilityField}
              validate={[required]}
              setFacility={setFacility}
              facilitiesList={facilitiesList}
              getFacilities={getFacilities}
            />
          </div>

          <div className="mt-4">
            <div className="row">
              <div className="col-md-6">
                <button
                  className="btn btn-primary py-2 h-100 w-100"
                  disabled={fetchingDownload || !facility}
                  onClick={downloadFacilityInvoice}
                >
                  {fetchingDownload && (
                    <span
                      className="spinner-border spinner-border-sm align-middle mr-1"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Download Invoice
                </button>
              </div>
              <div className="col-md-6 mt-3 mt-md-0">
                <button
                  className="btn btn-primary py-2 h-100 w-100"
                  disabled={fetchingSend || !facility}
                  onClick={sendFacilityInvoice}
                >
                  {fetchingSend && (
                    <span
                      className="spinner-border spinner-border-sm align-middle mr-1"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Send Invoice
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FacilityInvoice.propTypes = {
  facilitiesList: PropTypes.arrayOf(facilitiesPropType),
  getFacilities: PropTypes.func.isRequired,
};

FacilityInvoice.defaultProps = {
  facilitiesList: [],
};

const mapStateToProps = (state) => ({
  facilitiesList: state.facilities.facilityTableData.map((facility) => ({
    value: String(facility.id),
    label: facility.name,
  })),
});

const mapDispatchToProps = (dispatch) => ({
  getFacilities: (inputValue) =>
    debouncer.do(
      (searchInput) => dispatch(getSearchFacilities(searchInput, 1, 10)),
      inputValue
    ),
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: invoiceForm,
  })
)(FacilityInvoice);
