const LoadingSpinner = () => (
  <div className="loader">
    {[...Array(8)].map((_, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={i} className={`loader__dot loader__dot--${i + 1}`} />
    ))}
  </div>
);

export default LoadingSpinner;
