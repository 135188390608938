import { MemberContactsFormState } from "../RxMemberContactsForm";

export const getIsAllEmailsSelected = (state: MemberContactsFormState) => {
  const {
    emailPrimaryMember,
    customContacts,
    emailOrganizationContacts,
    emailOrganizationProducerContacts,
  } = state.root.value;

  return [
    emailPrimaryMember,
    ...customContacts.map(({ sendEmail }) => sendEmail),
    ...emailOrganizationContacts,
    ...emailOrganizationProducerContacts,
  ].every((sendEmail) => sendEmail);
};
