import { ControlModels } from "@reactables/forms";
import { Field, FormArray } from "@reactables/react-forms";
import { TextInput, CheckboxInput } from "@jauntin/react-ui";
import {
  Facility,
  FacilityForm as FacilityFormValue,
} from "../Models/facility.model";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useReactable } from "@reactables/react";
import { useSelector } from "react-redux";
import { Form } from "@reactables/react-forms";
import SelectList from "@basicare/common/src/Components/FormElements/SelectList";
import ContactEmailField from "@basicare/common/src/Components/ContactEmailField";
import { getLgSelectStyle } from "@basicare/common/src/Helpers/ReactSelectStyle";
import { RxFacilityForm, facilityContactConfig } from "../Rx/RxFacilityForm";

import FacilityService from "Services/FacilityService";
import API from "Services/API";
import ProductCodeField from "./FormElements/ProductCodeField";
import ElixirGroupIdField from "./FormElements/ElixirGroupIdField";
import FacilityLogoUpload from "./FacilityLogoUpload";
import { MAX_FACILITY_CONTACTS } from "../../../constants";
import { ExtendedMeta } from "@jauntin/utilities";

interface FacilityFormChildrenProps {
  formState: ControlModels.Form<FacilityFormValue>;
  formFields: React.ReactNode;
}

const FacilityForm = ({
  facility,
  children,
  showErrors = ({ touched, error }: ExtendedMeta) => Boolean(touched && error),
}: {
  facility?: Facility;
  children?: (props: FacilityFormChildrenProps) => React.ReactNode;
  showErrors?: (meta: ExtendedMeta) => boolean;
}) => {
  const [state, actions] = useReactable(RxFacilityForm, {
    facility,
    facilityService: new FacilityService(new API()),
  });

  const stateOptions = useSelector((state: { app: { states } }) =>
    state.app.states.map((usState) => ({
      label: usState.name,
      value: usState.code,
    }))
  );

  if (!state) return;

  const { logoImageFile, logoUrl } = state.form.root.value;

  const logoSrc = logoImageFile?.content || logoUrl;

  const { validatorErrors, pending } = state.form["code"];

  const isOrgCodeValidating =
    Object.values(validatorErrors).every((error) => !error) && pending;

  const formFields = (
    <Form rxForm={[state.form, actions.form]}>
      {/* General Information */}
      <div className="card w-100">
        <div className="card-header bg-transparent d-flex justify-content-between">
          <div className="my-auto contacts__cardTitle">
            <strong>Organization Information</strong>
          </div>
        </div>
        <div className="card-body">
          <Row>
            <Col md={10}>
              <Field
                label={
                  <>
                    Organization
                    {isOrgCodeValidating && (
                      <span className="ml-2 small">Validating...</span>
                    )}
                  </>
                }
                component={TextInput}
                inputClassName="form-control-lg"
                name="code"
                maxLength={6}
                errorMessages={{
                  facilityCode: "Invalid. Must be 3 to 6 numbers/letters",
                  uniqueFacilityCode:
                    "The Organization Code has already been taken.",
                }}
                showErrors={showErrors}
              />
              <Field
                component={TextInput}
                name="name"
                label="Organization Name"
                inputClassName="form-control-lg col-lg-6 mb-4"
                errorClassName="d-inline ml-2"
                showErrors={showErrors}
              />
              <Field
                component={TextInput}
                label="Monthly Price"
                name="monthlyPrice"
                inputClassName="form-control-lg col-lg-6"
                errorClassName="d-inline ml-2"
                showErrors={showErrors}
              />
              <div className="form-row">
                <Col md={6}>
                  <Field
                    component={ProductCodeField}
                    name="productCode"
                    status={facility?.status}
                    showErrors={showErrors}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    component={TextInput}
                    label="Recuro Agent ID"
                    ariaLabel="Recuro Agent ID"
                    name="recuroAgentId"
                    inputClassName="form-control-lg"
                    errorClassName="d-inline ml-2"
                    showErrors={showErrors}
                  />
                </Col>
              </div>
              <div className="form-row">
                <Col md={6}>
                  <Field
                    component={ElixirGroupIdField}
                    name="elixirGroupId"
                    showErrors={showErrors}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    component={TextInput}
                    inputClassName="form-control-lg"
                    label="Recuro Group ID"
                    name="recuroGroupId"
                    errorClassName="d-inline ml-2"
                    showErrors={showErrors}
                  />
                </Col>
              </div>
              <Field
                component={CheckboxInput}
                name="requiresRecuroSubscriberNumber"
                className="d-flex align-items-center"
                ariaLabel="Requires Recuro Subscriber Number"
                labelClassName="pl-2"
                label="Requires Recuro Subscriber Number"
                showErrors={showErrors}
              />
              <Field
                component={CheckboxInput}
                name="doNotSendEmailsToMember"
                className="d-flex align-items-center"
                ariaLabel="Do not send emails to the member"
                labelClassName="pl-2"
                label="Do not send emails to the member"
                showErrors={showErrors}
              />
              <Field
                component={TextInput}
                name="otherInsured.address"
                label="Street address"
                ariaLabel="Street address"
                inputClassName="form-control-lg mb-2"
                className="mb-0"
                showErrors={showErrors}
              />
              <Field
                component={TextInput}
                name="otherInsured.address2"
                ariaLabel="Address Line 2"
                inputClassName="form-control-lg mb-2"
                showErrors={showErrors}
              />
              <div className="form-row">
                <div className="col-sm">
                  <Field
                    component={TextInput}
                    name="otherInsured.city"
                    label="City"
                    ariaLabel="City"
                    inputClassName="form-control-lg"
                    showErrors={showErrors}
                  />
                </div>
                <div className="col-sm">
                  <Field
                    name="otherInsured.state"
                    component={SelectList}
                    customStyles={getLgSelectStyle}
                    label="State"
                    placeholder="State"
                    options={stateOptions}
                    isClearable={true}
                    showErrors={showErrors}
                  />
                </div>
                <div className="col-sm">
                  <Field
                    component={TextInput}
                    name="otherInsured.zip"
                    label="Zipcode"
                    inputClassName="form-control-lg"
                    showErrors={showErrors}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <FacilityLogoUpload
            imgSrc={logoSrc}
            onSelect={actions.form.selectLogo}
            onClear={actions.form.clearLogo}
          />
        </div>
      </div>

      {/* Broker Information */}

      <div className="card w-100 mt-4">
        <div className="card-header bg-transparent d-flex justify-content-between">
          <div className="my-auto contacts__cardTitle">
            <strong>Broker</strong>
          </div>
        </div>
        <div className="card-body">
          <Row>
            <Col md={10}>
              <Field
                name={`producer`}
                component={SelectList}
                customStyles={getLgSelectStyle}
                label={
                  <>
                    Broker{" "}
                    {state.typeahead.loading && (
                      <span className="small">(Searching...)</span>
                    )}
                  </>
                }
                placeholder="Type to search"
                options={state.typeahead.data.map(({ id, name, contacts }) => ({
                  label: name,
                  value: {
                    id,
                    name,
                    contacts: contacts.map(
                      ({ id, fullName, copyOnEmails, email }) => ({
                        id,
                        copyOnEmails,
                        email,
                        fullName,
                      })
                    ),
                  },
                }))}
                onChange={actions.form.selectProducer}
                onInputChange={(value, { action }) => {
                  if (action === "input-change") {
                    actions.typeahead.search(value);
                  }
                }}
                inputClassName="form-control-lg"
                isClearable
                showErrors={showErrors}
              />
              <div className="mt-4">
                <FormArray name="emailProducerContacts">
                  {({ items }) =>
                    items.map(
                      (
                        {
                          value: { fullName, email },
                        }: ControlModels.FormControl<{
                          id: number;
                          copyOnEmails: boolean;
                          email: string;
                          fullName: string;
                        }>,
                        index
                      ) => (
                        <Field
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          component={CheckboxInput}
                          name={`emailProducerContacts.${index}.copyOnEmails`}
                          ariaLabel="Copy on emails checkbox"
                          className="d-flex align-items-center mb-2"
                          label={
                            <>
                              {fullName && (
                                <span className="mr-3">{fullName}</span>
                              )}
                              <p className="font-weight-normal mb-0">{email}</p>
                            </>
                          }
                          labelClassName="pl-2 contacts__copyOnEmail"
                          showErrors={showErrors}
                        />
                      )
                    )
                  }
                </FormArray>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="card w-100 mt-4">
        <div className="card-header bg-transparent d-flex justify-content-between">
          <div className="my-auto contacts__cardTitle">
            <strong>Invoice Contact</strong>
          </div>
        </div>
        <div className="card-body">
          <Row>
            <Col md={10}>
              <Field
                component={TextInput}
                name="invoiceContact.name"
                label="Name"
                ariaLabel="Name"
                inputClassName="form-control-lg mb-2"
                className="mb-0"
                showErrors={showErrors}
              />
              <Field
                component={TextInput}
                name="invoiceContact.address"
                label="Street address"
                ariaLabel="Street address"
                inputClassName="form-control-lg mb-2"
                className="mb-0"
                showErrors={showErrors}
              />
              <Field
                component={TextInput}
                name="invoiceContact.address2"
                ariaLabel="Address Line 2"
                inputClassName="form-control-lg mb-2"
                showErrors={showErrors}
              />
              <div className="form-row">
                <div className="col-sm">
                  <Field
                    component={TextInput}
                    name="invoiceContact.city"
                    label="City"
                    ariaLabel="City"
                    inputClassName="form-control-lg"
                    showErrors={showErrors}
                  />
                </div>
                <div className="col-sm">
                  <Field
                    name="invoiceContact.state"
                    component={SelectList}
                    customStyles={getLgSelectStyle}
                    label="State"
                    placeholder="State"
                    options={stateOptions}
                    isClearable={true}
                    showErrors={showErrors}
                  />
                </div>
                <div className="col-sm">
                  <Field
                    component={TextInput}
                    name="invoiceContact.zip"
                    label="Zipcode"
                    inputClassName="form-control-lg"
                    showErrors={showErrors}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* Contacts */}
      <FormArray name="contacts">
        {({ items, pushControl, removeControl }) => (
          <>
            {items.map((_, index) => (
              <Card key={index} className="w-100 mt-4">
                <Card.Header className="bg-transparent d-flex justify-content-between">
                  <div className="font-weight-bold  ">
                    {`Organization Contact #${index + 1}`}
                  </div>
                  <Button
                    variant="link"
                    className="btn-link--black btn-link--delete"
                    onClick={() => removeControl(index)}
                  >
                    Delete
                  </Button>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={10}>
                      <Field
                        component={TextInput}
                        name={`contacts.${index}.fullName`}
                        label="Full name"
                        ariaLabel="Full name"
                        inputClassName="form-control-lg mb-4"
                        showErrors={showErrors}
                      />
                      <Field
                        component={TextInput}
                        name={`contacts.${index}.role`}
                        label="Role"
                        ariaLabel="Role"
                        inputClassName="form-control-lg mb-4"
                        showErrors={showErrors}
                      />
                      <Field
                        component={ContactEmailField}
                        name={`contacts.${index}.email`}
                        label="Email"
                        placeholder=""
                        contactsFormArray={state.form["contacts"]}
                        errorClassName="mb-2"
                        inputClassName="form-control-lg mb-4"
                        showErrors={showErrors}
                      />
                      <Field
                        component={CheckboxInput}
                        name={`contacts.${index}.copyOnEmails`}
                        className="d-flex align-items-center"
                        ariaLabel="Copy on emails checkbox"
                        labelClassName="pl-2 contacts__copyOnEmail"
                        label="Copy on emails containing member information."
                        showErrors={showErrors}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}

            {items.length < MAX_FACILITY_CONTACTS && (
              <Button
                block
                variant="outline-secondary"
                className="px-4 mt-4"
                onClick={() => pushControl(facilityContactConfig())}
              >
                Add contact
              </Button>
            )}
          </>
        )}
      </FormArray>
    </Form>
  );

  return <>{children && children({ formState: state.form, formFields })}</>;
};

export default FacilityForm;
