import { connect } from "react-redux";
import React from "react";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { ConnectedRouter } from "@jauntin/react-ui";
import { Link } from "react-router-dom";
import * as qs from "query-string";
import { loginFormName } from "../../../constants";
import LoginField from "./LoginField";
import PasswordField from "./PasswordField";
import { required, email } from "../../../Helpers/validators";
import ButtonLogin from "./ButtonLogin";
import AuthService from "@basicare/common/src/Helpers/AuthService";
import API from "../../../Services/API";
import {
  getUrl,
  MEMBERS_PAGE,
  TWO_FACTOR_PAGE,
  REQUEST_RESET_PASSWORD,
} from "../../../Helpers/URLParser";
import { FormPropTypes } from "@basicare/common/src/Constants/ReduxFormPropTypes";
import {
  updateLoginStatus,
  setLoginLockedOut,
  setCurrentUser,
  errorResponse,
} from "../../../Redux/Actions/actions";
import Logo from "@basicare/common/src/Assets/Images/basicare.1200.transparent.png";
import LoginLockOut from "./LoginLockOut";
import { getCaptchaToken } from "../../../Helpers/ReCaptchaHelper";

let LoginForm = ({
  valid,
  handleSubmit,
  submitting,
  error,
  lockedOut,
  setShowLockout,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="container text-center pt-5 mt-5">
        <img
          src={Logo}
          alt="Logo"
          width="400"
          className="img-fluid mx-auto mb-2"
        />
        {(!lockedOut && (
          <div className="col-sm-6 col-lg-4 mx-auto">
            <div>
              <Field
                component={LoginField}
                validate={[required, email]}
                name="email"
              />
            </div>
            <div>
              <Field
                component={PasswordField}
                validate={[required]}
                name="password"
              />
            </div>
            <Link
              className="c-gray-600 font-weight-normal mb-1 d-block"
              to={getUrl(REQUEST_RESET_PASSWORD)}
            >
              Reset Password
            </Link>
            {error && <div className="form-error ml-2 text-left">{error}</div>}
            <div>
              <ButtonLogin
                className="btn btn-primary w-100 my-4 py-2"
                disabled={!valid || submitting}
                text="LOG IN"
              />
            </div>
          </div>
        )) || (
          <LoginLockOut
            setShowLockout={setShowLockout}
            title="Return to Login"
          />
        )}
      </div>
    </form>
  );
};
LoginForm = reduxForm({ form: loginFormName })(LoginForm);
LoginForm.propTypes = FormPropTypes;

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) =>
    dispatch(async () => {
      const { returnUrl } = qs.parse(window.location.search);
      try {
        const response = await new AuthService(new API()).login({
          ...values,
          captcha: await getCaptchaToken(),
        });
        dispatch(updateLoginStatus(true));
        dispatch(setLoginLockedOut(false));
        dispatch(setCurrentUser(response.data.user));
        return dispatch(
          ConnectedRouter.push(returnUrl || getUrl(MEMBERS_PAGE))
        );
      } catch (e) {
        const { response } = e;
        const { data } = response;
        const { errors } = data;

        if (response.status === 302) {
          dispatch(setCurrentUser(response.data.user));
          return dispatch(
            ConnectedRouter.push(
              getUrl(TWO_FACTOR_PAGE) +
                (returnUrl ? `?returnUrl=${returnUrl}` : "")
            )
          );
        }

        let message = "Login Failed";
        let lockedOut = false;

        if (
          response.status === 429 &&
          data &&
          errors &&
          errors.email.length > 0
        ) {
          const [attempts] = errors.email;
          message = attempts;
          lockedOut = true;
        }
        dispatch(setLoginLockedOut(lockedOut));
        dispatch(updateLoginStatus(false));
        dispatch(errorResponse(e));
        throw new SubmissionError({
          _error: message,
        });
      }
    }),
  setShowLockout: (val) => dispatch(setLoginLockedOut(val)),
});

const mapStateToProps = (state) => ({
  lockedOut: state.app.isLockedOut,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
