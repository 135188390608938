import { Spinner } from "react-bootstrap";

const LoadingMask = ({
  loading = false,
  size = "4rem",
  children,
}: {
  loading?: boolean;
  size?: string;
  children;
}) => (
  <div className="position-relative">
    {loading && (
      <div className="bg-mask position-absolute h-100 w-100 d-flex align-items-center justify-content-center">
        <Spinner animation="border" style={{ width: size, height: size }} />
      </div>
    )}
    {children}
  </div>
);

export default LoadingMask;
