import { group, control } from "@reactables/forms";
import { emptyPromoCode } from "@basicare/common/src/Models/promoCode.model";
import { DiscountTypes } from "@basicare/common/src/Constants/discountTypes";
import { BillingCycleTypes } from "@basicare/common/src/Constants/billingCycleTypes";

export const promoCode = ({
  name,
  code,
  subscriptionInterval,
  type,
  discountType,
  discountAmount,
  dateRange,
  billingCycles,
  usages,
} = emptyPromoCode) =>
  group({
    validators: ["promoCodeDiscountDollarAmount", "discountAmountRequired"],
    controls: {
      name: control([name, "required"]),
      code: control({
        initialValue: code,
        validators: [
          "required",
          "maxLength20",
          "minLength3",
          "alphaNumericOrDash",
          "promoCodeFormat",
        ],
        asyncValidators: ["uniquePromoCode"],
        normalizers: ["alphaNumericOrDash", "maxLength20", "uppercase"],
      }),
      subscriptionInterval: control([subscriptionInterval, "required"]),
      type: control([type, "required"]),
      discountType: control([
        discountType || DiscountTypes.Percent,
        "required",
      ]),
      discountAmount:
        discountType === DiscountTypes.Fixed
          ? discountAmountDollars(discountAmount)
          : discountAmountPercentage(discountAmount),
      billingCycles:
        billingCycles.type === BillingCycleTypes.Limited
          ? group({
              controls: {
                type: control([billingCycles.type, "required"]),
                count: billingCyclesCount(billingCycles.count),
              },
            })
          : group({
              controls: {
                type: control([BillingCycleTypes.Unlimited, "required"]),
              },
            }),
      usages: control([usages, ["notZero", "usageGreaterThanRedeems"]]),
      dateRange: control([dateRange, "startDateRequired"]),
    },
  });

export const billingCyclesCount = (billingCycles: string = "") =>
  control([billingCycles, ["required", "notZero"]]);

export const discountAmountDollars = (discountAmount = "") =>
  control({
    initialValue: discountAmount,
    validators: ["notZero"],
    normalizers: ["formatDiscountDollars"],
  });

export const discountAmountPercentage = (discountAmount = "") =>
  control({
    initialValue: discountAmount,
    validators: ["max60", "notZero"],
    normalizers: ["maxInteger60"],
  });
