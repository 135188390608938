import { of, from, Observable, combineLatest } from "rxjs";
import { map, debounceTime, filter } from "rxjs/operators";
import { ActionMap, Reactable, Action, ofTypes } from "@reactables/core";
import {
  FormBuilders,
  asyncValidators,
  RxRequest,
  RequestState,
  Reducers,
} from "@jauntin/reactables";
import { ControlModels, RxFormActions } from "@reactables/forms";
import { MemberForm, emptyMember } from "./Models/member.model";
import { MemberDetails } from "./Models/memberDetails.model";
import { memberDetailsToMemberForm } from "./Models/member.model";
import formProviders from "@basicare/common/src/Helpers/formProviders";
import { addDependentReducer } from "@basicare/common/src/Rx/Reducers/addDependent.reducer";
import {
  placesAutocompleteReducers,
  PlacesAutoCompleteActions,
} from "@basicare/common/src/Rx/Reducers/placesAutocomplete.reducer";
import MembershipService from "../../../Services/MembershipService";
import { MemberStatuses } from "@basicare/common/src/Constants/memberStatuses";
import { parse, isBefore, subMonths } from "date-fns";
import { Organization } from "@basicare/common/src/Models/organization.model";
import FacilityService from "Services/FacilityService";
import { group, array, control } from "@reactables/forms";
import { primaryMember as primaryMemberConfig } from "@basicare/common/src/Rx/Configs/primaryMember.config";
import { dependent } from "@basicare/common/src/Rx/Configs/dependent.config";

export const member = ({
  primaryMember,
  dependents,
  membership: {
    recuroSubscriberNumber,
    effectiveDate,
    organization,
    cancellationDate = "",
  },
}: MemberForm = emptyMember) =>
  group({
    controls: {
      membership: group({
        validators: ["recuroNumRequiredForOrg"],
        controls: {
          recuroSubscriberNumber: control({
            initialValue: recuroSubscriberNumber,
            asyncValidators: ["uniqueRecuroNumber"],
            validators: ["digits9orAlphaNum11"],
            normalizers: ["normalize11AlphaNums"],
          }),
          effectiveDate: control({
            initialValue: effectiveDate,
            validators: ["required", "effectiveDate", "threeMonthsInThePast"],
            normalizers: ["normalizeDate"],
          }),
          cancellationDate: control({
            initialValue: cancellationDate,
            validators: ["validDateFormat"],
            normalizers: ["normalizeDate"],
          }),
          organization: control([organization, ["required"]]),
        },
      }),
      primaryMember: primaryMemberConfig(primaryMember),
      dependents: array({
        controls: dependents.map((d) => dependent(d)),
      }),
    },
  });

interface OrganizationSearchPayload {
  search: string;
  productCode?: string;
}

type FormFieldActions = {
  addDependent: () => void;
  selectOrganization: (org: Organization | string) => void;
} & PlacesAutoCompleteActions &
  RxFormActions &
  ActionMap;

export interface MemberFormState {
  form: ControlModels.Form<MemberForm>;
  organizationTypeahead: RequestState<Organization[]>;
}

export interface MemberFormActions {
  form: FormFieldActions;
  organizationTypeahead: {
    searchOrganizations: (payload: OrganizationSearchPayload) => void;
  };
}

export const RxMemberForm = ({
  facilityService,
  membershipService,
  memberDetails,
}: {
  facilityService: FacilityService;
  membershipService: MembershipService;
  memberDetails?: MemberDetails;
}): Reactable<MemberFormState, MemberFormActions> => {
  const [form$, formActions, formActions$] = FormBuilders.build(
    member(
      memberDetails ? memberDetailsToMemberForm(memberDetails) : undefined
    ),
    {
      name: "rxMemberForm",
      reducers: {
        ...placesAutocompleteReducers(["primaryMember"]),
        addDependent: addDependentReducer,
        selectOrganization: (
          { updateValues },
          state,
          { payload }: Action<Organization | string>
        ) => {
          state = updateValues(state, {
            controlRef: ["membership", "organization"],
            value: payload,
          });
          return state;
        },
      },
      providers: {
        ...formProviders,
        asyncValidators: asyncValidators([
          {
            name: "uniqueRecuroNumber",
            resource: (value) =>
              from(
                membershipService.getIsUniqueRecuroSubscriberNumber(value)
              ).pipe(
                map(({ data }: { data: { unique: boolean } }) => data.unique)
              ),
          },
        ]),
        validators: {
          ...formProviders.validators,
          threeMonthsInThePast: (value: string) => {
            const date = parse(value, "MM/dd/yyyy", new Date());
            const status = memberDetails?.status;

            return {
              threeMonthsInThePast:
                ((status && status !== MemberStatuses.Active) || !status) &&
                isBefore(date, subMonths(new Date(), 3)),
            };
          },
        },
      },
    }
  ) as Reactable<ControlModels.Form<MemberForm>, FormFieldActions>;

  const initialSearch = {
    search: "",
    productCode: memberDetails?.organization.productCode,
  };

  const organizationClearedFetchTypeahead$ = formActions$.pipe(
    ofTypes(["selectOrganization"]),
    filter(({ payload }: Action<Organization>) => !payload),
    map(() => ({ type: "send", payload: initialSearch }))
  );

  const initialState = {
    ...Reducers.loadableInitialState,
    data: memberDetails
      ? [memberDetailsToMemberForm(memberDetails).membership.organization]
      : [],
  };
  const [organizationTypeahead$, { send: searchOrganizations }] = RxRequest<
    OrganizationSearchPayload,
    Organization[]
  >({
    name: "rxOrganizationTypeahead",
    initialState,
    sources: [
      of({ type: "send", payload: initialSearch }),
      organizationClearedFetchTypeahead$,
    ],
    effect: (
      action$: Observable<Action<{ search: string; productCode?: string }>>
    ) =>
      action$.pipe(
        debounceTime(500),
        map(
          ({
            payload: { search, productCode },
          }: Action<OrganizationSearchPayload>) => {
            return from(
              facilityService.getSearchFacilities(search, productCode)
            ).pipe(map(({ data: { data } }) => data)) as Observable<
              Organization[]
            >;
          }
        )
      ),
  });

  const state$ = combineLatest({
    form: form$,
    organizationTypeahead: organizationTypeahead$,
  });

  const actions = {
    form: formActions,
    organizationTypeahead: { searchOrganizations },
  };

  return [state$, actions];
};
