import {
  DateResponse,
  SettingFormPayload,
} from "Features/Settings/Rx/RxTimeTravelRequests";

/**
 *
 * Services from the server that the app needs.
 *
 */
class SettingService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  setDateForTravel = (payload: SettingFormPayload) =>
    this.api.post({
      location: "/settings",
      body: JSON.stringify(payload),
    });

  getDateForTravel = (): Promise<DateResponse> =>
    this.api.get({
      location: "/settings",
    });

  resetDateForTravel = () =>
    this.api.delete({
      location: "/settings",
    });
}

export default SettingService;
