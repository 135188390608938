import React from "react";
import {
  FieldInputProps,
  FieldMetaProps,
} from "@basicare/common/src/Constants/ReduxFormPropTypes";
import { DEFAULT_ELIXIR_GROUP_ID } from "../../../../constants";
import { mapExtendedErrors } from "@jauntin/utilities";

const values = [DEFAULT_ELIXIR_GROUP_ID];

const ElixirGroupIdField = ({
  input,
  meta,
  showErrors = ({ touched, error }) => Boolean(touched & error),
}) => {
  meta = mapExtendedErrors(meta);
  return (
    <div className="form-group">
      <label
        htmlFor={input.name}
        className={showErrors(meta) ? "label form-error__label" : "label"}
      >
        Elixir Group ID
      </label>
      {showErrors(meta) && <div className="form-error">{meta.error}</div>}
      <select
        {...input}
        name={input.name}
        id={input.name}
        className="custom-select custom-select-lg"
        required
      >
        {values.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
};

ElixirGroupIdField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
};

export default ElixirGroupIdField;
