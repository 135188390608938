import { Modal } from "react-bootstrap";
import { Button } from "@jauntin/react-ui";

const InfoModal = ({
  show,
  handleClose,
  message,
}: {
  show: boolean;
  handleClose: () => void;
  message: string;
}) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header className="w-100 border-0 pb-0" closeButton />
      <Modal.Body className="text-center px-5">
        <p>{message}</p>
        <br />
      </Modal.Body>
      <Modal.Footer className="border-0 pb-4 mb-3">
        <Button
          className="btn btn-primary px-5"
          onClick={handleClose}
          text="OK"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default InfoModal;
