import { connect } from "react-redux";
import { NotesContainer } from "@jauntin/react-ui";
import { getProducer } from "../../../Redux/Actions/actions";
import API from "../../../Services/API";
import ProducerService from "../../../Services/ProducerService";

const mapStateToProps = (state) => ({
  entityId: state.producers.currentProducer.id,
  notes: state.producers.currentProducer.notes.map(
    ({ id, message, type, userName, createdAt }) => ({
      id,
      message,
      type,
      userName,
      created: createdAt,
    })
  ),
});

const mapDispatchToProps = (dispatch) => ({
  publish: (id, message) =>
    dispatch(() => {
      return new ProducerService(new API())
        .postAddNote({ id, message })
        .then(() => dispatch(getProducer(id)));
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesContainer);
