/**
 *
 * Reducers related to data on the Producers page.
 *
 */
import {
  UPDATE_PRODUCERS_TABLE,
  SET_PAGE,
  UPDATE_PRODUCERS_SEARCH_TERM,
  UPDATE_PRODUCER_DATA,
  SHOW_NEW_PRODUCER_ALERT,
  SET_NEW_PRODUCER_STATUS_MESSAGE,
  SET_EDITING,
  CLEAR_PRODUCER_DATA,
  SET_VALID_PRODUCER_CODE,
  SET_HAS_CHECKED_PRODUCER_CODE,
} from "../Actions/actions";
import {
  emptyProducer,
  convertServerProducerToProducerModel,
} from "../../Helpers/ProducerModel";

const initialState = () => ({
  producerTableData: [],
  searchTerm: "",
  producerPagination: {
    count: 0,
    page: 1,
    total: 0,
    totalPages: 0,
    perPage: 10,
  },
  currentProducer: emptyProducer,
  valid: false,
  newProducerStatus: false,
  newProducerStatusMessage: "",
  editing: false,
  validProducerCode: true,
  hasCheckedProducerCode: true,
});

const updateProducersTable = (state, producers) => {
  const updated = { ...state };
  updated.producerPagination.count = producers.count;
  updated.producerTableData = producers.data.map((m) =>
    convertServerProducerToProducerModel(m)
  );
  updated.producerPagination.page = producers.page;
  updated.producerPagination.perPage = producers.perPage;
  updated.producerPagination.total = producers.total;
  updated.producerPagination.totalPages = producers.totalPages;
  if (updated.producerTableData.length === 0) {
    updated.producerTableData = [];
  }
  return updated;
};

const updateProducerData = (state, producers) => {
  const updated = {
    ...state,
    currentProducer: { ...convertServerProducerToProducerModel(producers) },
  };
  return updated;
};

const updateProducersSearchTerm = (state, searchTerm) => {
  return {
    ...state,
    searchTerm,
  };
};

const setPage = (state, newPage) => {
  const updated = { ...state };
  updated.producerPagination.page = newPage;
  return updated;
};

const showNewProducerAlert = (state, data) => {
  const updated = { ...state };
  updated.newProducerStatus = data;
  return updated;
};

const setNewProducerStatusMessage = (state, data) => {
  const updated = { ...state };
  updated.newProducerStatusMessage = data;
  return updated;
};

const setEditing = (state, data) => {
  const updated = { ...state };
  updated.editing = data;
  return updated;
};

const clearProducerData = (state) => {
  const updated = {
    ...state,
    currentProducer: initialState().currentProducer,
    validProducerCode: initialState().validProducerCode,
    hasCheckedProducerCode: initialState().hasCheckedProducerCode,
  };
  return updated;
};

export default (state = initialState(), action) => {
  if (action.type === UPDATE_PRODUCERS_TABLE) {
    return { ...updateProducersTable(state, { ...action.payload }) };
  }
  if (action.type === UPDATE_PRODUCER_DATA) {
    return { ...updateProducerData(state, { ...action.payload }) };
  }
  if (action.type === SET_PAGE) {
    return { ...setPage(state, action.newPage) };
  }
  if (action.type === UPDATE_PRODUCERS_SEARCH_TERM) {
    return { ...updateProducersSearchTerm(state, action.payload) };
  }
  if (action.type === SHOW_NEW_PRODUCER_ALERT) {
    return { ...showNewProducerAlert(state, action.payload) };
  }
  if (action.type === SET_NEW_PRODUCER_STATUS_MESSAGE) {
    return { ...setNewProducerStatusMessage(state, action.payload) };
  }
  if (action.type === SET_EDITING) {
    return { ...setEditing(state, action.payload) };
  }
  if (action.type === CLEAR_PRODUCER_DATA) {
    return { ...clearProducerData(state) };
  }
  if (action.type === SET_VALID_PRODUCER_CODE) {
    return { ...state, validProducerCode: action.payload };
  }
  if (action.type === SET_HAS_CHECKED_PRODUCER_CODE) {
    return { ...state, hasCheckedProducerCode: action.payload };
  }
  // if (isForm && action.type === UPDATE_SYNC_ERRORS)
  //   return { ...checkFormValidity(state, action.payload.syncErrors) };
  return state;
};
