import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import SearchProducersContainer from "./SearchProducersContainer";
import {
  producerPropType,
  emptyProducer,
} from "../../../Helpers/ProducerModel";
import LoadProducerContainer from "./LoadProducerContainer";
import ProducersMenu from "./ProducersMenu";
import ProducerContainer from "./ProducerContainer";
import ProducerNotesContainer from "./ProducerNotesContainer";
import AddNewProducerContainer from "./AddNewProducerContainer";
import {
  getUrl,
  PRODUCERS_PAGE,
  ADD_PRODUCER_PAGE,
  PRODUCER_PAGE,
  PRODUCER_NOTES_PAGE,
} from "../../../Helpers/URLParser";

const Producers = ({ producer }) => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={getUrl(PRODUCERS_PAGE)}
          component={SearchProducersContainer}
        />
        <Route
          path={getUrl(ADD_PRODUCER_PAGE)}
          component={AddNewProducerContainer}
        />
        <LoadProducerContainer>
          <div className="subsection__wrapper">
            <ProducersMenu id={producer.id || 0} />
            <div className="subsection__container">
              <div>
                <Route
                  path={getUrl(PRODUCER_PAGE)}
                  component={ProducerContainer}
                />
                <Route
                  path={getUrl(PRODUCER_NOTES_PAGE)}
                  component={ProducerNotesContainer}
                />
              </div>
            </div>
          </div>
        </LoadProducerContainer>
      </Switch>
      {/* LoadProducerContainer will load the current producer when it is mounted */}
    </>
  );
};

Producers.propTypes = {
  producer: producerPropType,
};

Producers.defaultProps = {
  producer: emptyProducer,
};

const mapStateToProps = (state) => ({
  producer: state.producers.currentProducer,
});

const ProducersContainer = connect(mapStateToProps)(Producers);

export default ProducersContainer;
