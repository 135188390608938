import { from, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { Reactable } from "@reactables/core";
import { HookedReactable } from "@reactables/react";
import { ControlModels } from "@reactables/forms";
import { RequestState, RequestActions, RxEntity } from "@jauntin/reactables";

import MembershipService from "../../../Services/MembershipService";
import FacilityService from "Services/FacilityService";

import { CancelMemberPayload, SaveMemberPayload } from "./Models/member.model";
import {
  getContactsAndEmailPayload,
  MemberContactsForm,
} from "./Models/memberContactsForm.model";
import { MemberDetails } from "./Models/memberDetails.model";

export interface MemberState {
  entity: RequestState<MemberDetails>;
  saveMember: RequestState<unknown>;
  cancelMember: RequestState<unknown>;
  memberEmails: RequestState<unknown>;
  paymentMethodUpdateLink: RequestState<{ success: boolean }>;
  addNote: RequestState<unknown>;
}

export type MemberActions = {
  saveMember: RequestActions<SaveMemberPayload>;
  cancelMember: RequestActions<CancelMemberPayload>;
  memberEmails: RequestActions<{
    form: ControlModels.Form<MemberContactsForm>;
    memberDetails: MemberDetails;
  }>;
  paymentMethodUpdateLink: RequestActions<undefined>;
  addNote: RequestActions<{ id: number; message: string }>;
};

export type RxMemberProp = HookedReactable<MemberState, MemberActions>;

export const RxMember = ({
  membershipService,
  id,
  onSaveMemberSuccess,
}: {
  membershipService: MembershipService;
  facilityService: FacilityService;
  id: number;
  onSaveMemberSuccess: () => void;
}) =>
  RxEntity({
    name: "Member",
    getEntityResource: () =>
      membershipService
        .getMembership(id)
        .then(({ data }) => data) as Promise<MemberDetails>,
    entityActions: {
      saveMember: {
        resource: (payload: SaveMemberPayload) =>
          membershipService.saveMember(payload, id),
        onSuccess: onSaveMemberSuccess,
      },
      cancelMember: {
        resource: (payload: CancelMemberPayload) =>
          membershipService.cancelMember(payload, id),
      },
      memberEmails: {
        resource: (params: {
          form: ControlModels.Form<MemberContactsForm>;
          memberDetails: MemberDetails;
        }) => {
          const { emails, customContacts } = getContactsAndEmailPayload(params);

          return forkJoin([
            from(membershipService.updateCustomContacts(id, customContacts)),
            from(membershipService.sendWelcomeEmails(emails, id)),
          ]).pipe(map(([{ data: updatedContacts }]) => updatedContacts));
        },
      },
      addNote: {
        resource: ({ message }: { message: string }) =>
          membershipService.addNote({ id, message }),
      },
      paymentMethodUpdateLink: {
        resource: () => membershipService.sendUpdateLink(id),
      },
    },
  }) as Reactable<MemberState, MemberActions>;
