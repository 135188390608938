import PropTypes from "prop-types";

const loginFormName = "login";
const twoFactorFormName = "twoFactor";
const facilityFormName = "facility";
const addFacilityForm = "addFacility";
const producerFormName = "producer";
const addProducerForm = "addProducer";
const producerContactsForm = "producerContactsForm";
const facilityContactsForm = "facilityContactsForm";
const customContactsForm = "customContactsForm";
const notesForm = "notesForm";
const requestResetPasswordForm = "requestResetPasswordForm";
const facilityOverviewForm = "facilityOverviewForm";
const newUserForm = "newUserForm";
const settingsForm = "settingsForm";
const reportsForm = "reportsForm";
const sendReportsForm = "sendReportsForm";
const invoiceForm = "invoiceForm";

const DEFAULT_FACILITY_CODE = "BCC";
const DEFAULT_FACILITY_NAME = "BasiCare Plus";

const DEFAULT_PRODUCER_NAME = "BasiCare Plus";
const DEFAULT_PRODUCER_CODE = "BCP";

// Organization (Facility) Statuses
const ACTIVE = "active";
const HOLD = "hold";

// Max facility/producer contacts
const MAX_FACILITY_CONTACTS = 10;
const MAX_PRODUCER_CONTACTS = 10;
const MAX_CUSTOM_CONTACTS = 2;

// Modal messages
const modalUpdateServerErrorMessage =
  "Server error. Changes were not applied, please try again.";
const modalDocsSentMessage = "Policy documents successfully sent.";
const modalSendDocsErrorMessage =
  "Server error. Policy documents were not sent, please try again.";

// Note types
const AUTOMATIC_NOTE_TYPE = "automatic";
const MANUAL_NOTE_TYPE = "manual";

const additionalInsuredPropTypes = PropTypes.shape({
  companyName: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  country: PropTypes.string,
});

// Alert messages
const formatAddress = (address) => `${address.address1},
  ${address.city},
  ${address.state},
  ${address.country}`;

const alertNew = (name) => `${name} has been successfully added.`;
const alertUpdated = (name, address) =>
  `${
    name
      ? `${name}`
      : `${address && address.address1 ? `${formatAddress(address)}` : ""}`
  } has been successfully updated.`;

// Prop type for an American state - { code: 'NY', name: 'New York' }
const statePropType = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
});

const paginationProps = PropTypes.shape({
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  contactType: PropTypes.string,
});

paginationProps.defaultProps = {
  contactType: "",
};
// Prop type for producers search results under Facilities
const producerContactsPropType = PropTypes.shape({
  producerContactId: PropTypes.number,
  email: PropTypes.string,
});

const producersPropType = PropTypes.shape({
  value: PropTypes.number,
  label: PropTypes.string,
  producerContacts: PropTypes.arrayOf(producerContactsPropType),
});

const emailProducerContactsPropType = PropTypes.shape({
  id: PropTypes.number,
  fullName: PropTypes.string,
  email: PropTypes.string,
  copyOnEmails: PropTypes.bool,
});

const facilitiesPropType = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

// The API URL formatted to match the local address when in dev
// used to avoid issues accessing hosts from the network
// e.g. if localhost, but testing from a phone using local IP such as 192.168.1.1, assume the API is also the same IP
const regex = /(localhost|127.0.0.1)/;
const url = new URL(process.env.REACT_APP_API_LOCATION);
if (regex.test(url.hostname)) {
  url.hostname = window.location.hostname;
}
const apiUrl = url.href;

// Log out users after 60 min of inaction, polling every second
const LOGINTIMEOUTINTERVAL =
  (process.env.REACT_APP_LOGINTIMEOUTINTERVAL || 3600) * 1000;
const LOGINTIMEOUTPOLLINTERVAL =
  (process.env.REACT_APP_LOGINTIMEOUTPOLLINTERVAL || 1) * 1000;

// Contact Types
const FACILITY_CONTACT_TYPE = "facility";
const PRODUCER_CONTACT_TYPE = "producer";
const BULK_ACTION_REMOVE = "remove";
const BULK_ACTION_OVERWRITE = "overwrite";

// ADMIN ROLES AND PERMISSIONS
export const ROLE_ADMIN = "Admin";
export const ROLE_SUPERADMIN = "Super Admin";
export const PERMISSION_MANAGE_USERS = "Manage Users";
export const PERMISSION_MANAGE_SETTINGS = "Manage Settings";
export const PERMISSION_VIEW_REPORTS = "View Reports";
export const PERMISSION_TEST_FEATURES = "Test Features";

// Organization
export const DEFAULT_ELIXIR_GROUP_ID = "10004885";
export const DEFAULT_RECURO_GROUP_ID = "RE001";

// Reports
export const MEMBER_REPORTS = [
  {
    value: "ScriptClaimMembershipReport",
    title: "Script Claim",
  },
  {
    value: "RecuroMembershipReport",
    title: "Recuro",
  },
  {
    value: "WorkplaceWellnessMembershipReport",
    title: "Wellness",
  },
  {
    value: "MembershipReport",
    title: "BasiCare",
  },
];

export {
  apiUrl,
  loginFormName,
  twoFactorFormName,
  facilityFormName,
  addFacilityForm,
  facilityOverviewForm,
  producersPropType,
  producerContactsPropType,
  emailProducerContactsPropType,
  facilitiesPropType,
  producerFormName,
  addProducerForm,
  producerContactsForm,
  facilityContactsForm,
  customContactsForm,
  newUserForm,
  notesForm,
  requestResetPasswordForm,
  settingsForm,
  reportsForm,
  sendReportsForm,
  invoiceForm,
  statePropType,
  paginationProps,
  DEFAULT_FACILITY_CODE,
  DEFAULT_FACILITY_NAME,
  DEFAULT_PRODUCER_NAME,
  DEFAULT_PRODUCER_CODE,
  ACTIVE,
  HOLD,
  MAX_FACILITY_CONTACTS,
  MAX_PRODUCER_CONTACTS,
  MAX_CUSTOM_CONTACTS,
  modalUpdateServerErrorMessage,
  modalDocsSentMessage,
  modalSendDocsErrorMessage,
  additionalInsuredPropTypes,
  alertNew,
  alertUpdated,
  LOGINTIMEOUTINTERVAL,
  LOGINTIMEOUTPOLLINTERVAL,
  FACILITY_CONTACT_TYPE,
  PRODUCER_CONTACT_TYPE,
  BULK_ACTION_REMOVE,
  BULK_ACTION_OVERWRITE,
  MANUAL_NOTE_TYPE,
  AUTOMATIC_NOTE_TYPE,
};
