import styled from "styled-components";
import { getThemeColor } from "../../Helpers/themes";

export const ButtonStyles = ({ theme, variant, outline }) => `
    color: ${getThemeColor(theme, variant, outline ? "main" : "contrastText")};
    background-color: ${outline ? "#fff" : getThemeColor(theme, variant)};
    border: 1px solid ${getThemeColor(theme, variant)};
    cursor: pointer;
    font-weight: 500;
    &:hover,
    &:focus,
    &:active {
      &:not(:disabled) {
        color: ${getThemeColor(theme, variant, "contrastText")};
        background-color: ${getThemeColor(theme, variant, "dark")};
        border-color: ${getThemeColor(theme, variant, "dark")};
        opacity: 1;
      }
    }
    &:disabled {
      background-color: ${outline ? "#fff" : getThemeColor(theme, variant)};
      cursor: default;
      opacity: 0.7;
    }
    ${theme.components?.Button?.customCss || ""}
  `;

const Button = styled.button((props) => ButtonStyles(props));

export default Button;
