import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import createRootReducer from "./Redux/Reducers/reducers";
import NavigationMiddleware from "./Redux/Middleware/NavigationMiddleware";

export default function configureStore() {
  const store = createStore(
    createRootReducer(),
    composeWithDevTools(applyMiddleware(NavigationMiddleware, thunkMiddleware))
  );

  return store;
}
