export const getFilteredParams = (obj, defaultSearch = {}) => {
  const filteredKeys = Object.keys(obj).filter((key) => {
    const value = obj[key];

    if (value === defaultSearch[key]) return false;

    if (typeof value === "string") return Boolean(value.trim());

    if (value === null || value === undefined) return false;

    if (typeof value === "function") return false;

    return true;
  });

  return filteredKeys.reduce((params, key) => {
    params[key] = obj[key];
    return params;
  }, {});
};
export const getQueryString = (obj, defaultSearch = {}) => {
  const filteredQueryParams = getFilteredParams(obj, defaultSearch);

  if (!Object.keys(filteredQueryParams).length) return "";

  return `?${new URLSearchParams(filteredQueryParams).toString()}`;
};
