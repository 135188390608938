export enum MembershipSource {
  APP_PURCHASE = "App Purchase",
  ADMIN_CREATE_MANUAL = "Admin Create Manual",
}

export enum MembershipStatus {
  PENDING = "created",
  ACTIVE = "active",
  CANCELLED = "cancelled",
  INACTIVE = "inactive",
}

export const statusOptions = [
  {
    label: "Created",
    value: MembershipStatus.PENDING,
  },
  {
    label: "Active",
    value: MembershipStatus.ACTIVE,
  },
  {
    label: "Not Active",
    value: MembershipStatus.CANCELLED,
  },
];
