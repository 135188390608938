import { FacilityProp } from "../Rx/RxFacility";
import { NotesContainer, NoteTypes } from "@jauntin/react-ui";
import { useNotePublisher } from "Features/Shared/Hooks/useNotePublisher";

const FacilityNotes = ({ rxFacility }: { rxFacility: FacilityProp }) => {
  const [state, actions, , actions$] = rxFacility;

  const { id, notes } = state.entity.data;
  const { addNote } = state;

  const publish = useNotePublisher({ actions$, publish: actions.addNote.send });

  return (
    <NotesContainer
      publish={publish}
      entityId={id}
      notes={notes.map(({ id, message, type, user, createdAt }) => ({
        id,
        message,
        type: type as NoteTypes,
        userName: user?.name,
        created: createdAt,
      }))}
      publishing={addNote.loading}
    />
  );
};

export default FacilityNotes;
