import { ControlModels } from "@reactables/forms";
import { useSelector } from "react-redux";
import { useReactable } from "@reactables/react";
import { ExtendedMeta } from "@jauntin/utilities";
import { Button, PhoneInput, EmailInput } from "@jauntin/react-ui";
import { Form, Field, FormArray } from "@reactables/react-forms";
import { Row, Col, Card } from "react-bootstrap";
import TextField from "@basicare/common/src/Components/FormElements/TextField";
import SelectList from "@basicare/common/src/Components/FormElements/SelectList";
import AddressFieldGroup from "@basicare/common/src/Components/FormElements/AddressFieldGroupNew";
import { getLgSelectStyle } from "@basicare/common/src/Helpers/ReactSelectStyle";
import { Organization } from "@basicare/common/src/Models/organization.model";
import { genderOptions } from "@basicare/common/src/Constants/genders";
import { Dependent } from "@basicare/common/src/Models/dependent.model";
import { RelationshipType } from "@basicare/common/src/Constants/dependents";
import DependentFields from "@basicare/common/src/Components/FormElements/DependentFields";
import { MAX_DEPENDENTS } from "@basicare/common/src/Constants/dependents";
import RecuroSubscriberNumberField from "@basicare/common/src/Components/FormElements/RecuroSubscriberNumberField";
import { MemberStatuses } from "@basicare/common/src/Constants/memberStatuses";
import { MemberDetails } from "../Rx/Models/memberDetails.model";
import { MemberForm as MemberFormValue } from "../Rx/Models/member.model";
import { RxMemberForm } from "../Rx/RxMemberForm";
import MembershipService from "Services/MembershipService";
import FacilityService from "Services/FacilityService";
import API from "Services/API";
interface MemberFormChildrenProps {
  formState: ControlModels.Form<MemberFormValue>;
  formFields: React.ReactNode;
}

const MemberForm = ({
  memberDetails,
  showErrors = ({ touched, error }: ExtendedMeta) => Boolean(touched && error),
  children,
}: {
  memberDetails?: MemberDetails;
  showErrors?: (meta: ExtendedMeta) => boolean;
  children?: (props: MemberFormChildrenProps) => React.ReactNode;
}) => {
  const [state, actions] = useReactable(RxMemberForm, {
    membershipService: new MembershipService(new API()),
    facilityService: new FacilityService(new API()),
    memberDetails,
  });

  const usStates = useSelector(
    (state: { app: { states } }) => state.app.states
  );

  if (!state) return;

  const { form } = state;
  const selectedOrganization = form[`membership.organization`]
    .value as Organization | null;

  const dependents = form[`dependents`].value as Dependent[];

  const disableSpouseRadio = dependents.some(
    (dependent) =>
      dependent.relationshipToAccountHolder === RelationshipType.Spouse
  );

  const formFields = (
    <Form rxForm={[form, actions.form]}>
      <div className="content__body">
        <div className="card mb-4">
          <div className="card-header bg-transparent d-flex justify-content-between">
            <div className="my-auto contacts__cardTitle">
              <strong>Membership Information</strong>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col md={6}>
                <Field
                  memberFormGroup={form.membership}
                  recuroSubNumControl={
                    state.form[
                      `membership.recuroSubscriberNumber`
                    ] as ControlModels.FormControl<string>
                  }
                  name={`membership.recuroSubscriberNumber`}
                  component={RecuroSubscriberNumberField}
                  inputClassName="form-control-lg"
                  showErrors={showErrors}
                  disabled={
                    !Boolean(selectedOrganization) ||
                    memberDetails?.status === MemberStatuses.Active
                  }
                />
              </Col>
              <Col md={6}>
                <Field
                  name={`membership.effectiveDate`}
                  component={TextField}
                  label="Effective Date"
                  placeholder="mm/dd/yyyy"
                  inputClassName="form-control-lg date-field"
                  errorMessages={{
                    effectiveDate: "Invalid Date",
                    threeMonthsInThePast:
                      "Cannot be more than 3 months in the past.",
                  }}
                  showErrors={showErrors}
                  disabled={memberDetails?.status === MemberStatuses.Active}
                />
              </Col>
              {state.form[`membership.cancellationDate`]?.value && (
                <Col md={6}>
                  <Field
                    name={`membership.cancellationDate`}
                    component={TextField}
                    label="Cancellation Date"
                    placeholder="mm/dd/yyyy"
                    inputClassName="form-control-lg date-field"
                    disabled
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <Field
                  name={`membership.organization`}
                  component={SelectList}
                  customStyles={getLgSelectStyle}
                  label={
                    <>
                      Organization{" "}
                      {state.organizationTypeahead.loading && (
                        <span className="small">(Searching...)</span>
                      )}
                    </>
                  }
                  placeholder="Type to search"
                  options={state.organizationTypeahead.data.map((org) => ({
                    label: org.name,
                    value: org,
                  }))}
                  onChange={actions.form.selectOrganization}
                  onInputChange={(value, { action }) => {
                    if (action === "input-change") {
                      actions.organizationTypeahead.searchOrganizations({
                        search: value,
                        productCode: memberDetails?.organization.productCode,
                      });
                    }
                  }}
                  inputClassName="form-control-lg"
                  searchable={!Boolean(selectedOrganization)}
                  isClearable
                  showErrors={showErrors}
                />
              </Col>
            </Row>
            {memberDetails?.scriptClaimGroupId ? (
              <Row className="mb-3 mt-2">
                <Col md={6}>
                  <span className="font-weight-bold">
                    Script Claim Group ID:
                  </span>{" "}
                  {memberDetails.scriptClaimGroupId}
                </Col>
              </Row>
            ) : (
              selectedOrganization &&
              selectedOrganization.hasScriptClaimGroupId && (
                <Row className="mb-3 mt-2">
                  <Col md={6}>
                    <span className="font-weight-bold">
                      Script Claim Group ID:
                    </span>{" "}
                    {memberDetails?.scriptClaimGroupId
                      ? memberDetails.scriptClaimGroupId
                      : "assigned by the system"}
                  </Col>
                </Row>
              )
            )}
            <Row className="mb-3 mt-2">
              {selectedOrganization &&
                !Boolean(
                  selectedOrganization.requiresRecuroSubscriberNumber
                ) && (
                  <Col md={6}>
                    <span className="font-weight-bold">Member ID:</span>{" "}
                    {memberDetails?.subscriberNumber
                      ? memberDetails.subscriberNumber
                      : "assigned by the system"}
                  </Col>
                )}
              <Col md={6}>
                <span className="font-weight-bold">Product Code:</span>{" "}
                {selectedOrganization
                  ? selectedOrganization.productCode
                  : "assigned by the organization"}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <span className="font-weight-bold">Elixir Group ID:</span>{" "}
                {selectedOrganization
                  ? selectedOrganization.elixirGroupId
                  : "assigned by the organization"}
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Recuro Group ID:</span>{" "}
                {selectedOrganization
                  ? selectedOrganization.recuroGroupId
                  : "assigned by the organization"}
              </Col>
            </Row>
          </div>
        </div>
        <div className="card">
          <div className="card-header bg-transparent d-flex justify-content-between">
            <div className="my-auto contacts__cardTitle">
              <strong>Primary Member</strong>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col xl={7}>
                <Row>
                  <Col md={6}>
                    <Field
                      name={`primaryMember.firstName`}
                      component={TextField}
                      label="First Name"
                      placeholder="First Name"
                      lengthClassName="small"
                      inputClassName="form-control-lg"
                      showErrors={showErrors}
                      maxLength={50}
                    />
                  </Col>
                  <Col md={6}>
                    <Field
                      name={`primaryMember.lastName`}
                      component={TextField}
                      label="Last Name"
                      placeholder="Last Name"
                      lengthClassName="small"
                      inputClassName="form-control-lg"
                      showErrors={showErrors}
                      maxLength={50}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Field
                      name={`primaryMember.dateOfBirth`}
                      component={TextField}
                      inputMode="numeric"
                      label="Date of Birth"
                      placeholder="mm/dd/yyyy"
                      inputClassName="form-control-lg date-field"
                      showErrors={showErrors}
                    />
                  </Col>
                  <Col md={6}>
                    <Field
                      name={`primaryMember.gender`}
                      component={SelectList}
                      customStyles={getLgSelectStyle}
                      label="Gender"
                      placeholder="Gender"
                      options={genderOptions}
                      searchable={false}
                      inputClassName="form-control-lg"
                      showErrors={showErrors}
                    />
                  </Col>
                </Row>
                <AddressFieldGroup
                  googleMapsLoaded={true}
                  groupName={`primaryMember`}
                  addressValue={
                    state.form[`primaryMember.address`].value as string
                  }
                  onClear={actions.form.clearAddress}
                  usStates={usStates}
                  onSelect={actions.form.selectPlace}
                  lgStyle
                  showErrors={showErrors}
                />
                <Row>
                  <Col md={6}>
                    <Field
                      name={`primaryMember.phoneNumber`}
                      component={PhoneInput}
                      label="Phone Number"
                      placeholder="XXX-XXX-XXXX"
                      inputClassName="form-control-lg"
                      showErrors={showErrors}
                    />
                  </Col>
                  <Col md={6}>
                    <Field
                      name={`primaryMember.email`}
                      component={EmailInput}
                      label="Email Address"
                      inputClassName="form-control-lg"
                      showErrors={showErrors}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <FormArray name={`dependents`}>
          {({ items, removeControl }) => (
            <>
              {items.map(
                (
                  dependentControl: ControlModels.FormControl<Dependent>,
                  index
                ) => {
                  const { key } = dependentControl;

                  return (
                    <Card className="mt-4" key={key}>
                      <Card.Header className="bg-transparent d-flex justify-content-between">
                        <div className="my-auto contacts__cardTitle">
                          <strong>Dependent #{index + 1}</strong>
                        </div>
                        <button
                          className="btn-link--black btn-link--delete btn btn-link"
                          onClick={() => removeControl(index)}
                        >
                          Delete
                        </button>
                      </Card.Header>
                      <Card.Body>
                        <DependentFields
                          dependentControl={dependentControl}
                          dependentDobControl={
                            state.form[
                              `dependents.${index}.dateOfBirth`
                            ] as ControlModels.FormControl<string>
                          }
                          groupName={`dependents.${index}`}
                          disableSpouseRadio={disableSpouseRadio}
                          relationshipControl={
                            state.form[
                              `dependents.${index}.relationshipToAccountHolder`
                            ] as ControlModels.FormControl<RelationshipType>
                          }
                          customStyles={getLgSelectStyle}
                          showErrors={showErrors}
                        />
                      </Card.Body>
                    </Card>
                  );
                }
              )}
              {dependents.length < MAX_DEPENDENTS && (
                <Button
                  text="Add Dependent"
                  className="btn btn-outline-secondary w-100 mt-4"
                  onClick={actions.form.addDependent}
                ></Button>
              )}
            </>
          )}
        </FormArray>
      </div>
    </Form>
  );

  return <>{children && children({ formState: form, formFields })}</>;
};

export default MemberForm;
