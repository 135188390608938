import download from "downloadjs";

/**
 *
 * Services provided by the server related to `Facility`
 *
 */
class FacilityService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getFacilitiesSearchResults = (input = "", page, perPage) => {
    let apiParams: { page; perPage; search? } = { page, perPage };
    if (input) {
      const search = input.trim();
      apiParams = { search, page, perPage };
    }
    return this.api.get({
      location: "/facility",
      params: apiParams,
    });
  };

  getSearchFacilities = (input: string = "", productCode?: string) => {
    const search = input.trim();
    const params: {
      search?: string;
      productCode?: string;
    } = {};
    if (search) {
      params.search = search;
    }
    if (productCode) {
      params.productCode = productCode;
    }
    return this.api.get({
      location: "/facility/searchFacilities",
      params,
    });
  };

  getIsValidFacilityCode = (id) =>
    this.api.get({
      location: `/facility/facilityCode/${id}`,
    });

  getProducersForFacility = (input) => {
    const search = input.trim();
    return this.api.get({
      location: "/facility/searchProducers",
      params: { search },
    });
  };

  getFacility = (id) =>
    this.api.get({
      location: `/facility/${id}`,
    });

  putFacilityEditDetails = (data) =>
    this.api.put({
      location: `/facility/${data.id}/facilityDetails`,
      body: data,
    });

  postAddNewFacilityDetails = (data) =>
    this.api.post({
      location: `/facility/create`,
      body: data,
    });

  postAddNote = (data) =>
    this.api.post({
      location: `/facility/${data.id}/addNote`,
      body: data,
    });

  downloadExportInvoice = async (facilityId, params = {}) => {
    const response = await this.api.getDownload({
      location: `/facility/${facilityId}/generateInvoice`,
      config: {
        responseType: "blob",
      },
    });
    const fileName =
      response.headers["content-disposition"].match(/filename="([^"]*)"/)[1] ||
      "download.xlsx";

    return download(
      response.data,
      fileName,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  };

  postSendInvoice = async (facilityId) =>
    this.api.post({
      location: `facility/${facilityId}/sendInvoice`,
    });
}

export default FacilityService;
