import { ControlModels, Field } from "@reactables/react-forms";
import { RadioInput } from "@jauntin/react-ui";
import { RelationshipType } from "../../Constants/dependents";
import { ExtendedMeta, mapExtendedErrors } from "@jauntin/utilities";
import TextField from "@basicare/common/src/Components/FormElements/TextField";
import { Row, Col } from "react-bootstrap";
import { Dependent } from "../../Models/dependent.model";
import DependentDobField from "./DependentDobField";
import SelectList from "./SelectList";
import { genderOptions } from "../../Constants/genders";
import { StylesConfig } from "react-select";

const DependentFields = ({
  dependentControl,
  dependentDobControl,
  groupName,
  disableSpouseRadio,
  relationshipControl,
  customStyles,
  showErrors,
}: {
  groupName: string;
  disableSpouseRadio: boolean;
  relationshipControl: ControlModels.FormControl<RelationshipType | "">;
  dependentControl: ControlModels.FormControl<Dependent>;
  dependentDobControl: ControlModels.FormControl<string>;
  customStyles: (hasError: boolean) => StylesConfig;
  showErrors?: (meta: ExtendedMeta) => boolean;
}) => {
  groupName = groupName ? `${groupName}.` : "";

  return (
    <>
      <div className="label label--required">Relationship</div>
      <div className="d-flex align-items-center mb-3">
        <div className="pl-4 mr-5">
          <Field
            name={`${groupName}relationshipToAccountHolder`}
            component={RadioInput}
            label="Spouse"
            value={RelationshipType.Spouse}
            disabled={disableSpouseRadio}
            checked={relationshipControl.value === RelationshipType.Spouse}
          />
        </div>
        <div>
          <Field
            name={`${groupName}relationshipToAccountHolder`}
            component={RadioInput}
            label="Dependent"
            value={RelationshipType.Dependent}
            checked={relationshipControl.value === RelationshipType.Dependent}
          />
        </div>
        {showErrors(mapExtendedErrors(relationshipControl)) && (
          <div className="form-error ml-3">
            {mapExtendedErrors(relationshipControl).error}
          </div>
        )}
      </div>
      <Row>
        <Col sm={6}>
          <Field
            name={`${groupName}firstName`}
            component={TextField}
            label="First Name"
            placeholder="First Name"
            lengthClassName="small"
            inputClassName="form-control-lg"
            labelClassName="label--required"
            showErrors={showErrors}
            maxLength={50}
          />
        </Col>
        <Col sm={6}>
          <Field
            name={`${groupName}lastName`}
            component={TextField}
            label="Last Name"
            placeholder="Last Name"
            lengthClassName="small"
            inputClassName="form-control-lg"
            labelClassName="label--required"
            showErrors={showErrors}
            maxLength={50}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            name={`${groupName}dateOfBirth`}
            errorMessages={{
              dependentDateOfBirth: `Dependent must be less than 26 years old`,
            }}
            showErrors={showErrors}
            inputMode="numeric"
            component={DependentDobField}
            dependentControl={dependentControl}
            dependentDobControl={dependentDobControl}
            label="Date of Birth"
            labelClassName="label--required"
            inputClassName="form-control-lg date-field"
            placeholder="mm/dd/yyyy"
          />
        </Col>
        <Col sm={6}>
          <Field
            name={`${groupName}gender`}
            component={SelectList}
            placeholder="Gender"
            label="Gender"
            labelClassName="label--required"
            options={genderOptions}
            customStyles={customStyles}
            searchable={false}
            inputClassName="form-control-lg"
            showErrors={showErrors}
          />
        </Col>
      </Row>
    </>
  );
};

export default DependentFields;
