import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@jauntin/react-ui";
import { FormBuilders } from "@jauntin/reactables";
import { control, group } from "@reactables/forms";
import { Form, Field } from "@reactables/react-forms";
import { useReactable } from "@reactables/react";
import formProviders from "@basicare/common/src/Helpers/formProviders";
import TextField from "@basicare/common/src/Components/FormElements/TextField";
import AsyncButton from "@basicare/common/src/Components/AsyncButton";
import { RxMemberProp } from "Features/Members/Rx/RxMember";
import { isBefore, parse } from "date-fns";

const RxCancelMemberForm = (paidThroughDate?: string, effectiveDate?: string) =>
  FormBuilders.build(
    group({
      controls: {
        cancellationDate: control({
          initialValue: paidThroughDate || "",
          normalizers: ["normalizeDate"],
          validators: [
            "required",
            "validDateFormat",
            "dateNotInThePast",
            "dateEarlierThanEffective",
          ],
        }),
      },
    }),
    {
      name: "rxCancelMemberForm",
      providers: {
        ...formProviders,
        validators: {
          ...formProviders.validators,
          dateEarlierThanEffective: (value: string) => {
            return {
              dateEarlierThanEffective: isBefore(
                parse(value, "MM/dd/yyyy", new Date()),
                parse(effectiveDate, "MM/dd/yyyy", new Date())
              ),
            };
          },
        },
      },
    }
  );

const ModalCancelMember = ({
  show,
  handleClose,
  rxMember,
}: {
  show: boolean;
  handleClose: () => void;
  rxMember: RxMemberProp;
}) => {
  const [
    {
      entity: {
        data: { subscription, effectiveDate },
      },
      cancelMember: { loading, error },
    },
    memberActions,
  ] = rxMember;

  useEffect(() => memberActions.cancelMember.resetState, [memberActions]);

  const rxCancelMemberForm = useReactable(
    RxCancelMemberForm,
    subscription?.paidThroughDate,
    effectiveDate
  );
  const [state, actions] = rxCancelMemberForm;

  if (!state) return;

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header className="w-100 border-0 pb-0" closeButton />
      <Modal.Body className="px-4">
        <Form rxForm={[state, actions]}>
          <Field
            name="cancellationDate"
            disabled={!!subscription?.paidThroughDate}
            component={TextField}
            label="Enter Cancellation Date"
            placeholder="mm/dd/yyyy"
            inputClassName="form-control-lg date-field"
            errorMessages={{
              dateFormat: "Incorrect date",
              dateNotInThePast: "The cancellation date must not be in the past",
              dateEarlierThanEffective:
                'Cancellation date cannot be before activation date. To cancel a membership before activation, use the "Cancel Member Now" button instead.',
            }}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0 pb-4">
        <Button
          className="btn btn-outline-secondary"
          text="Discard"
          onClick={handleClose}
        />
        <AsyncButton
          className="btn btn-primary px-4 mx-2 text-nowrap border-radius-0"
          spinning={loading}
          onClick={() => {
            memberActions.cancelMember.send({
              cancellationDate: String(state.cancellationDate.value),
            });
            // setTimeout(handleClose, 500); // TODO: fix this (\/)__o_O__(\/)
          }}
          disabled={!state.cancellationDate.valid}
          spinningText="Cancelling"
        >
          Cancel Member
        </AsyncButton>
        {error && (
          <div className="form-error position-absolute mt--65">
            There was an error processing your request. Please try again.
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCancelMember;
