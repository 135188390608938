import { PlanTypes } from "@basicare/common/src/Constants/planTypes";
import { PromoCodeTypes } from "@basicare/common/src/Constants/promoCodeTypes";
import { DiscountTypes } from "@basicare/common/src/Constants/discountTypes";
import { NoteTypes } from "@jauntin/react-ui";
import { currencyFromInt } from "@basicare/common/src/Helpers/CurrencyHelper";
import { BillingCycleTypes } from "@basicare/common/src/Constants/billingCycleTypes";

export interface PromoCodeForm {
  id?: number;
  name: string;
  code: string;
  subscriptionInterval: PlanTypes;
  type: PromoCodeTypes;
  discountType: DiscountTypes;
  discountAmount: string;
  dateRange: {
    startDate: string;
    endDate?: string;
  };
  billingCycles: {
    type: BillingCycleTypes;
    count?: string;
  };
  usages?: string;
}

export const emptyPromoCode: PromoCodeForm = {
  name: "",
  code: "",
  subscriptionInterval: null,
  type: null,
  discountType: DiscountTypes.Percent,
  discountAmount: "",
  dateRange: {
    startDate: null,
    endDate: null,
  },
  billingCycles: {
    type: BillingCycleTypes.Limited,
    count: "",
  },
  usages: "",
};

export interface PromoCodePayload {
  name: string;
  code: string;
  type: PromoCodeTypes;
  subscriptionInterval: PlanTypes;
  discountType?: DiscountTypes; // or "fixed"
  discountAmount?: number; // for "fixed" it should be integer (like in GG or GBLI, so for "monthly" max amount will be 5890)
  usages?: number;
  billingCycles?: number;
  startDate: string;
  endDate?: string;
}

export interface PromoCodeSearch {
  search: string;
  subscriptionInterval: PlanTypes;
  type: PromoCodeTypes;
  activeOnly: boolean;
  page: number;
}

export interface PromoCodeDetails {
  id: number;
  name: string;
  code: string;
  type: PromoCodeTypes;
  subscriptionInterval: PlanTypes;
  discountType: DiscountTypes;
  discountAmount: number;
  notes: {
    id: number;
    message: string;
    type: NoteTypes;
    user?: {
      name: string;
      email: string;
    };
    createdAt: string;
    updatedAt: string;
  }[];
  redeems: number;
  billingCycles?: number;
  usages?: number;
  startDate: string;
  endDate?: string;
  createdAt: string;
  updatedAt: string;
}

export const mapPromoCodeDetails = (
  details: PromoCodeDetails
): PromoCodeForm => {
  const {
    name,
    code,
    subscriptionInterval,
    type,
    discountType,
    discountAmount,
    startDate,
    endDate,
    billingCycles,
    usages,
  } = details;

  return {
    name,
    code,
    subscriptionInterval,
    type,
    discountType,
    discountAmount:
      discountAmount == null
        ? ""
        : discountType === DiscountTypes.Fixed
        ? currencyFromInt(discountAmount).formatDollars()
        : discountAmount.toString(),
    dateRange: {
      startDate: startDate ? new Date(startDate).toISOString() : null,
      endDate: endDate ? new Date(endDate).toISOString() : null,
    },
    billingCycles:
      billingCycles == null
        ? { type: BillingCycleTypes.Unlimited }
        : { type: BillingCycleTypes.Limited, count: billingCycles.toString() },
    usages: usages == null ? "" : usages.toString(),
  };
};
