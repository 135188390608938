import React from "react";
import PropTypes from "prop-types";
import ButtonLogin from "./ButtonLogin";

const LoginLockOut = ({ setShowLockout, title }) => (
  <div className="card col-lg-8 mx-auto my-5">
    <div className="card-body mx-auto text-center">
      <div className="col mx-auto">
        <h2 className="m-0 font-weight-bold">Log in attempts exceeded.</h2>
        <div className="mb-4 mt-2 mx-auto">
          This access activity was flagged as suspicious. The account has been
          locked.
          <br />
          You will be temporarily blocked from logging in or requesting a
          password reset.
        </div>
        <div className="mb-4 mt-2 col-lg-8 mx-auto">
          Please try to log in or reset your password at a later time or contact
          the site administrator.
        </div>
      </div>
      <div className="col-lg-10 mx-auto py-2 text-center w-100">
        <ButtonLogin
          className="btn btn-primary w-50 my-3 py-2"
          action={() => {
            setShowLockout(false);
          }}
          text={title}
        />
      </div>
    </div>
  </div>
);
LoginLockOut.propTypes = {
  setShowLockout: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
export default LoginLockOut;
