import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { stringHelpers } from "../../../Helpers/FormattingHelpers";
import { producerPropType } from "../../../Helpers/ProducerModel";
import StatusField from "./FormElements/StatusField";
import ReferralLinkToast from "../../Shared/Components/ReferralLinkToast";

const ProducerStatus = ({ editing, producer }) => {
  const [showToast, setShowToast] = useState(false);

  return (
    <>
      <div className="card border-0">
        <div className="form-row align-items-center mb-4">
          <div className="col-auto">
            {editing ? (
              <Field
                component={StatusField}
                name="producerStatus"
                type="select"
              />
            ) : (
              <>
                <span className="label mr-4">Status:</span>
                <span>{stringHelpers.firstToUpper(producer.status)}</span>
              </>
            )}
          </div>
        </div>
      </div>
      <ReferralLinkToast
        show={showToast}
        setShowToast={setShowToast}
        text={`Broker referral link`}
      />
    </>
  );
};

ProducerStatus.propTypes = {
  editing: PropTypes.bool.isRequired,
  producer: producerPropType.isRequired,
};

export default ProducerStatus;
