import { Modal } from "react-bootstrap";
import { Button } from "@jauntin/react-ui";

const ModalDiscardAddNew = ({ show, handleClose, confirmAction }) => (
  <Modal show={show} onHide={handleClose} size="lg">
    <Modal.Body className="pb-4">
      <Modal.Header className="w-100 border-0 pb-0" />
      <div className="mt-4 text-center">
        <div>Discarding will erase your changes.</div>
        <div>
          It’s like saying never mind! …and we’ll pretend those edits never
          happened.
        </div>
        <div>Are you sure you wish to discard?</div>
      </div>
    </Modal.Body>
    <Modal.Footer className="border-0 pb-4">
      <Button
        text="Cancel"
        className="btn btn-outline-secondary mx-2"
        onClick={handleClose}
      />
      <Button
        text="Yes, discard changes"
        className="btn btn-primary px-4 mx-2"
        onClick={() => {
          handleClose();
          confirmAction();
        }}
        block
      />
    </Modal.Footer>
  </Modal>
);

export default ModalDiscardAddNew;
