import React, { useState } from "react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "@jauntin/react-ui";
import {
  reduxForm,
  Field,
  getFormValues,
  formValueSelector,
  FieldArray,
  change,
} from "redux-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import { Button } from "@jauntin/react-ui";
import {
  ACTIVE,
  addProducerForm,
  alertNew,
  modalUpdateServerErrorMessage,
} from "../../../constants";
import StatusField from "./FormElements/StatusField";
import {
  producerCommissionId,
  contactEmailsUnique,
} from "../../../Helpers/validators";
import { normalizeProducerCommissionId } from "../../../normalizer";
import { getUrl, PRODUCERS_PAGE } from "../../../Helpers/URLParser";
import ProducerService from "../../../Services/ProducerService";
import API from "../../../Services/API";
import {
  showNewProducerAlert,
  setNewProducerStatusMessage,
  errorResponse,
  checkAndSetValidProducerCode,
  setHasCheckedProducerCode,
} from "../../../Redux/Actions/actions";
import ModalDiscardAddNew from "../../Shared/Components/ModalDiscardAddNew";
import ModalUpdateError from "../../Shared/Components/ModalUpdateError";
import ContactFields from "./FormElements/ContactFields";
import Debounce from "../../../Helpers/Debounce";
import ProducerLogoUpload from "./ProducerLogoUpload";
import { getAddProducerFormImgSrc } from "Redux/Selectors/Producer";
import { fileToBase64 } from "@basicare/common/src/Helpers/Base64EncodeFile";
import { producerCodeLength } from "@basicare/common/src/Constants/codes";
import TextField from "@basicare/common/src/Components/FormElements/TextField";
import { validators } from "@jauntin/utilities";
const { required } = validators;
const formValues = formValueSelector(addProducerForm);
const allFormValues = getFormValues(addProducerForm);

const producerCodeDebounce = new Debounce({ period: 500 });

const AddProducer = ({
  pristine,
  valid,
  goToSearchPage,
  addProducer,
  validProducerCode,
  hasCheckedProducerCode,
  setHasCheckedProducerCode,
  checkAndSetValidProducerCode,
}) => {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);
  const handleShowError = () => setShowModalError(true);
  const dispatch = useDispatch();
  const producerFormImgSrc = useSelector(getAddProducerFormImgSrc);

  return (
    <>
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Add New Broker</h4>
          <div className="d-flex float-right">
            <Button
              text="Discard Changes"
              className="btn btn-outline-secondary px-4 mx-2"
              onClick={handleShow}
            />
            <Button
              text="Save Broker"
              className="btn btn-primary px-4 mx-2 text-nowrap"
              onClick={() => addProducer(handleShowError)}
              disabled={
                pristine ||
                !valid ||
                !validProducerCode ||
                !hasCheckedProducerCode
              }
            />
          </div>
        </div>
      </div>
      <div className="content__body">
        <div className="card-body">
          <Field component={StatusField} name="producerStatus" type="select" />
        </div>

        <div className="card w-100">
          <div className="card-header bg-transparent d-flex justify-content-between">
            <div className="my-auto contacts__cardTitle">
              <strong>Broker Information</strong>
            </div>
          </div>
          <div className="card-body">
            <Field
              component={TextField}
              validate={[required]}
              name="producerName"
              label="Broker Name"
              ariaLabel="Broker Name"
              type="text"
              inputClassName="form-control-lg col-lg-6 mb-4"
              errorClassName="d-inline ml-2"
            />
            <Field
              component={TextField}
              label="Broker Code"
              ariaLabel="Broker Code"
              validate={[required, producerCommissionId]}
              name="producerCommissionId"
              type="text"
              normalize={normalizeProducerCommissionId}
              inputClassName="form-control-lg col-md-4 mb-4"
              errorClassName="d-inline ml-2"
              onChange={(e) => {
                const normalizedValue = normalizeProducerCommissionId(
                  e.target.value
                );

                if (normalizedValue.length === producerCodeLength) {
                  setHasCheckedProducerCode(false);
                  producerCodeDebounce.do(
                    checkAndSetValidProducerCode,
                    normalizedValue
                  );
                }
              }}
            />
            {!hasCheckedProducerCode && (
              <div className="form-row">
                <span className="form-error ml-1">Validating code...</span>
              </div>
            )}
            {hasCheckedProducerCode && !validProducerCode && (
              <div className="form-row">
                <div className="col-sm form-group form-error">
                  The Broker Code has already been taken.
                </div>
              </div>
            )}
            <ProducerLogoUpload
              imgSrc={producerFormImgSrc}
              onSelect={(file) =>
                dispatch(change(addProducerForm, "producerImgFile", file))
              }
              onClear={() =>
                dispatch(change(addProducerForm, "producerImgFile", null))
              }
            />
          </div>
        </div>

        <FieldArray name="producerContacts" component={ContactFields} />
      </div>

      <ModalDiscardAddNew
        show={showModal}
        handleClose={handleClose}
        confirmAction={goToSearchPage}
      />

      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />
    </>
  );
};

AddProducer.propTypes = {
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  goToSearchPage: PropTypes.func.isRequired,
  addProducer: PropTypes.func.isRequired,
  validProducerCode: PropTypes.bool.isRequired,
  hasCheckedProducerCode: PropTypes.bool.isRequired,
  checkAndSetValidProducerCode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  valid: state.producers.valid,
  commissionId: formValues(state, "producerCommissionId") || "",
  producers: state.producers.producerTableData || [],
  validProducerCode: state.producers.validProducerCode,
  hasCheckedProducerCode: state.producers.hasCheckedProducerCode,
});

const mapDispatchToProps = (dispatch) => ({
  goToSearchPage: () => dispatch(ConnectedRouter.push(getUrl(PRODUCERS_PAGE))),
  addProducer: (goToSearchPage, handleShowError) =>
    dispatch((_, getState) => {
      const values = allFormValues(getState());
      const data = {
        status: values.producerStatus,
        name: values.producerName,
        commissionId: values.producerCommissionId,
        contacts: values.producerContacts,
      };

      let createProducerPromise;

      if (values.producerImgFile) {
        createProducerPromise = fileToBase64(values.producerImgFile).then(
          (content) => {
            const logoImageFile = {
              fileName: values.producerImgFile.name,
              size: values.producerImgFile.size,
              content,
            };

            return new ProducerService(new API()).postAddNewProducerDetails({
              ...data,
              logoImageFile,
            });
          }
        );
      } else {
        createProducerPromise = new ProducerService(
          new API()
        ).postAddNewProducerDetails(data);
      }

      return createProducerPromise
        .then((response) => {
          if (response.status === 201) {
            dispatch(showNewProducerAlert(true));
            dispatch(
              setNewProducerStatusMessage(alertNew(values.producerName))
            );
            goToSearchPage();
          }
        })
        .catch((err) => {
          handleShowError();
          dispatch(errorResponse(err));
        });
    }),
  setHasCheckedProducerCode: (value) =>
    dispatch(setHasCheckedProducerCode(value)),
  checkAndSetValidProducerCode: (commissionId) =>
    dispatch(checkAndSetValidProducerCode(commissionId)),
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  addProducer: (handleShowError) =>
    dispatchProps.addProducer(dispatchProps.goToSearchPage, handleShowError),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: addProducerForm,
    initialValues: {
      producerStatus: ACTIVE,
      producerName: "",
      producerCommissionId: "",
      producerContacts: [],
      producerImgFile: null,
    },
    validate: (values) => ({
      ...contactEmailsUnique(values, "producerContacts"),
    }),
  })
)(AddProducer);
